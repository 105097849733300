var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/kitchen/material/advantage-visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Advantages of our Ceramics"
    }
  }), _c('page-section', {
    staticClass: "py-40px py-md-80px my-40px my-md-80px grey lighten-5"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg text-center",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm mb-12px mb-md-16px"
  }, [_vm._v("평생 최고의 품질")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("그릇, 칼, 나무 도마, 세라믹 싱크대를 깨끗하게 유지하기 위한 유용한 팁과 가이드를 통해 주방 청소는 간단하고 효율적인 프로세스가 됩니다. 주방을 위생적이고 반짝반짝하게 유지하는 데 도움이 되는 입증된 방법을 발견하여 주방에서 항상 편안하고 건강하게 느낄 수 있습니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item.title,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.image,
        "aspect-ratio": 1 / 1
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('v-sheet', {
      staticClass: "d-flex align-center pa-20px",
      attrs: {
        "color": "grey lighten-5",
        "height": "100%"
      }
    }, [_c('div', [_c('div', {
      staticClass: "tit tit--sm mb-10px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])])])], 1)], 1)], 1)];
  })], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }