var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-tabs', {
    staticClass: "d-md-none",
    attrs: {
      "hide-slider": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-tab', {
      key: item.title
    }, [_c('span', {
      staticClass: "tit tit--xs text-pre-wrap"
    }, [_vm._v(" " + _vm._s(item.title) + " ")])])];
  })], 2), _c('v-tabs', {
    staticClass: "d-none d-md-flex",
    attrs: {
      "vertical": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-tab', {
      key: item.title
    }, [_c('span', {
      staticClass: "tit tit--xs text-pre-wrap"
    }, [_vm._v(" " + _vm._s(item.title) + " ")])])];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-tab-item', {
      key: item.title
    }, [_c('expansion-panels', {
      attrs: {
        "data": item.children
      }
    })], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }