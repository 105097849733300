var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/bathroom/planner/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Bathroom Planner"
    }
  }), _c('page-section', {
    staticClass: "py-40px py-md-80px"
  }, [_c('v-container', {
    staticClass: "mt-50px mt-md-100px"
  }, [_c('div', {
    staticClass: "tit text-center mb-12px mb-md-16px"
  }, [_vm._v("집에서 간편하게 온라인으로 욕실 계획을 세워보세요")]), _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "large": "",
      "color": "primary",
      "href": "https://badplaner.villeroy-boch.de/3DPlanerWeb/cloud/VB-EUROPE/?ts=1726118325074&vs=1726118325074#intro",
      "target": "_blank"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs white--text font-weight-medium"
  }, [_vm._v("바로 시작하기")]), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right-white.svg"
    }
  })], 1)], 1)])], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#1"
    }
  }, [_vm._v(" 중요한 기능을 한눈에 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#2"
    }
  }, [_vm._v(" 욕실 계획 팁 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#3"
    }
  }, [_vm._v(" 욕실 플래너를 위한 템플릿 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#4"
    }
  }, [_vm._v(" 당신이 꿈꾸는 욕실을 위한 영감 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")])], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium mb-4px"
  }, [_vm._v("간단하고 유연함")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("개인의 아이디어에 따라 언제 어디서나 꿈의 욕실을 계획해 보세요.")])])], 1), _c('v-row', {
    staticClass: "row--xs pt-12px pt-md-16px"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")])], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium mb-4px"
  }, [_vm._v("사용자 친화적")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("직관적인 조작으로 단 몇 단계만으로 빠르고 쉽게 설계할 수 있습니다.")])])], 1), _c('v-row', {
    staticClass: "row--xs pt-12px pt-md-16px"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")])], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium mb-4px"
  }, [_vm._v("3D 시각화")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("3D 계획 덕분에 미래의 기분 좋은 장소에 대한 현실적인 아이디어를 얻을 수 있습니다.")])])], 1)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px my-40px my-md-80px"
  }, [_c('v-container', [_c('div', {
    staticClass: "product-section-title"
  }, [_c('div', {
    staticClass: "tit grey lighten-5 font-tertiary"
  }, [_vm._v("욕실을 올바르게 계획하는 방법")])])])], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("3D 욕실 플래너의 작동 방식은 다음과 같습니다.")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("세련되게 디자인된 욕실은 네 개의 벽 안에 있는 나만의 웰빙 오아시스입니다. 평화롭게 휴식을 취하고 재충전할 수 있는 휴양지입니다. 새 건물이든 개축 건물이든 집에서 향상된 삶의 질을 즐겨보세요. 온라인으로 욕실을 쉽게 계획하는 방법을 영상에서 알아보세요.")]), _c('v-btn', {
    staticClass: "rounded-xs mt-18px mt-md-24px",
    attrs: {
      "large": "",
      "elevation": "2",
      "href": "https://youtu.be/5mYSf3E0dg4?si=Q-nDh706gwvW_gmU",
      "target": "_blank",
      "color": "white"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs txt--dark font-weight-medium"
  }, [_vm._v("YouTube 비디오 지침 보러 가기")]), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('video', {
    attrs: {
      "width": "100%",
      "controls": "",
      "src": "/videos/ideas/bathroom/planner/planner.mp4"
    }
  })])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "470",
      "src": "/images/ideas/bathroom/planner/planner-1.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "tile": ""
    }
  }, [_vm._l(_vm.steps, function (item) {
    return [_c('v-expansion-panel', {
      key: item
    }, [_c('v-expansion-panel-header', {
      staticClass: "txt txt--lg txt--dark"
    }, [_vm._v(" " + _vm._s(item.header) + " ")]), _c('v-expansion-panel-content', {
      staticClass: "txt txt--sm txt--dark"
    }, [_vm._v(" " + _vm._s(item.content) + " ")])], 1)];
  })], 2)], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "1"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "tit text-center font-tertiary"
  }, [_vm._v("가장 중요한 기능을 한눈에")])])], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "470",
      "src": "/images/ideas/bathroom/planner/planner-2.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "tile": ""
    }
  }, [_vm._l(_vm.functions, function (item) {
    return [_c('v-expansion-panel', {
      key: item
    }, [_c('v-expansion-panel-header', {
      staticClass: "txt txt--lg txt--dark"
    }, [_vm._v(" " + _vm._s(item.header) + " ")]), _c('v-expansion-panel-content', {
      staticClass: "txt txt--sm txt--dark"
    }, [_c('ul', {
      staticClass: "function-content-list"
    }, [_vm._l(item.children, function (child) {
      return [_c('li', {
        key: child
      }, [_vm._v(" " + _vm._s(child) + " ")])];
    })], 2)])], 1)];
  })], 2)], 1)], 1)], 1)], 1), _c('page-section', [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/planner/planner-full.webp"
    }
  })], 1), _c('page-section', [_c('v-container')], 1), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px mb-20px mb-md-40px",
    attrs: {
      "id": "2"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "product-section-title"
  }, [_c('div', {
    staticClass: "tit grey lighten-5 font-tertiary"
  }, [_vm._v("욕실 계획에 대한 팁")])])])], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("계획하기 전에 이 점을 고려해야 합니다.")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("새 욕실이 시각적으로나 기능적으로 원하는 모든 것을 충족시키려면 욕실을 계획하기 전에 몇 가지 필수 사항을 고려하고 특정 기본 조건을 명확히 해야 합니다.")]), _c('v-expansion-panels', {
    attrs: {
      "tile": ""
    }
  }, [_vm._l(_vm.before, function (item) {
    return [_c('v-expansion-panel', {
      key: item
    }, [_c('v-expansion-panel-header', {
      staticClass: "txt txt--lg txt--dark"
    }, [_vm._v(" " + _vm._s(item.header) + " ")]), _c('v-expansion-panel-content', {
      staticClass: "txt txt--sm txt--dark"
    }, [_vm._v(" " + _vm._s(item.content) + " ")])], 1)];
  })], 2)], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실 계획을 위한 팁과 요령")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("귀하가 꿈꾸는 욕실은 세련된 디자인과 실용적인 유용성이 성공적으로 결합된 욕실이어야 합니다. 계획할 때 염두에 두어야 할 팁과 요령 목록을 정리했습니다.")]), _c('v-expansion-panels', {
    attrs: {
      "tile": ""
    }
  }, [_vm._l(_vm.tips, function (item) {
    return [_c('v-expansion-panel', {
      key: item
    }, [_c('v-expansion-panel-header', {
      staticClass: "txt txt--lg txt--dark"
    }, [_vm._v(" " + _vm._s(item.header) + " ")]), _c('v-expansion-panel-content', {
      staticClass: "txt txt--sm txt--dark"
    }, [_vm._v(" " + _vm._s(item.content) + " ")])], 1)];
  })], 2)], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실 플래너 FAQ")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("여기에서는 온라인 욕실 플래너의 취급 및 기능에 관해 자주 묻는 질문에 대한 답변을 찾을 수 있습니다.")]), _c('v-expansion-panels', {
    attrs: {
      "tile": ""
    }
  }, [_vm._l(_vm.faqs, function (item) {
    return [_c('v-expansion-panel', {
      key: item
    }, [_c('v-expansion-panel-header', {
      staticClass: "txt txt--lg txt--dark"
    }, [_vm._v(" " + _vm._s(item.header) + " ")]), _c('v-expansion-panel-content', {
      staticClass: "txt txt--sm txt--dark"
    }, [_vm._v(" " + _vm._s(item.content) + " ")])], 1)];
  })], 2)], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px my-20px my-md-40px",
    attrs: {
      "id": "3"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "product-section-title"
  }, [_c('div', {
    staticClass: "tit grey lighten-5 font-tertiary"
  }, [_vm._v("욕실 플래너를 위한 계획 템플릿")])])])], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("지금 욕실 플래너에서 엄선된 욕실 전문가가 만든 디자인을 만나보세요. 한 번의 클릭으로 계획 템플릿을 시작한 다음 무료 3D 욕실 플래너를 사용하여 욕실을 개인의 희망과 요구 사항에 빠르고 쉽게 적용할 수 있습니다.")])])], 1)], 1)], 1), _c('planner-slides'), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px my-20px my-md-40px",
    attrs: {
      "id": "4"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "product-section-title"
  }, [_c('div', {
    staticClass: "tit grey lighten-5 font-tertiary"
  }, [_vm._v("당신이 꿈꾸는 욕실을 위한 영감")])])])], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/planner/planner-3.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실의 슬로프")]), _c('p', {
    staticClass: "txt txt--sm mb-18px mb-md-24px"
  }, [_vm._v("경사진 천장은 욕실 계획에 색다른 도전을 선사합니다. 올바른 공간 설계만 있다면, 그 도전은 아늑한 웰빙 오아시스를 완성하는 기회가 될 것입니다!")]), _c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/ideas/bathroom/planner/slope"
    }
  }, [_vm._v(" 자세히 알아보기 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("개인 웰니스 오아시스")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("귀하의 욕실은 웰빙의 매력적인 장소가 됩니다. 언제든지 일상 생활에서 벗어나 편안한 휴식을 취하세요. 여기에서 나만의 웰빙 욕실을 디자인하는 방법을 알아보세요!")]), _c('div', {
    staticClass: "d-flex justify-center mt-12px mt-md-16px"
  }, [_c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "large": "",
      "color": "primary",
      "to": "/ideas/bathroom/planner/wellness"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs white--text font-weight-medium"
  }, [_vm._v("웰니스 욕실에 대한 추가 정보")]), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right-white.svg"
    }
  })], 1)], 1)]), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/planner/planner-4.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/planner/planner-5.webp"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', {
    staticClass: "line-height-2 font-size-14 font-size-md-16"
  }, [_vm._v("Antao , Theano 및 Le Valence 컬렉션 등 고품질 소재와 분위기 있는 조명으로 제작된 조화로운 욕실에서 순수한 웰빙을 누리세요 . 독립형 욕조 또는 편안한 레인 샤워를 위한 바닥 샤워 트레이 등 욕실이 웰니스 오아시스가 됩니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실에 대한 아이디어")]), _c('p', {
    staticClass: "txt txt--sm mb-18px mb-md-24px"
  }, [_vm._v("욕실은 더욱 현대적이고 편안해지고 있습니다. 당사 제품군에서는 고품질 욕실 가구, 현대적인 욕조 및 샤워 트레이, 세면기 및 설비, 훌륭한 액세서리를 찾을 수 있습니다. 귀하의 개인 웰빙 오아시스를 가구 및 장식하기 위한 아이디어를 제시해 드립니다.")]), _c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/ideas/bathroom/overview"
    }
  }, [_vm._v(" 욕실 아이디어 보러가기 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "470",
      "src": "/images/ideas/bathroom/planner/planner-7.webp"
    }
  })], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("욕실 컬렉션을 살펴보세요")])]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.collections, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "12",
        "lg": "3"
      }
    }, [_c('v-card', {
      staticClass: "mx-auto",
      attrs: {
        "tile": "",
        "flat": "",
        "to": item.link,
        "max-width": "400",
        "color": "transparent"
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), item.link ? _c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v(_vm._s(item.title) + " 알아보기")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1) : _vm._e()], 1)], 1)];
  })], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }