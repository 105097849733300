<template>
    <client-page>
        <shop-product-visual wide :image="`${path}/antao-bathtub-white-with-washbasin-and-taps.webp`" :breadcrumbs="breadcrumbs" title="Bathroom trends for 2024" />

        <page-section class="page-section--first">
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8" class="text-center">
                        <div class="tit mb-12px mb-md-16px">욕실의 최신 트렌드는 무엇입니까?</div>
                        <p class="txt txt--sm">2024년 욕실에는 기능성 외에도 모던한 디자인이 중요한 요소입니다. 현재 우리는 빠른 일상 위생을 위한 단순한 웨트룸에서 점점 더 집처럼 편안한 객실로 나아가고 있습니다. 이는 2024년 욕실에 대한 다음 10가지 새로운 트렌드에 반영됩니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <section-slide :path="path" :items="slides" />
            </v-container>
        </page-section>

        <page-section bg="right">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/infinity-showers-shower-system-matt-black-with-squaro-infinity-shower-tray.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">욕실 트렌드 6: 미니멀리즘과 명확한 라인</div>
                        <p class="txt txt--sm">우아하고 깔끔하며 명확하게 구조화된 욕실 가구를 꾸밀 때 미니멀리즘과 명확한 선에 초점을 맞추고 또 다른 최신 욕실 트렌드를 따르면 이러한 효과를 얻을 수 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <section-slide :path="path" :items="slides2" />
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row justify="center" align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <v-img :src="`${path}/antao-vanity-washbasin-morning-green-in-green-bathroom.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">욕실 트렌드 10: 원형 거울과 간접 LED 조명</div>
                        <p class="txt txt--sm">
                            욕실에는 거울이 없어야 합니다. 정사각형 모델이 여전히 사용됩니다. 그러나 현대적이고 트렌디한 욕실에서는 거울이 점점 더 둥글게 변하고 있습니다. <br />
                            특히 거울 전체에 간접 LED 조명이 적용되어 더욱 돋보입니다. 이는 에너지를 절약하고 쾌적하고 분위기 있는 조명을 제공합니다.
                        </p>
                        <v-btn large color="#fff" elevation="2" class="rounded-xs ml-1 mt-18px mt-md-24px" to="/">
                            조명 거울 찾기
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import SectionSlide from "@/components/client/dumb/section-slide.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        SectionSlide,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 아이디어 및 트렌드",
                href: "/ideas/bathroom/trends",
            },
        ],
        path: "/images/ideas/bathroom/trend-2024",
        slides: [
            {
                title: "욕실 트렌드 1: 욕실의 모던하고 매트한 색상",
                text: "옛날 옛적에 멸균 흰색이있었습니다. 욕실의 컬러 트렌드는 컬러풀한 액센트입니다. 콘크리트와 자연석 외관이 바닥재로 인기가 있습니다. 이제 벽에서는 따뜻한 흙색, 부드러운 파스텔 톤뿐만 아니라 강렬한 색상, 검정색 및 무연탄색도 찾을 수 있습니다.\r\n색상에 관계없이 2024년의 많은 욕실 디자인 아이디어는 무광 표면이 인상적입니다.",
                image: "loop-and-friends-washbasin-black-dull.webp",
                button: "욕실에서 컬러를 발견해보세요",
                link: "/ideas/bathroom/overview/colours",
            },
            {
                title: "욕실 트렌드 2: 욕실 가구의 목재 느낌",
                text: "요즘 큰 화두는 욕실 가구에 사용되는 목재의 모습입니다. 욕실이 작다면 밝은 목재 느낌이 장점입니다. 이렇게 하면 공간이 시각적으로 확대됩니다. 넓은 욕실에는 어두운 목재 느낌의 욕실 가구를 사용하여 현대적인 포인트를 더할 수도 있습니다.",
                image: "antao-bathroom-wc-furniture-washbasin-white-and+oak.webp",
            },
            {
                title: "욕실 트렌드 3: 대리석과 블랙이 우아함을 선사합니다",
                text: "타일, 욕조, 세면대, 변기 등 욕실의 트렌드는 검정색입니다. 충분한 일광과 적절한 조명을 갖춘 블랙 욕실은 전혀 어둡게 보이지 않고 오히려 우아하고 차분한 기분 좋은 분위기를 연출합니다.\r\n대리석과 함께 검은색 욕실도 특히 고급스러워 보입니다.",
                image: "antao-washbasin-mirror-hotel-bathroom.webp",
            },
            {
                title: "욕실 트렌드 4: 지속 가능성과 기술",
                text: "지속 가능성이라는 주제는 욕실에서 점점 더 주목받고 있으므로 현재 욕실 트렌드에서 빠질 수 없습니다. 무엇보다도 신기술을 사용하면 물과 에너지를 절약할 수 있습니다. 빌레로이앤보흐는 2024년 지속 가능한 욕실을 위해 스마트 홈 기술과 기타 혁신적인 기술을 활용하고 있습니다. 예를 들어, 화장실에서 TwistFlush 수세식을 사용하면 물을 절약할 수 있습니다.",
                image: "subway30-toilet-with-twistflush-technology-and-boy-standing-beside.webp",
            },
            {
                title: "욕실 트렌드 5: 어울리는 액세서리를 통한 아늑함",
                text: "욕실의 현재 트렌드에는 편안함도 포함됩니다. 하지만 욕실은 어떻게 편안해 집니까?\r\n자연스러운 색상의 수건, 욕실 러그 등의 욕실 텍스타일이 객실에 따뜻함을 선사합니다.",
                image: "subway-30-white-bathtub-washbasin-with-wooden-walls.webp",
            },
        ],
        slides2: [
            {
                title: "욕실 트렌드 7: 편안한 욕실을 위한 워크인 샤워부스",
                text: "대형 샤워실은 오랫동안 현대적인 욕실의 필수적인 부분이었으며 2024년에도 계속해서 욕실 트렌드 중 하나가 될 것입니다. 높은 샤워 트레이가 있는 오래된 샤워 부스에 비해 통풍이 잘 되고 시각적으로 공간이 넓어 보입니다.\r\n또 다른 장점은 편안하고 완전히 또는 거의 지상에 입구가 있다는 것입니다. 이는 노년층이 쉽게 접근할 수 있도록 하고 청소를 더 쉽게 해줍니다.",
                image: "squaro-edge12-freestanding-green-bathtub-white-bathroom.webp",
                button: "빌레로이앤보흐 샤워기 샬펴보기",
                link: "/",
            },
            {
                title: "욕실 트렌드 8: 시선을 사로잡는 독립형 욕조",
                text: "충분한 공간만 있으면 욕실 트렌드 8위인 자립형 욕조가 욕실에 고급스러운 느낌을 선사합니다.\r\n욕실이 독립형 욕조를 놓을 만큼 크지 않은 경우, 벽걸이형 욕조를 사용하면 독립형 욕조의 매력적인 모습을 포기하지 않고도 공간을 절약할 수 있습니다.",
                image: "theano-freestanding-bathtub-beige.webp",
                button: "지금 욕조 제품군을 살펴보세요",
                link: "/",
            },
            {
                title: "욕실 트렌드 9: 더욱 편안하고 위생적인 ​​무테 변기",
                text: "현대적인 욕실에 점점 더 많이 적용되고 있는 또 다른 추세는 테두리 없는 변기 설치입니다. 이 모델에서는 전체 내부 세면대가 플러시됩니다. 시간이 지남에 따라 석회질과 소변 잔여물이 쌓이는 가장자리 뒤의 손이 닿기 힘든 부분을 청소하는 번거로움을 덜 수 있습니다.",
                image: "antao-toilet-in-beige-bathroom.webp",
                button: "빌레로이앤보흐 화장실",
                link: "/",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>