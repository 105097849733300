<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/wellness/visual.webp" :breadcrumbs="breadcrumbs" title="Wellness bathroom" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">욕실에 매력적인 웰니스 오아시스가 있어 언제든지 자신만의 공간에서 일상 생활에서 벗어나 편안한 휴식을 취할 수 있습니다. 여기서는 집에 개별 웰니스 욕실을 설치하는 방법을 읽어볼 수 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey white">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <template v-for="(item, index) in navigation">
                                <v-col cols="12" :key="index">
                                    <router-link :to="`#${index + 1}`" class="txt txt--sm d-flex align-center">
                                        <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                        {{ item }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <dot-list>
                                <template v-for="item in overview">
                                    <li :key="item">
                                        {{ item }}
                                    </li>
                                </template>
                            </dot-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="1">
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit mb-12px mb-md-16px">개인별 웰빙 오아시스 만들기</div>
                        <p class="txt txt--sm">
                            욕실은 종종 단순한 습식 방과 유사한 기능적인 공간으로 간주되었습니다. 오늘날 현대적인 욕실은 작은 웰니스 공간으로 기능해야 하며 최고의 디자인 표준을 충족해야 합니다. 웰빙의 오아시스 중심에는 넓은 바닥에 샤워실과 독립형 욕조가 있어 욕실을 개인 휴식 공간으로 바꿔줍니다. <br />
                            욕실에서의 웰빙은 개인 관리 그 이상을 의미합니다. 여기에는 기분이 좋고, 휴식을 취하고, 긴장을 풀 수 있는 공간이 있습니다. 이를 달성하려면 리모델링 시 욕실을 시각적으로 차분한 공간으로 만들어야 합니다. 개방된 공간이 많고 위생용품과 가구를 그룹별로 배치하여 균형 잡힌 분위기를 조성할 수 있습니다.
                        </p>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card color="grey lighten-5" tile elevation="4" class="pa-24px pa-md-40px">
                            <div class="mb-12px mb-md-16px">
                                <v-row align="center" class="row--sm">
                                    <v-col cols="auto">
                                        <v-icon>mdi-information-outline</v-icon>
                                    </v-col>
                                    <v-col class="font-weight-medium font-size-18">팁</v-col>
                                </v-row>
                            </div>
                            <p class="txt txt--sm">욕실을 디자인할 때 전문가와 함께 구조적 요구 사항을 명확히 하고 평면도를 작성하고 비용을 계산하십시오. 그런 다음에야 계획할 때 옵션으로 제공되는 월풀 욕조나 마사지 제트와 같은 기능을 디자인 아이디어로 고려해야 합니다.</p>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <section-slide :path="path" :items="slides" />
            </v-container>
        </page-section>

        <page-section class="page-section--grey mb-0">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit tit--sm mb-12px mb-md-16px">웰빙 목욕에는 무엇이 필요합니까?</div>
                        <p class="txt txt--sm">웰니스 욕실은 최고급 소재와 적절한 색상, 액세서리를 포함한 고품질 가구로 이루어진 조화로운 전체 구조가 인상적입니다. 분위기 있는 조명, 부드러운 음악 소리, 그리고 객실 향과 에센셜 오일과 같은 기타 "기분 좋은 제품"이 개인 웰니스 욕실을 완성합니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey pt-0 mt-0">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit tit--sm mb-12px mb-md-16px">욕실의 쾌적한 조명 디자인</div>
                        <p class="txt txt--sm">
                            욕실의 분위기 있는 조명 디자인은 아늑함을 더해주고 욕실에 다양한 요소를 더해줍니다. 낮에는 큰 창문을 통해 빛이 들어오지만, 저녁에는 천장의 작은 LED 조명이 변화하는 조명 시나리오로 인상적입니다. 이 틈새 조명 외에도 실제 양초나 LED 양초를 사용하여 욕실에 편안함을 더할 수 있습니다. 대규모 조명 대신 여러 개의 작은 광원을 사용하면 저녁 목욕 경험을 더욱 즐겁게 만들 수 있습니다. 세련되고 특이한 조명 효과도 웰빙 욕실을 더욱 돋보이게 합니다. 예를 들어, 방의 개별 영역을
                            예술적으로 강조하는 웅장한 샹들리에나 은은한 캐비닛 조명이 있습니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <three-images :path="path" first="finion-bath-tub-and-furniture-in-blue-bathroom.webp" second="finion-bathroom-mirror-with-lighting-with-washbasin-and-vanity-unit.webp" third="artis-washbasin-white-with-conum-tap-and-finion-bath.webp" />
            </v-container>
        </page-section>

        <page-section>
            <v-row align="center" class="row--lg grey lighten-5 my-md-0">
                <v-col cols="12" md="7" class="py-md-0">
                    <v-container class="mx-md-0 px-md-0">
                        <v-img :src="`${path}/finion-bathroom-furniture-blue.webp`" />
                    </v-container>
                </v-col>
                <v-col cols="12" md="3" class="py-md-0">
                    <v-container>
                        <div class="tit tit--sm mb-12px mb-md-16px">당신의 색깔을 보여주세요 – 정신에 미치는 영향</div>
                        <p class="txt txt--sm">
                            색상은 정신에 과소평가되어서는 안되는 영향을 미치기 때문에 욕실을 꾸밀 때 조화로운 색상 개념을 고려해야 합니다. 색상 심리학에 따르면, 차분한 효과가 있어 휴식에 도움이 되는 것은 주로 파란색, 녹색, 파스텔 톤과 같은 차가운 색상입니다. 화이트, 크림, 그레이 등의 뉴트럴 톤도 차분하고 조화로운 전체적인 이미지를 만들어줍니다. <br />
                            밝은 색상은 공간을 시각적으로 확대하므로 작은 욕실에 특히 적합합니다. 넓은 웰니스 욕실이 있다면 강한 톤을 적절히 활용하면 된다. 그러나 귀하의 웰빙 오아시스가 너무 과부하된 것처럼 보이지 않도록 대비되는 방법으로만 사용하십시오.
                        </p>
                    </v-container>
                </v-col>
            </v-row>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8">
                        <v-card tile elevation="4" class="pa-24px pa-md-40px">
                            <div class="mb-12px mb-md-16px">
                                <v-row align="center" class="row--sm">
                                    <v-col cols="auto">
                                        <v-icon>mdi-information-outline</v-icon>
                                    </v-col>
                                    <v-col class="font-weight-medium font-size-18">팁</v-col>
                                </v-row>
                            </div>
                            <p class="txt txt--sm">예를 들어 벽 색상을 욕실 액세서리나 욕실 텍스타일에 맞추는 등 원하는 색상을 가구 아이디어에 적용할 수 있습니다.</p>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit tit--sm mb-12px mb-md-16px">레인 샤워부터 월풀까지</div>
                        <p class="txt txt--sm">많은 현대적인 욕실의 중심에는 독립형 웰빙 욕조가 있습니다. 개인 웰니스 오아시스의 하이라이트로 일상 생활에서 벗어나 휴식을 취할 수 있는 공간으로 여러분을 초대합니다. 빌레로이 앤 보흐(Villeroy & Boch) 컬렉션을 사용하면 디자이너 욕조를 집에 들여와 웰빙 공간의 시선을 사로잡는 럭셔리한 공간으로 만들 수 있습니다. Aveo New Generation 스타일의 타원형, 곡선형 또는 타원형 Loop & Friends 변형: 당사의 독립형 욕조는 최고 수준의 목욕 문화를 제공합니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <idea-product-list :path="path" :items="products" />
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <p class="txt txt--sm">추운 계절이나 그 사이에는 월풀 기능이 욕조의 편안함을 높여줍니다. 작은 어메니티로서 풀옵션 수영장 못지않은 쾌적한 휴식을 약속드립니다. Villeroy & Boch에서는 Architectura, Oberon 2.0, Squaro Edge 등 우아한 욕조와 결합할 수 있는 다양한 월풀 시스템을 만나보실 수 있습니다.</p>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card color="grey lighten-5" tile elevation="4" class="pa-24px pa-md-40px">
                            <div class="mb-12px mb-md-16px">
                                <v-row align="center" class="row--sm">
                                    <v-col cols="auto">
                                        <v-icon>mdi-information-outline</v-icon>
                                    </v-col>
                                    <v-col class="font-weight-medium font-size-18">팁</v-col>
                                </v-row>
                            </div>
                            <p class="txt txt--sm">아침이나 퇴근 후 장시간 목욕할 시간이 없으신가요? 그렇다면 다양한 제트 유형으로 완벽한 샤워 경험을 선사하는 고품질 샤워기와 레인 샤워 헤드로 감각을 만족시켜 보세요.</p>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">집에서의 웰빙과 휴식</div>
                        <p class="txt txt--sm">집에서 완벽한 스파 느낌을 원하신다면 향, 색상, 소리가 조화롭게 어우러진 편안한 스파를 경험해 보세요. 힘든 하루를 보낸 후에도 아로마, 색색의 조명, 좋아하는 음악으로 몸과 마음을 가꾸고 모든 감각을 편안하게 해줄 수 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7" order-md="2">
                        <v-img :src="`${path}/infinity-showers-shower-tap-witch-black-walls.webp`" />
                    </v-col>
                    <v-col cols="12" md="5" order-md="1">
                        <div class="tit tit--sm mb-12px mb-md-16px">온몸으로 여유로움을 느껴보세요</div>
                        <p class="txt txt--sm">
                            가끔씩 따뜻한 샤워뿐만 아니라 찬물 샤워나 콘트라스트 샤워를 하여 온몸의 이완을 느껴보세요. 자주 피곤하고 지친다면, 예를 들어 차가운 얼굴 샤워나 차가운 팔 목욕 등이 혈액 순환을 자극할 수 있습니다. 다리 근육을 풀어주는 차가운 허벅지와 무릎 스프레이는 다리 부종과 고혈압을 예방하는 데 도움이 됩니다. 춥고 습한 날씨에는 온도가 38도까지 천천히 올라가는 족욕을 추천합니다. Villeroy & Boch에서는 이러한 용도에 도움이 되는 모든 액세서리를 구입할 수 있습니다. 예를 들어 샤워 시
                            최대 3가지 제트 유형을 사용하여 타겟 마사지를 가능하게 하는 핸드 샤워 등이 있습니다. <br />
                            Tip. 단파장 적외선은 몸에 열을 발생시켜 통증완화 효과가 있어 더욱 편안한 휴식을 약속드립니다. 전문가가 관련 모듈을 샤워실에 직접 설치할 수 있으므로 이를 위해 추가 객실이 필요하지 않습니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit tit--sm mb-12px mb-md-16px">맛, 음악, 따뜻한 차</div>
                        <p class="txt txt--sm">감각을 자극하는 실내 향기, 편안한 음악, 따뜻한 차와 함께 나만의 스파 경험을 완성해보세요. 라벤더, 바닐라, 레몬밤과 같은 향은 차분한 웰니스 경험을 선사합니다. 목욕물에 에센셜 오일을 한 방울씩 첨가하면 향이 코를 통해 몸 전체에 스며드는 것을 느껴보세요. 욕실에서 자연스러운 향기를 선호한다면 빌레로이 앤 보흐 꽃병에 좋아하는 꽃다발을 담아 후각을 자극해보세요.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center" align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <v-img :src="`${path}/woman-with-drink-in-bathtub.webp`"></v-img>
                    </v-col>
                    <v-col cols="12" md="5">
                        <p class="txt txt--sm">웰빙 경험을 위해 눈을 감고 편안한 음악에 빠져보세요. 욕조 목욕을 보완하기 위해 차분한 효과가 있는 부드러운 악기 음악이나 자연의 소리를 추천합니다. 약간의 간식으로 웰니스 경험을 마무리하세요. 발레리안 또는 카모마일 차 한 잔은 마음을 따뜻하게 해주고 휴식을 더해줍니다. 우아한 도자기 컵으로 차를 즐겨보세요. "Amazonia Anmut" 또는 "Rose Garden"과 같은 컬렉션을 사용하면 차 맛이 두 배 더 좋아집니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit tit--sm- mb-12px mb-md-16px">욕실에 기분 좋은 포인트를 설정하세요</div>
                        <p class="txt txt--sm">매일 즐길 수 있는 욕실에 기분 좋은 액센트를 만들어 보세요. 특이한 개별 요소로 웰빙 욕실을 강화하여 웰빙을 높일 수도 있습니다.</p>
                        <div class="tit-wrap tit-wrap--lg" />
                        <dot-list>
                            <template v-for="item in points">
                                <li :key="item">
                                    {{ item }}
                                </li>
                            </template>
                        </dot-list>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";

import SectionSlide from "@/components/client/dumb/section-slide.vue";
import ExpansionPanels from "@/components/client/ideas/expansion-panels.vue";
import DotList from "@/components/client/ideas/dot-list.vue";
import ThreeImages from "@/components/client/ideas/three-images.vue";
import IdeaProductList from "@/components/client/ideas/idea-product-list.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,

        SectionSlide,
        ExpansionPanels,
        DotList,
        ThreeImages,
        IdeaProductList,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 플래너",
                href: "/ideas/bathroom/planner",
            },
        ],
        path: "/images/ideas/bathroom/wellness",
        navigation: [
            "개인별 웰빙 오아시스 만들기",
            "웰빙 목욕에는 무엇이 필요합니까?",
            "집에서의 웰빙과 휴식",
            "집에서 쉽게 즐기는 웰니스",
            //
        ],
        overview: [
            "웰니스 욕실에 공기정화 녹색식물을 배치하여 실내공기 개선",
            "현대적인 비데 변기, 고품질 설비 및 온도 조절 장치로 편안함을 보장합니다.",
            "조명 디자인이 조화로운지 확인하십시오.",
            "젖은 공간에서 시선을 사로잡는 현대식 샤워 시설이나 독립형 고급 욕조를 이용해보세요.",
            //
        ],
        slides: [
            {
                title: "일상에서 벗어나 휴식을 취하세요",
                text: "집에 있는 웰니스 욕실에서는 힘든 일상 생활에서 회복하기 위해 개인별 기분 좋은 액센트를 설정해야 합니다. 차이를 만드는 것도 작은 것들입니다. 직장에서 힘든 하루를 보낸 후에는 촛불 옆에서 목욕을 즐기고 물에 에센셜 오일을 첨가하여 감각을 유혹해보세요. 자신만의 웰니스 욕조에서 자연의 소리, 명상적인 만트라 또는 좋아하는 노래를 들으며 몸을 움직여보세요. 목욕 후 진정 케어 로션을 피부에 바르고 소파나 이불 밑에 책을 올려 편안하게 쉬어보세요.",
                image: "antao-bathtub-green-with-woman-filling-water.webp",
            },
            {
                title: "웰니스 풀에서 신선한 공기를 즐겨보세요",
                text: "욕실의 웰니스는 편안함뿐만 아니라 공기가 잘 통하는 상쾌함도 중요합니다. 욕실을 계획하거나 기존 욕실을 개조하고 월풀이나 스팀 샤워기를 추가하려는 경우 방에 창문이 하나 이상 있어야 합니다. 이를 통해 정기적으로 환기하고 웰빙 욕실에 대한 정교한 아이디어를 구현할 수 있는 기회를 얻을 수 있습니다. 창문이 없는 욕실이 있는 경우에도 건강을 위협하는 곰팡이를 예방하려면 증기가 빠져나가야 합니다. 이 경우 좋은 공기 교환을 보장하는 최신 환기 시스템을 사용하십시오. 통풍을 보장하고 팬을 사용하여 열린 욕실 문을 통해 공기 교환을 유도하면 신선한 공기가 이웃 방에서 욕실로 도달할 수도 있습니다.",
                image: "theano-curved-edition-bathtub-white-in-wellness-bathroom.webp",
            },
            {
                title: "식물의 긍정적인 효과",
                text: '녹색 식물은 현대적인 욕실에 훌륭한 추가 요소입니다. 그들은 모든 욕실을 더욱 편안하게 만들고 건강 증진 효과가 있습니다. "천연 청정제"로서 공기 중 오염 물질을 걸러냅니다. 일부 품종은 욕실의 신선한 공기를 최적화하는 데 특히 적합합니다. 일광이 거의 필요하지 않고 높은 습도에도 견딜 수 있는 관엽 식물을 선택하십시오. 대나무, 국화, 담쟁이덩굴은 공기 정화 식물로 여겨지며, 행운의 깃털과 하트잎 식물은 장식적인 눈길을 끄는 식물로 돋보입니다. 조화로운 전체 그림을 만들려면 관엽 식물을 적절한 용기에 배열하십시오. Villeroy & Boch에서는 식물을 장식적으로 표현하는 유리와 도자기로 만든 완벽한 모양의 꽃병을 만나보실 수 있습니다.',
                image: "loop-friends-bathtub-in-front-of-a-stone-wall.webp",
            },
            {
                title: "욕실에서의 웰빙 – 또 무엇이 중요한가요?",
                text: "집에서 스파를 할 때 편안함과 위생을 보장해야 합니다. 예를 들어, 좌석 난방 및 원격 제어와 같은 현대적인 추가 기능을 통해 젖은 공간을 최고 수준으로 업그레이드하는 현대적인 비데 변기를 설치하십시오. Villeroy & Boch에서는 온도가 좋은 물을 제공하는 일류 피팅도 만나보실 수 있습니다. 샤워실에는 최첨단 샤워 온도 조절 장치가 귀하의 웰빙 경험에 기여합니다. 정사각형 또는 원형 온도 조절 장치는 쉽게 조절 가능한 샤워 홀더를 통해 최대한의 편안함을 제공하며 뜨거운 물로 인한 화상을 방지하는 통합 화상 방지 장치가 장착되어 있습니다.",
                image: "universal-showers-shower-tap-matt-black-witch-architectura-shower-tray.webp",
            },
        ],
        products: [
            {
                title: "실내 온수 욕조",
                text: "집에서 즐기는 럭셔리 스파",
                image: "subway-30-indoor-whirlpool-with-roof-window.webp",
                link: "/",
                cols: "6",
            },
            {
                title: "독립형 욕조",
                text: "개인 웰니스 오아시스에서 일상에서 벗어나세요.",
                image: "aveo-new-generation-bathtub-with-towels.webp",
                link: "/",
                cols: "6",
            },
        ],
        points: [
            "귀하의 웰빙 영역을 완벽하게 보완하는 작지만 고급 액세서리를 사용하십시오. 자연이 들어오게 하는 자연을 모티브로 한 작은 장식 그릇은 어떠세요? 아니면 욕실 가구의 색상과 일치시킬 수 있는 자가접착식 프라이버시 필름을 창문에 사용하시겠습니까? 은은한 포인트를 주는 LED 조명이 일체형으로 적용된 욕실 가구도 스타일리시합니다.",
            '예를 들어, Villeroy & Boch의 장식용 케이크 스탠드를 사용하면 스타일리쉬하게 음식을 정리할 수 있을 뿐만 아니라 화장품 병과 화장품 도구를 놓을 수도 있습니다. 칫솔컵을 작은(깨끗한) 꽃병으로 교체하면 더욱 창의성과 미적 감각을 보여줄 수 있습니다. 작은 "드롭" 꽃병에 칫솔을 매력적인 방식으로 배열하고 욕실을 창의적으로 업그레이드할 수 있습니다.',
            //
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>