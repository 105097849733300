var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/bathroom/repairs/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Bathroom repairs"
    }
  }), _c('page-section', {
    staticClass: "pt-40px pt-md-80px"
  }, [_c('v-container', {
    staticClass: "mt-50px mt-md-100px"
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("우리 욕실이 웰빙의 오아시스로 남아 있기 위해서는 때때로 수리 작업을 수행해야 합니다. 욕실의 작은 결함을 수리하는 방법을 설명합니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실 수리에 유용한 팁")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_c('p', [_vm._v("때때로 욕실에는 케이블, 파이프, 변기, 부속품 또는 샤워실 문과 같은 결함이 있습니다. 그렇다면 어느 정도 광범위한 욕실 수리가 필요합니다. 배수구가 막히거나, 배관에서 물이 새거나, 수도꼭지에서 물이 떨어지면 불쾌합니다. 반면에 귀중한 물은 손실됩니다.")]), _c('p', [_vm._v(" 하지만 때때로 욕실의 다른 것들도 수리해야 합니다. 예를 들어, 여기에는 타일 사이의 실리콘 조인트 수리가 포함됩니다. 그리고 타일 자체도 손상될 수 있습니다. 아침에 피곤해서 거울 앞에 서서 헤어드라이어를 찾으려고 손을 뻗었는데 헤어드라이어가 손에서 떨어졌다고 상상해 보세요. 헤어드라이어는 바닥에 대한 충격을 견뎌냈지만 이제 바닥 타일에 균열이 생겼습니다. 다치는 사람이 없도록 당연히 교체해야 합니다. 그러나 욕실 수리를 불가피하게 만드는 것은 단순한 마모의 징후인 경우가 많습니다. 스스로 할 수 있는 욕실 수리와 필요한 도구 및 재료에 대해 설명하겠습니다. ")])])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/repairs/venticello-toilet-in-bathroom-with-squaro-infinity-shower-tray.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실 배관 문제 해결")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 누구나 이전에 경험해 본 적이 있을 것입니다. 변기의 물이 더 이상 내려가지 않고 물이 계속 흐릅니다. 비용이 발생할 수 있습니다. 동시에 물은 불필요하게 낭비됩니다. 따라서 수리를 통해 문제를 신속하게 해결하는 것이 중요합니다. 씰, 플로트, 흡입 밸브 등 플러셔의 대부분 구성 요소는 쉽게 수리하거나 교체할 수 있습니다. 구매 시 교체 부품이 변기 수세식에 맞는지 확인하세요. 그리고 위생도기가 손상되면 어떻게 되나요? 싱크대나 샤워 트레이의 가벼운 긁힘은 특수 수리 키트를 사용하여 수리할 수 있습니다. 큰 손상이 있으면 새 것을 구입하는 것이 좋습니다. ")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('swiper', _vm._b({}, 'swiper', {
    options: _vm.options
  }, false), [_vm._l(_vm.items, function (item) {
    return [_c('swiper-slide', {
      key: item.title
    }, [_c('v-row', {
      staticClass: "row--lg",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "5",
        "order": "2",
        "order-md": "1"
      }
    }, [_c('v-card', {
      staticClass: "h-md-400px d-md-flex align-center"
    }, [_c('div', [_c('div', {
      staticClass: "tit mb-12px mb-md-16px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "txt txt--sm txt--dark font-weight-medium"
    }, [_vm._v(" " + _vm._s(item.subtitle) + " ")]), _c('p', {
      staticClass: "txt txt--sm mb-18px mb-md-24px"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])])])], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "7",
        "order": "1",
        "order-md": "2"
      }
    }, [_c('v-card', {
      staticClass: "position-relative mb-50px mb-md-0",
      attrs: {
        "tile": "",
        "img": item.image
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 674 / 504
      }
    }), _c('div', {
      staticClass: "swiper-controler"
    }, [_c('v-row', {
      staticClass: "row--xs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "swiper-button swiper-button--prev",
      attrs: {
        "x-small": "",
        "icon": "",
        "outlined": ""
      }
    }, [_c('v-img', {
      attrs: {
        "max-width": "32",
        "src": "/images/icon/icon-swiper-left.svg"
      }
    })], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "swiper-pagination"
    })]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "swiper-button swiper-button--next",
      attrs: {
        "x-small": "",
        "icon": "",
        "outlined": ""
      }
    }, [_c('v-img', {
      attrs: {
        "max-width": "32",
        "src": "/images/icon/icon-swiper-right.svg"
      }
    })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px my-20px my-md-40px"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실 타일 수리")]), _c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 타일은 마모와 습기로부터 벽을 보호합니다. 그러나 충격에 취약합니다. 그러나 몇 가지 요령을 사용하면 손상된 욕실 타일을 수리할 수 있습니다. 작은 균열이나 흠집은 터치업 펜으로 쉽게 제거할 수 있습니다. 특수 타일 접착제로 부서진 부분을 다시 부착합니다. 클래식 필러는 더 큰 균열과 드릴 구멍을 막는 데 이상적입니다. 그러나 필러에는 석고가 포함되어 있어 수분을 흡수합니다. 그래서 많은 사람들이 실리콘을 선택합니다. 이 소재는 다양한 색상으로 제공되며 습기에 견딜 수 있습니다. 에폭시 수지는 타일의 균열을 메우는 데에도 적합합니다. ")]), _c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }), _c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("타일 ​​수리용 도구 및 재료")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("타일을 수리하려면 장인의 기술은 물론 다양한 도구와 재료가 필요합니다.")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_c('ul', {
    staticClass: "list"
  }, [_c('li', [_vm._v("타일을 수리하는 데 필요한 가장 중요한 도구에는 무선 드릴, 멀티 샌더 및 멀티 도구가 있습니다.")]), _c('li', [_vm._v("그 외에도 다양한 보조 수단이 필요합니다. 다음 도구는 특히 중요합니다: 브러시, 긁는 도구, 천 또는 청소용 브러시, 필러 대패, 플라스틱 주걱, 펜치, 와이퍼, 진공 청소기 및 장갑.")]), _c('li', [_vm._v("또한 멀티 샌더 또는 손 샌딩(예: 240방)을 위한 사포, 실리콘 또는 2성분 필러 및 타일 페인트가 필요합니다.")])])])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/repairs/architectura-shower-tray-anthracite-with-grey-tiles.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("적합한 교체 타일 선택")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("타일의 작은 흠집이나 손상은 비교적 쉽게 수리할 수 있습니다. 그러나 큰 균열이나 균열이 있는 경우 유일한 해결책은 종종 새 타일로 교체하는 것입니다. 하지만 올바른 기술과 도구를 사용하면 스스로 할 수 있습니다. 이상적으로는 이 상황을 위해 일부 타일을 저장했습니다. 그런 다음 즉시 타일 교체를 시작할 수 있습니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실 문제 예방을 위한 팁")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실은 따뜻하고 습합니다. 이러한 사실과 일상적인 사용으로 인해 손상이 빠르게 발생할 수 있습니다. 그러나 다음 팁을 사용하면 이러한 문제를 예방할 수 있는 경우가 많습니다.")])]), _c('ol', {
    staticClass: "tip-list"
  }, [_vm._l(_vm.tips, function (item, index) {
    return [_c('li', {
      key: index
    }, [_c('v-row', {
      staticClass: "row--xs"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-avatar', {
      staticClass: "border border-dark font-size-12 txt--dark d-md-none",
      attrs: {
        "size": "18"
      }
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('v-avatar', {
      staticClass: "border border-dark txt txt--sm txt--dark d-none d-md-block",
      attrs: {
        "size": "24"
      }
    }, [_vm._v(" " + _vm._s(index + 1) + " ")])], 1), _c('v-col', [_c('div', {
      staticClass: "txt txt--sm mt-2px mt-md-0"
    }, [_c('div', {
      staticClass: "font-weight-medium txt--dark mb-4px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', [_vm._v(" " + _vm._s(item.text) + " ")])])])], 1)], 1)];
  })], 2)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }