var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/kitchen/material/ceramicplus-visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "CeramicPlus in The Kitchen"
    }
  }), _c('page-section', {
    staticClass: "py-40px py-md-80px my-40px my-md-80px grey lighten-5"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg text-center",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm mb-12px mb-md-16px"
  }, [_vm._v("더 많은 청결함 - 삶의 질을 위한 더 많은 시간")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" CeramicPlus는 깨끗하고 특히 관리가 쉬운 세라믹 표면을 위한 혁신적인 개발품입니다. CeramicPlus를 사용한 세라믹 마감은 결정적인 이점을 제공합니다. 접촉 시 액체는 즉시 방울에서 구슬로 결합되어 싱크대로 굴러갑니다. 먼지, 석회 등의 잔여물은 표면에 거의 들러붙지 않고 즉시 용해되어 제거됩니다. 이렇게 하면 CeramicPlus로 코팅된 세라믹을 더 쉽게 청소할 수 있습니다. 건조된 석회질 얼룩은 강력하고 마모성이 있으며 환경에 유해한 세제를 사용하지 않고도 즉시 제거할 수 있습니다. CeramicPlus는 모든 표면에 최적의 위생과 상쾌한 청결을 제공합니다. 이 소재는 의료 및 화장품을 포함한 일반적인 산 및 알칼리 기반 가정용품에 내성이 있습니다. ")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "image-wrap"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/kitchen/material/ceramic-plus-1.webp",
      "max-width": "672"
    }
  }), _c('v-img', {
    staticClass: "positioned-image positioned-image--1",
    attrs: {
      "src": "/images/ideas/kitchen/material/ceramic-plus-2.webp"
    }
  }), _c('v-img', {
    staticClass: "positioned-image positioned-image--2",
    attrs: {
      "src": "/images/ideas/kitchen/material/ceramic-plus-3.webp"
    }
  })], 1)])], 1), _c('page-section', {
    staticClass: "py-40px py-md-80px my-40px my-md-80px grey lighten-5"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm mb-12px mb-md-16px"
  }, [_vm._v("손쉬운 관리로 최고의 품질")]), _c('ul', {
    staticClass: "list txt txt--sm"
  }, [_c('li', [_vm._v("긁힘 방지 및 내구성이 뛰어난 표면")]), _c('li', [_vm._v("위생적이고 깨끗함")]), _c('li', [_vm._v("알칼리 및 산에 둔감함")]), _c('li', [_vm._v("사용된 재료에 대한 높은 품질 기준")])])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "txt txt--sm txt--dark text-center pb-24px pb-md-40px"
  }, [_c('div', [_c('strong', {
    staticClass: "font-weight-bold"
  }, [_vm._v("이미지 1:")]), _vm._v(" 세련된 CeramicPlus")]), _c('div', [_c('strong', {
    staticClass: "font-weight-bold"
  }, [_vm._v("이미지 2:")]), _vm._v(" 기존 세라믹")])]), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "src": "/images/ideas/kitchen/material/comparison-1.webp",
      "max-width": "672"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "368",
      "src": "/images/ideas/kitchen/material/comparison-2.webp"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }