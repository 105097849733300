var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/bathroom/visual.jpg",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Bathroom Overview"
    }
  }), _c('page-section', {
    staticClass: "pt-40px pt-md-80px"
  }, [_c('v-container', {
    staticClass: "mt-50px mt-md-100px"
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실의 모든 것")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("오래된 욕실을 새롭게 개조하거나 새로운 욕실을 설치하고 싶지만 아이디어가 떠오르지 않으신가요? 그렇다면 지금이 바로 시작하기 좋은 때입니다. 욕실을 현대적이고 편안하게 만드는 방법과 필요한 요소를 알려드립니다. Villeroy & Boch의 디자인, 혁신, 최신 트렌드, 그리고 실용적인 팁에서 영감을 얻어보세요. 당신의 욕실을 웰빙을 위한 진정한 오아시스로 변신시켜보세요!")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px my-20px my-md-40px"
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#1"
    }
  }, [_vm._v(" 모든 욕실 주제 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#2"
    }
  }, [_vm._v(" 신상품 및 인기 컬렉션 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#3"
    }
  }, [_vm._v(" 욕실 제품 살펴보기 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "1"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('h2', {
    staticClass: "tit text-center"
  }, [_vm._v("욕실에 관한 흥미로운 주제를 찾아보세요")])]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.subjects, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "to": item.link
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v("자세히 알아보기")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("당신을 위해 선정된 주요 주제들")])]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.themes, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "to": item.link
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "tit tit--sm grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v("자세히 알아보기")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('overview-revolution', {
    attrs: {
      "id": "2"
    }
  }), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("가장 인기 있는 욕실 컬렉션")])]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.collections, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "12",
        "lg": "4"
      }
    }, [_c('v-card', {
      staticClass: "mx-auto",
      attrs: {
        "tile": "",
        "flat": "",
        "href": item.link,
        "target": "_blank",
        "max-width": "400"
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v("자세히 알아보기")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('overview-categories', {
    attrs: {
      "id": "3"
    }
  }), _c('overview-popular-slides'), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "product-section-title"
  }, [_c('span', {
    staticClass: "tit font-weight-regular font-tertiary white"
  }, [_vm._v("유용한 도구 및 서비스")])])])], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--xxl",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/service-1.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs mb-20px grey--text font-weight-light"
  }, [_vm._v("전문적인 조언")]), _c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실 플래너 살펴보기")]), _c('p', {
    staticClass: "txt txt--sm mb-18px mb-md-24px"
  }, [_vm._v("욕실을 계획할 때는 실용적인 온라인 욕실 플래너를 활용해보세요. 초기 디자인 아이디어를 정리한 후, 욕실 플래너를 통해 아이디어를 3D 형식으로 시각화하고 구체화할 수 있습니다.")]), _c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "href": "/ideas/bathroom/planner"
    }
  }, [_vm._v(" 욕실 플래너 보러 가기 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('overview-exploration')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }