var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": `${_vm.path}/visual.webp`,
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Scandinavian-style bathroom"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Hygge 다음에는 Scandi가 옵니다! 스칸디나비아 스타일로 욕실을 꾸미는 방법을 알려드리겠습니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_vm._l(_vm.navigation, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('router-link', {
      staticClass: "txt txt--sm d-flex align-center",
      attrs: {
        "to": `#${index + 1}`
      }
    }, [_c('v-img', {
      staticClass: "mr-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-down.svg"
      }
    }), _vm._v(" " + _vm._s(item) + " ")], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('dot-list', [_vm._l(_vm.overview, function (item) {
    return [_c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "right"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/avento-washbasin-vanity-unit-with-washbasin-in-wooden-bathroom.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실을 위한 스칸디나비아 스타일: 미학과 기능성")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실의 스칸디나비아 스타일은 기본적으로 자연성, 지속 가능성, 기능성 및 미니멀리즘이라는 네 가지 측면에서 비롯됩니다. 따라서 방을 장식으로 가득 채우지 않고 필수품에 집중하는 것이 중요합니다. 동시에 가구와 시스템은 심미적일 수 있으며 집과 같은 편안한 분위기를 조성하는 데 기여할 수 있습니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("스칸디 욕실의 단순함 – 소재 및 구조")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("스칸디나비아 스타일로 살고 있거나 욕실을 스칸디나비아 스타일로 꾸미고 싶다면 천연 소재를 무시할 수 없습니다. 물론 여기서 가장 눈에 띄는 대표자는 우드이다. 욕실의 스칸디나비아 타일은 일반적으로 가볍고 질감이 있는 표면을 가지고 있습니다. 슬레이트 타일도 인기가 있습니다. 양모 카펫은 스칸디나비아 스타일을 완성합니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/loop-and-friends-japandi-style-bathroom.webp`
    }
  })], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "right"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/antao-white-washbasin-wooden-furniture.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("미니멀리스트 Scandi 디자인 - 완벽한 조명 조건")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("빛은 스칸디 스타일의 가장 중요한 측면 중 하나입니다. 이상적으로는 일광 욕실이 있습니다. 그러나 조명 거울은 Scandi 욕실에 완벽한 추가 요소이기도 합니다. 이러한 인공 광원은 따뜻한 빛을 방출하고 실내를 균일하게 조명합니다. 색상을 선택할 때 스칸디나비아 사람들은 빛을 반사하고 공간감과 차분한 느낌을 주는 흰색, 베이지, 크림, 밝은 회색과 같은 밝은 톤을 사용합니다.")]), _c('v-btn', {
    staticClass: "rounded-xs mt-18px mt-md-24px",
    attrs: {
      "large": "",
      "color": "white",
      "to": "/ideas/bathroom/overview/lighting"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs font-weight-medium"
  }, [_vm._v("욕실의 조명 조건에 대한 추가 정보")]), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("Scandi 욕실에 딱 맞는 제품!")])]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item.title,
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "to": item.link
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "eager": "",
        "src": `${_vm.path}/${item.image}`
      }
    }), _c('div', {
      staticClass: "tit tit--xs mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "txt tit--xs mb-12px mb-md-16px"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('div', {
      staticClass: "d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v(" " + _vm._s(item.button) + " ")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)], 1)], 1)];
  })], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }