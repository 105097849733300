<template>
    <page-section>
        <v-container>
            <v-sheet class="section-bg" />

            <swiper v-bind="{ options }">
                <template v-for="item in items">
                    <swiper-slide :key="item">
                        <v-row align="center" class="row--lg">
                            <v-col cols="12" md="5" order="2" order-md="1">
                                <v-card color="grey lighten-5" class="h-md-400px d-md-flex align-center">
                                    <div>
                                        <div class="tit mb-12px mb-md-16px">
                                            {{ item.title }}
                                        </div>
                                        <p class="txt txt--sm mb-18px mb-md-24px">
                                            {{ item.text, }}
                                        </p>
                                        <template v-if="item.button">
                                            <v-btn large color="#fff" elevation="2" class="rounded-xs ml-1" :href="item.link" target="_blank">
                                                {{ item.button }}
                                                <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                            </v-btn>
                                        </template>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="7" order="1" order-md="2">
                                <v-card tile :img="item.image" class="position-relative mb-50px mb-md-0">
                                    <v-responsive :aspect-ratio="674 / 504" />
                                    <div class="swiper-controler">
                                        <v-row align="center" class="row--xs">
                                            <v-col cols="auto">
                                                <v-btn x-small icon outlined class="swiper-button swiper-button--prev">
                                                    <v-img max-width="32" src="/images/icon/icon-swiper-left.svg" />
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <div class="swiper-pagination" />
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn x-small icon outlined class="swiper-button swiper-button--next">
                                                    <v-img max-width="32" src="/images/icon/icon-swiper-right.svg" />
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </swiper-slide>
                </template>
            </swiper>
        </v-container>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        PageSection,
    },
    data: () => ({
        items: [
            {
                title: "양변기를 교체하다",
                text: "화장실을 개조할 때는 매우 조심스럽게 작업해야 합니다. 거치식 변기든 벽걸이 변기든 설치 시 안정성에 유의하여 사고를 예방하세요.",
                image: "/images/ideas/bathroom/renovation/toilet-1.webp",
                // button: "양변기 교체",
                // link: "/",
            },
            {
                title: "벽 변기 조립",
                text: "벽걸이형 변기는 우아하고 모던해 보이기 때문에 특히 인기가 높습니다. 또한, 바닥형 변기보다 변기 아래와 주변 바닥 청소가 더 쉽습니다.",
                image: "/images/ideas/bathroom/renovation/toilet-2.webp",
            },
            {
                title: "올바른 변기 높이 결정",
                text: "화장실의 크기에 대해서는 정해진 기준이 거의 없습니다. 특히 키에 관해서는 어느 정도 여유가 있습니다. 벽면 연결 높이에 따라 자신에게 맞는 높이에 변기를 설치할 수 있습니다.",
                image: "/images/ideas/bathroom/renovation/toilet-3.webp",
            },
        ],
        options: {
            effect: "fade",
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                },
            },
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
}
.section-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }
}
::v-deep(.swiper-container) {
    overflow: visible;
}
.swiper-controler {
    position: absolute;
    bottom: -56px;
    left: 0;
    .swiper-pagination {
        position: static;
        padding: 0 16px;
        ::v-deep(.swiper-pagination-bullet) {
            margin: 0 16px !important;
            background-color: initial !important;
            border: initial !important;
            width: auto !important;
            height: auto !important;
            font-weight: 500;
            color: var(--v-grey-darken3);
            border-radius: initial;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
            &-active {
                border-bottom: 1px solid var(--v-grey-darken3) !important;
            }
        }
    }
}
@media (min-width: 768px) {
    .container {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    .section-bg {
        width: calc(100% / 12 * 8);
        height: 100%;
    }
}
</style>