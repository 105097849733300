var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": `${_vm.path}/antao-bathtub-white-with-washbasin-and-taps.webp`,
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Bathroom trends for 2024"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실의 최신 트렌드는 무엇입니까?")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("2024년 욕실에는 기능성 외에도 모던한 디자인이 중요한 요소입니다. 현재 우리는 빠른 일상 위생을 위한 단순한 웨트룸에서 점점 더 집처럼 편안한 객실로 나아가고 있습니다. 이는 2024년 욕실에 대한 다음 10가지 새로운 트렌드에 반영됩니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('section-slide', {
    attrs: {
      "path": _vm.path,
      "items": _vm.slides
    }
  })], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "right"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/infinity-showers-shower-system-matt-black-with-squaro-infinity-shower-tray.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실 트렌드 6: 미니멀리즘과 명확한 라인")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("우아하고 깔끔하며 명확하게 구조화된 욕실 가구를 꾸밀 때 미니멀리즘과 명확한 선에 초점을 맞추고 또 다른 최신 욕실 트렌드를 따르면 이러한 효과를 얻을 수 있습니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('section-slide', {
    attrs: {
      "path": _vm.path,
      "items": _vm.slides2
    }
  })], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/antao-vanity-washbasin-morning-green-in-green-bathroom.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실 트렌드 10: 원형 거울과 간접 LED 조명")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 욕실에는 거울이 없어야 합니다. 정사각형 모델이 여전히 사용됩니다. 그러나 현대적이고 트렌디한 욕실에서는 거울이 점점 더 둥글게 변하고 있습니다. "), _c('br'), _vm._v(" 특히 거울 전체에 간접 LED 조명이 적용되어 더욱 돋보입니다. 이는 에너지를 절약하고 쾌적하고 분위기 있는 조명을 제공합니다. ")]), _c('v-btn', {
    staticClass: "rounded-xs ml-1 mt-18px mt-md-24px",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/"
    }
  }, [_vm._v(" 조명 거울 찾기 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }