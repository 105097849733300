<template>
    <page-section>
        <v-container>
            <v-sheet class="section-bg section-bg--secondary" />
            <v-row align="center" class="row--lg">
                <v-col cols="12" md="8">
                    <div class="full-image-wrap">
                        <v-img src="/images/ideas/bathroom/features/metalrim.webp" class="d-md-none" />
                    </div>
                </v-col>
                <v-col cols="12" md="4">
                    <div>
                        <h2 class="tit font-tertiary font-weight-regular mb-14px mb-md-16px">무테 샤워 바닥</h2>
                        <p class="txt txt--sm">Architectura MetalRim 컬렉션의 매우 평평한 샤워 바닥은 모든 욕실 디자인에 매끄럽고 조화롭게 어울립니다. 완벽한 욕실을 위해 다양한 치수 중에서 선택하세요.</p>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </page-section>
</template>

<script>
import pageSection from "@/components/client/templates/page-section.vue";
export default {
    components: { pageSection },
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
}
.section-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }

    &--secondary {
        left: initial;
        right: 0;
        &::before {
            left: initial;
            right: -100vw;
        }
    }
}

@media (min-width: 768px) {
    .section-bg {
        width: calc(100% / 12 * 8);
        height: 100%;
    }
    .container {
        padding-top: var(--grid-gutter-lg);
        padding-bottom: var(--grid-gutter-lg);
    }
    .full-image-wrap {
        height: calc(678px - var(--grid-gutter-lg) * 2);
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: calc(-1 * var(--grid-gutter-lg));
            right: 0;
            aspect-ratio: calc(1136 / 678);
            background-image: url(/images/ideas/bathroom/features/metalrim.webp);
            background-repeat: no-repeat;
            background-size: cover;
            height: calc(100% + var(--grid-gutter-lg) * 2);
        }
    }
}
</style>