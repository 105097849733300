<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/kitchen/cleaning/visual.webp" :breadcrumbs="breadcrumbs" title="Kitchen Cleaning" />

        <page-section class="grey lighten-5 py-40px py-md-80px my-40px my-md-80px">
            <v-container>
                <v-row justify="center" class="row--lg text-center">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">일반 관리 정보</div>
                        <p class="txt txt--sm">반짝반짝 빛나는 새 주방으로 집에 돌아오는 것을 누가 싫어하겠습니까? 가능한 한 오랫동안 모든 것을 새 것처럼 유지하는 데 많은 시간이 걸리지 않습니다. Villeroy & Boch의 주방 컬렉션은 특히 쉽게 만들어줍니다. 이 제품은 세척과 관리를 쉽고 많은 노력 없이 거의 지나가는 것처럼 수행할 수 있도록 개발되었습니다. 간단한 가정용 제품만으로도 충분합니다. 이는 환경에도 좋고 여러분에게도 확실히 좋습니다. 따라서 예를 들어 편안한 목욕으로 시간을 최대한 활용할 수 있습니다. 이 메뉴 항목에서 주방을 오랫동안 새 것처럼 유지하는 데 도움이 되는 많은 정보를 찾을 수 있습니다. 또한 직원들의 수년간의 컨설팅 경험에서 얻은 몇 가지 추가 팁과 연구의 새로운 결과도 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <cleaning-images />
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 py-40px py-md-80px my-20px my-md-40px">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">일상 관리</div>
                        <p class="txt txt--sm">
                            매일 관리 Villeroy & Boch 주방 세라믹은 모든 일반 가정용 산과 알칼리에 강한 매끄럽고 조밀하며 거의 긁히지 않는 표면을 가지고 있습니다. 매일 관리하려면 종종 깨끗한 물과 축축한 천만 있으면 됩니다. 물 자국, 기름 및 비누 잔여물은 욕조 또는 식초 세척제로 쉽게 제거할 수 있습니다. "화학 물질"을 전혀 피하고 싶다면 가정용 식초나 희석된 식초 에센스를 사용하세요. 이렇게 하면 석회질이 빠르게 제거됩니다. 얼룩이 조금 더 완고하다면 식초에 담근 가정용 천을 밤새도록 두어 제거할 수
                            있습니다. 전문 소매점에서 가벼운 석회질 제거제 또는 식초 세척제도 구입할 수 있으며 제조업체의 권장 사항에 따라 사용할 수 있습니다. 녹 제거제 및 치약에 들어 있는 것과 같이 불소 함량이 높은 모든 물질에는 주의가 필요합니다. 장시간 노출되면 얼룩이 생길 수 있습니다. 따라서 잔여물은 즉시 제거하세요! 더 심하게 더러워진 경우 액체 또는 크림 형태의 비연마성 욕실 세척제를 권장합니다. 부드럽고 긁히지 않는 스펀지를 사용하면 제품의 효과가 더욱 향상됩니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">중요사항</div>
                        <div class="txt txt--sm">
                            <p>제조업체의 사용 권장 사항을 주의 깊게 읽어야 합니다. 의심되는 경우 세척 및 관리 제품을 보이지 않는 부분에서 테스트할 수 있습니다. 세라믹 표면을 긁을 수 있고 Ceramicplus의 뛰어난 특성을 손상시키거나 지속적으로 사용하면 파괴할 수 있는 매우 공격적인 세척제는 반드시 피해야 합니다. 예:</p>

                            <ul class="caution-list">
                                <li>세척용 밀크 또는 세척용 파우더</li>
                                <li>고농축 배수구 세척제</li>
                                <li>밸브 및 스틸 클리너</li>
                                <li>분말형 WC 세척제</li>
                            </ul>

                            <p>이러한 제품의 사용을 전적으로 피하는 것이 좋으며, 하수구 세척제, 수도꼭지 세척제 또는 강철 세척제의 경우 Kermaik에 직접 닿지 않도록 주의하세요.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 py-40px py-md-80px">
            <v-container>
                <div class="tit-wrap tit-wrap--lg">
                    <div class="tit">개요</div>
                </div>

                <v-row class="row--lg">
                <template v-for="item in items">
                    <v-col cols="12" lg="4" :key="item">
                        <v-card tile flat color="transparent" :to="item.link">
                            <v-img :src="item.image" />
                            <div class="tit tit--xxs mt-12px mt-md-16px">
                                {{ item.title }}
                            </div>
                            <div class="mt-12px mt-md-16px d-flex align-center">
                                <span class="txt txt--xs txt--dark font-weight-medium">자세히 알아보기</span>
                                <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                            </div>
                        </v-card>
                    </v-col>
                </template>
            </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import CleanedKitchen from "@/components/client/ideas/kitchen/cleaning/cleaned-kitchen.vue";
import CleaningImages from "@/components/client/ideas/kitchen/cleaning/cleaning-images.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        CleanedKitchen,
        CleaningImages,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "주방 관리 팁",
                href: "/ideas/kitchen/cleaning",
            },
        ],
        items: [
            {
                title: "주방 컬렉션",
                image: "/images/ideas/kitchen/cleaning/cleaning-overview-1.webp",
                link: "/",
            },
            {
                title: "주방 수도꼭지 및 부속품",
                image: "/images/ideas/kitchen/cleaning/cleaning-overview-2.webp",
                link: "/",
            },
            {
                title: "주방의 종류",
                image: "/images/ideas/kitchen/cleaning/cleaning-overview-3.webp",
                link: "/",
            },
        ]
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.caution-list {
    margin: 40px 0;
    > li {
        padding-left: 24px;
        position: relative;
        margin-bottom: 12px;
        &::before {
            content: "";
            position: absolute;
            left: 10px;
            top: 8px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--v-grey-base);
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
@media (min-width: 768px) {
    .caution-list {
        margin: 80px 0;
        > li {
            padding-left: 32px;
            margin-bottom: 16px;
            &::before {
                width: 6px;
                height: 6px;
                left: 6px;
                top: 9px;
            }
        }
    }
}
</style>