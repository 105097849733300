<template>
    <client-page>
        <shop-product-visual wide :image="`${path}/visual.webp`" :breadcrumbs="breadcrumbs" title="Scandinavian-style bathroom" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">Hygge 다음에는 Scandi가 옵니다! 스칸디나비아 스타일로 욕실을 꾸미는 방법을 알려드리겠습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <template v-for="(item, index) in navigation">
                                <v-col cols="12" :key="index">
                                    <router-link :to="`#${index + 1}`" class="txt txt--sm d-flex align-center">
                                        <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                        {{ item }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <dot-list>
                                <template v-for="item in overview">
                                    <li :key="item">
                                        {{ item }}
                                    </li>
                                </template>
                            </dot-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="right">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/avento-washbasin-vanity-unit-with-washbasin-in-wooden-bathroom.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">욕실을 위한 스칸디나비아 스타일: 미학과 기능성</div>
                        <p class="txt txt--sm">욕실의 스칸디나비아 스타일은 기본적으로 자연성, 지속 가능성, 기능성 및 미니멀리즘이라는 네 가지 측면에서 비롯됩니다. 따라서 방을 장식으로 가득 채우지 않고 필수품에 집중하는 것이 중요합니다. 동시에 가구와 시스템은 심미적일 수 있으며 집과 같은 편안한 분위기를 조성하는 데 기여할 수 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center" align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">스칸디 욕실의 단순함 – 소재 및 구조</div>
                        <p class="txt txt--sm">스칸디나비아 스타일로 살고 있거나 욕실을 스칸디나비아 스타일로 꾸미고 싶다면 천연 소재를 무시할 수 없습니다. 물론 여기서 가장 눈에 띄는 대표자는 우드이다. 욕실의 스칸디나비아 타일은 일반적으로 가볍고 질감이 있는 표면을 가지고 있습니다. 슬레이트 타일도 인기가 있습니다. 양모 카펫은 스칸디나비아 스타일을 완성합니다.</p>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-img :src="`${path}/loop-and-friends-japandi-style-bathroom.webp`" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="right">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/antao-white-washbasin-wooden-furniture.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">미니멀리스트 Scandi 디자인 - 완벽한 조명 조건</div>
                        <p class="txt txt--sm">빛은 스칸디 스타일의 가장 중요한 측면 중 하나입니다. 이상적으로는 일광 욕실이 있습니다. 그러나 조명 거울은 Scandi 욕실에 완벽한 추가 요소이기도 합니다. 이러한 인공 광원은 따뜻한 빛을 방출하고 실내를 균일하게 조명합니다. 색상을 선택할 때 스칸디나비아 사람들은 빛을 반사하고 공간감과 차분한 느낌을 주는 흰색, 베이지, 크림, 밝은 회색과 같은 밝은 톤을 사용합니다.</p>
                        <v-btn large color="white" class="rounded-xs mt-18px mt-md-24px" to="/ideas/bathroom/overview/lighting">
                            <span class="txt txt--xs font-weight-medium">욕실의 조명 조건에 대한 추가 정보</span>
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--lg text-center">
                    <div class="tit">Scandi 욕실에 딱 맞는 제품!</div>
                </div>
                <v-row class="row--lg">
                    <template v-for="item in items">
                        <v-col :key="item.title" cols="12" md="4">
                            <v-card tile flat :to="item.link">
                                <v-img eager :src="`${path}/${item.image}`" class="mb-12px mb-md-16px" />
                                <div class="tit tit--xs mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <div class="txt tit--xs mb-12px mb-md-16px">
                                    {{ item.text }}
                                </div>
                                <div class="d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">
                                        {{ item.button }}
                                    </span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import SectionSlide from "@/components/client/dumb/section-slide.vue";
import DotList from "@/components/client/ideas/dot-list.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        SectionSlide,
        DotList,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 아이디어 및 트렌드",
                href: "/ideas/bathroom/trends",
            },
        ],
        path: "/images/ideas/bathroom/trends/styles/scandi",
        navigation: [
            "스칸디 욕실 특징",
            "Scandi 욕실의 재료 및 구조",
            "적절한 조명 조건",
            "귀하의 Scandi 욕실에 적합한 제품",
            //
        ],
        overview: [
            "자연스러운 편안함 - 이것이 바로 스칸디 스타일을 간략하게 설명하는 방법입니다.",
            "목재 및 기타 천연 재료가 가장 중요하고 최종적입니다.",
            "직선 형태와 눈에 띄지 않는 액세서리는 스칸디나비아 스타일의 특징입니다.",
            //
        ],
        items: [
            {
                title: "적합한 Scandi 욕조 찾기",
                text: "Villeroy & Boch에서는 Scandi 욕실에 우아하게 설치할 수 있는 욕조를 만나보실 수 있습니다.",
                image: "theano-bathtub-in-modern-bathroom.webp",
                button: "빌레로이앤보흐 욕조",
                link: "/",
            },
            {
                title: "욕실 장식 - 스칸디나비아 스타일",
                text: "Scandi 욕실에서는 액세서리가 공간을 거의 차지하지 않습니다. 예를 들어 키가 큰 꽃병에 말린 꽃과 같이 스칸디나비아 장식을 구체적으로 배치하고 색상 구성에 주의를 기울이세요.",
                image: "antao-vanity-washbasin-white-on-vanity-unit-with-mirror-and-tap.webp",
                button: "스칸디나비아 스타일 장식",
                link: "/",
            },
            {
                title: "스칸디나비아 욕실 가구",
                text: "스칸디나비아 욕실 가구는 단순하고 넓습니다. 나무 모양과 직선 형태의 세면대는 스칸디나비아 욕실에 완벽하게 들어맞습니다.",
                image: "subway-30-washbasin-boho-lifestyle.webp",
                button: "올바른 욕실 가구",
                link: "/",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>