var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/bathroom/material/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Bathroom materials"
    }
  }), _c('page-section', {
    staticClass: "py-40px py-md-80px"
  }, [_c('v-container', {
    staticClass: "mt-50px mt-md-100px"
  }, [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#1"
    }
  }, [_c('v-img', {
    staticClass: "mr-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  }), _vm._v(" 욕실을 위한 최고의 재료 ")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#3"
    }
  }, [_c('v-img', {
    staticClass: "mr-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  }), _vm._v(" 욕실 디자인이 새롭게 바뀌었습니다. ")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#3"
    }
  }, [_c('v-img', {
    staticClass: "mr-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  }), _vm._v(" 자주 묻는 질문 ")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#4"
    }
  }, [_c('v-img', {
    staticClass: "mr-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  }), _vm._v(" 욕실을 위한 영감 ")], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")])], 1), _c('v-col', [_vm._v(" 욕실을 새롭게 디자인하고 싶은데 어떤 욕실 소재가 가장 좋은지 궁금하십니까? 도자기, 유리, 목재 등 욕실 소재의 차별점과 어디에 사용되는지 알려드리겠습니다. ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "1"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실을 위한 최고의 재료")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("올바른 재료를 사용하면 욕실이 웰빙의 오아시스가 될 것입니다. 타일은 진정한 고전입니다. Villeroy & Boch의 벽 및 바닥 타일은 청소가 쉽고 다양한 형식, 디자인, 색상으로 제공됩니다. 타일을 바닥재로 사용하려면 견고하고 미끄러지지 않는 타일을 선택해야 합니다. 대형 욕실 타일은 표면을 균일하게 만들고 작은 욕실을 더 크게 보이게 합니다. 욕실 벽에는 고품질 석기 벽 타일을 권장합니다.")])])])], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "image-wrap"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/material/antao-bathtub-white-with-washbasin-and-taps.webp",
      "max-width": "672"
    }
  }), _c('v-img', {
    staticClass: "positioned-image positioned-image--1",
    attrs: {
      "src": "/images/ideas/bathroom/material/artis-washbasin-frozen-with-finion-furniture-and-toilet.webp"
    }
  }), _c('v-img', {
    staticClass: "positioned-image positioned-image--2",
    attrs: {
      "src": "/images/ideas/bathroom/material/squaro-infinity-shower-tray-in-grey-bathroom-with-chair.webp"
    }
  })], 1)])], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm mt-md-16px"
  }, [_vm._v("유리와 목재 소재는 욕실에서도 찾아볼 수 있습니다. 유리 샤워 부스는 현대적이고 우아합니다. 고품질 목재 패널과 목재 가구가 욕실에 따뜻함을 선사합니다. 욕실을 개조하고 싶고 적합한 재료를 찾고 있다면 타일 대신 특수 석고를 사용할 수도 있습니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "pa-24px pa-md-40px",
    attrs: {
      "tile": "",
      "elevation": "2",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "mb-12px mb-md-16px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-avatar', {
    staticClass: "border border-dark",
    attrs: {
      "size": "20"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "#000"
    }
  }, [_vm._v("mdi-information-symbol")])], 1)], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--dark font-weight-medium"
  }, [_vm._v("팁")])])], 1)], 1), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("예를 들어, 욕실 타일의 자연스러운 대안은 발수성, 항진균성 특성을 지닌 Tadelakt 석회 석고로 샤워 뒷벽으로도 적합합니다.")])])], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "2"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실 디자인이 새롭게 바뀌었습니다.")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실은 나만의 웰빙 오아시스입니다. 따라서 욕실 소재를 신중하게 선택하는 것이 더욱 중요합니다. 위생용품의 디자인과 느낌이 중요한 역할을 합니다. 그러나 천장, 벽, 욕실 바닥의 디자인도 중요합니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', {
    staticClass: "position-relative pb-12px py-md-72px"
  }, [_c('v-sheet', {
    staticClass: "section-bg"
  }), _c('swiper', _vm._b({}, 'swiper', {
    options: _vm.options
  }, false), [_vm._l(_vm.items, function (item) {
    return [_c('swiper-slide', {
      key: item.title
    }, [_c('v-row', {
      staticClass: "row--lg",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "5",
        "order": "2",
        "order-md": "1"
      }
    }, [_c('v-card', {
      staticClass: "h-md-400px d-md-flex align-center",
      attrs: {
        "color": "grey lighten-5"
      }
    }, [_c('div', [_c('div', {
      staticClass: "tit mb-12px mb-md-16px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "txt txt--sm txt--dark font-weight-medium"
    }, [_vm._v(" " + _vm._s(item.subtitle) + " ")]), _c('p', {
      staticClass: "txt txt--sm mb-18px mb-md-24px"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])])])], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "7",
        "order": "1",
        "order-md": "2"
      }
    }, [_c('v-card', {
      staticClass: "position-relative mb-50px mb-md-0",
      attrs: {
        "tile": "",
        "img": item.image
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 674 / 504
      }
    }), _c('div', {
      staticClass: "swiper-controler"
    }, [_c('v-row', {
      staticClass: "row--xs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "swiper-button swiper-button--prev",
      attrs: {
        "x-small": "",
        "icon": "",
        "outlined": ""
      }
    }, [_c('v-img', {
      attrs: {
        "max-width": "32",
        "src": "/images/icon/icon-swiper-left.svg"
      }
    })], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "swiper-pagination"
    })]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "swiper-button swiper-button--next",
      attrs: {
        "x-small": "",
        "icon": "",
        "outlined": ""
      }
    }, [_c('v-img', {
      attrs: {
        "max-width": "32",
        "src": "/images/icon/icon-swiper-right.svg"
      }
    })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('material-idea'), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("빌레로이앤보흐의 혁신")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("빌레로이앤보흐는 항상 품질과 혁신을 대표해 왔습니다. AntiBac(항균 표면) 또는 Quaryl 기술과 같은 혁신과 기술은 회사의 창의성에서 비롯되었으며 욕실의 더욱 편안함과 지속 가능성을 보장합니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "color": "transparent",
      "max-width": "940"
    }
  }, [_c('v-row', {
    staticClass: "row--xxl",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "776",
      "color": "transparent",
      "tile": "",
      "flat": ""
    }
  }, [_c('v-img', {
    staticClass: "mb-12px mb-md-16px",
    attrs: {
      "src": "/images/ideas/bathroom/material/antao-bathtub-white-with-bath-tap-in-beige-bathroom.webp"
    }
  }), _c('div', {
    staticClass: "px-12px px-md-16px"
  }, [_c('div', {
    staticClass: "tit tit--xs grey--text text--darken-3 mb-6px mb-md-8px"
  }, [_vm._v("욕실을 위한 Quaryl 기술")]), _c('p', {
    staticClass: "txt txt--sm grey--text text--darken-3"
  }, [_vm._v("욕조나 샤워 트레이에 이상적인 재료가 무엇인지 궁금하십니까? 우리는 Quaryl 또는 아크릴이라고 말합니다. 다용도 위생 아크릴은 대부분의 욕조에 사용됩니다. 아크릴 수지와 천연 석영석으로 구성된 최고급 쿼릴로 만든 욕조도 제공합니다. 그 결과, 잘 디자인되고 안정적인 위생 제품이 탄생했습니다.")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "776",
      "color": "transparent",
      "tile": "",
      "flat": ""
    }
  }, [_c('v-img', {
    staticClass: "mb-12px mb-md-16px",
    attrs: {
      "src": "/images/ideas/bathroom/material/architectura-toilet-white-in-scandic-bathroom.webp"
    }
  }), _c('div', {
    staticClass: "px-12px px-md-16px"
  }, [_c('div', {
    staticClass: "tit tit--xs grey--text text--darken-3 mb-6px mb-md-8px"
  }, [_vm._v("AntiBac: 항균 표면")]), _c('p', {
    staticClass: "txt txt--sm grey--text text--darken-3"
  }, [_vm._v("AntiBac은 지속적인 항균 효과를 지닌 혁신적인 표면 기술입니다. 독립 기관에서 장기적인 보호를 확인했습니다. 유약에 포함된 은 이온 덕분에 AntiBac은 박테리아의 성장을 99.9% 이상 감소시킵니다. 테두리가 없는 DirectFlush 양변기 및 CeramicPlus 표면과 함께 Villeroy & Boch 기술은 최고 수준의 청결과 위생을 제공합니다.")])])], 1)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px my-20px my-md-40px",
    attrs: {
      "id": "3"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("자주 묻는 질문")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("아래에서 욕실 자재에 관해 자주 묻는 질문에 대한 답변을 찾아보세요.")]), _c('v-expansion-panels', {
    attrs: {
      "tile": ""
    }
  }, [_vm._l(_vm.qna, function (item) {
    return [_c('v-expansion-panel', {
      key: item.header
    }, [_c('v-expansion-panel-header', {
      staticClass: "txt txt--lg txt--dark"
    }, [_vm._v(" " + _vm._s(item.header) + " ")]), _c('v-expansion-panel-content', {
      staticClass: "txt txt--sm txt--dark"
    }, [_vm._v(" " + _vm._s(item.content) + " ")])], 1)];
  })], 2)], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "4"
    }
  }, [_c('v-container', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실을 위한 영감")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실을 아름답고 아늑하게 만드는 것은 무엇일까요? 우리의 디자인 아이디어가 당신에게 영감을 주도록 하세요!")])])], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.inspirations, function (item) {
    return [_c('v-col', {
      key: item.title,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.image,
        "aspect-ratio": 1 / 1
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('v-sheet', {
      staticClass: "d-flex align-center pa-20px",
      attrs: {
        "color": "grey lighten-5",
        "height": "100%"
      }
    }, [_c('div', [_c('div', {
      staticClass: "tit tit--sm mb-10px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])])])], 1)], 1)], 1)];
  })], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }