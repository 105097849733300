<template>
    <v-sheet color="transparent" max-width="940" class="mx-auto">
        <v-row justify="center" align="center" class="row--xxl">
            <template v-for="item in items">
                <v-col cols="12" :md="item.cols" :key="item">
                    <v-card max-width="776" color="transparent" tile flat :to="item.link" class="mx-auto">
                        <v-img :src="`${path}/${item.image}`" class="mb-12px mb-md-16px" />
                        <div class="px-12px px-md-16px">
                            <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                {{ item.title }}
                            </div>
                            <p class="txt txt--sm grey--text text--darken-3">
                                {{ item.text }}
                            </p>
                            <template v-if="item.button">
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">
                                        {{ item.button }}
                                    </span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </template>
                            <template v-else>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">모든 {{ item.title }} 보러 가기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </template>
                        </div>
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    props: {
        path: { type: "String" },
        items: { type: "String" },
    },
};
</script>