var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/bathroom/wellness/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Wellness bathroom"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실에 매력적인 웰니스 오아시스가 있어 언제든지 자신만의 공간에서 일상 생활에서 벗어나 편안한 휴식을 취할 수 있습니다. 여기서는 집에 개별 웰니스 욕실을 설치하는 방법을 읽어볼 수 있습니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey white"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_vm._l(_vm.navigation, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('router-link', {
      staticClass: "txt txt--sm d-flex align-center",
      attrs: {
        "to": `#${index + 1}`
      }
    }, [_c('v-img', {
      staticClass: "mr-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-down.svg"
      }
    }), _vm._v(" " + _vm._s(item) + " ")], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('dot-list', [_vm._l(_vm.overview, function (item) {
    return [_c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "1"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("개인별 웰빙 오아시스 만들기")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 욕실은 종종 단순한 습식 방과 유사한 기능적인 공간으로 간주되었습니다. 오늘날 현대적인 욕실은 작은 웰니스 공간으로 기능해야 하며 최고의 디자인 표준을 충족해야 합니다. 웰빙의 오아시스 중심에는 넓은 바닥에 샤워실과 독립형 욕조가 있어 욕실을 개인 휴식 공간으로 바꿔줍니다. "), _c('br'), _vm._v(" 욕실에서의 웰빙은 개인 관리 그 이상을 의미합니다. 여기에는 기분이 좋고, 휴식을 취하고, 긴장을 풀 수 있는 공간이 있습니다. 이를 달성하려면 리모델링 시 욕실을 시각적으로 차분한 공간으로 만들어야 합니다. 개방된 공간이 많고 위생용품과 가구를 그룹별로 배치하여 균형 잡힌 분위기를 조성할 수 있습니다. ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "pa-24px pa-md-40px",
    attrs: {
      "color": "grey lighten-5",
      "tile": "",
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "mb-12px mb-md-16px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', [_vm._v("mdi-information-outline")])], 1), _c('v-col', {
    staticClass: "font-weight-medium font-size-18"
  }, [_vm._v("팁")])], 1)], 1), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실을 디자인할 때 전문가와 함께 구조적 요구 사항을 명확히 하고 평면도를 작성하고 비용을 계산하십시오. 그런 다음에야 계획할 때 옵션으로 제공되는 월풀 욕조나 마사지 제트와 같은 기능을 디자인 아이디어로 고려해야 합니다.")])])], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('section-slide', {
    attrs: {
      "path": _vm.path,
      "items": _vm.slides
    }
  })], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey mb-0"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm mb-12px mb-md-16px"
  }, [_vm._v("웰빙 목욕에는 무엇이 필요합니까?")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("웰니스 욕실은 최고급 소재와 적절한 색상, 액세서리를 포함한 고품질 가구로 이루어진 조화로운 전체 구조가 인상적입니다. 분위기 있는 조명, 부드러운 음악 소리, 그리고 객실 향과 에센셜 오일과 같은 기타 \"기분 좋은 제품\"이 개인 웰니스 욕실을 완성합니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey pt-0 mt-0"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm mb-12px mb-md-16px"
  }, [_vm._v("욕실의 쾌적한 조명 디자인")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 욕실의 분위기 있는 조명 디자인은 아늑함을 더해주고 욕실에 다양한 요소를 더해줍니다. 낮에는 큰 창문을 통해 빛이 들어오지만, 저녁에는 천장의 작은 LED 조명이 변화하는 조명 시나리오로 인상적입니다. 이 틈새 조명 외에도 실제 양초나 LED 양초를 사용하여 욕실에 편안함을 더할 수 있습니다. 대규모 조명 대신 여러 개의 작은 광원을 사용하면 저녁 목욕 경험을 더욱 즐겁게 만들 수 있습니다. 세련되고 특이한 조명 효과도 웰빙 욕실을 더욱 돋보이게 합니다. 예를 들어, 방의 개별 영역을 예술적으로 강조하는 웅장한 샹들리에나 은은한 캐비닛 조명이 있습니다. ")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('three-images', {
    attrs: {
      "path": _vm.path,
      "first": "finion-bath-tub-and-furniture-in-blue-bathroom.webp",
      "second": "finion-bathroom-mirror-with-lighting-with-washbasin-and-vanity-unit.webp",
      "third": "artis-washbasin-white-with-conum-tap-and-finion-bath.webp"
    }
  })], 1)], 1), _c('page-section', [_c('v-row', {
    staticClass: "row--lg grey lighten-5 my-md-0",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-md-0",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-container', {
    staticClass: "mx-md-0 px-md-0"
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/finion-bathroom-furniture-blue.webp`
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "py-md-0",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "tit tit--sm mb-12px mb-md-16px"
  }, [_vm._v("당신의 색깔을 보여주세요 – 정신에 미치는 영향")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 색상은 정신에 과소평가되어서는 안되는 영향을 미치기 때문에 욕실을 꾸밀 때 조화로운 색상 개념을 고려해야 합니다. 색상 심리학에 따르면, 차분한 효과가 있어 휴식에 도움이 되는 것은 주로 파란색, 녹색, 파스텔 톤과 같은 차가운 색상입니다. 화이트, 크림, 그레이 등의 뉴트럴 톤도 차분하고 조화로운 전체적인 이미지를 만들어줍니다. "), _c('br'), _vm._v(" 밝은 색상은 공간을 시각적으로 확대하므로 작은 욕실에 특히 적합합니다. 넓은 웰니스 욕실이 있다면 강한 톤을 적절히 활용하면 된다. 그러나 귀하의 웰빙 오아시스가 너무 과부하된 것처럼 보이지 않도록 대비되는 방법으로만 사용하십시오. ")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card', {
    staticClass: "pa-24px pa-md-40px",
    attrs: {
      "tile": "",
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "mb-12px mb-md-16px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', [_vm._v("mdi-information-outline")])], 1), _c('v-col', {
    staticClass: "font-weight-medium font-size-18"
  }, [_vm._v("팁")])], 1)], 1), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("예를 들어 벽 색상을 욕실 액세서리나 욕실 텍스타일에 맞추는 등 원하는 색상을 가구 아이디어에 적용할 수 있습니다.")])])], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm mb-12px mb-md-16px"
  }, [_vm._v("레인 샤워부터 월풀까지")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("많은 현대적인 욕실의 중심에는 독립형 웰빙 욕조가 있습니다. 개인 웰니스 오아시스의 하이라이트로 일상 생활에서 벗어나 휴식을 취할 수 있는 공간으로 여러분을 초대합니다. 빌레로이 앤 보흐(Villeroy & Boch) 컬렉션을 사용하면 디자이너 욕조를 집에 들여와 웰빙 공간의 시선을 사로잡는 럭셔리한 공간으로 만들 수 있습니다. Aveo New Generation 스타일의 타원형, 곡선형 또는 타원형 Loop & Friends 변형: 당사의 독립형 욕조는 최고 수준의 목욕 문화를 제공합니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('idea-product-list', {
    attrs: {
      "path": _vm.path,
      "items": _vm.products
    }
  })], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("추운 계절이나 그 사이에는 월풀 기능이 욕조의 편안함을 높여줍니다. 작은 어메니티로서 풀옵션 수영장 못지않은 쾌적한 휴식을 약속드립니다. Villeroy & Boch에서는 Architectura, Oberon 2.0, Squaro Edge 등 우아한 욕조와 결합할 수 있는 다양한 월풀 시스템을 만나보실 수 있습니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "pa-24px pa-md-40px",
    attrs: {
      "color": "grey lighten-5",
      "tile": "",
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "mb-12px mb-md-16px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', [_vm._v("mdi-information-outline")])], 1), _c('v-col', {
    staticClass: "font-weight-medium font-size-18"
  }, [_vm._v("팁")])], 1)], 1), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("아침이나 퇴근 후 장시간 목욕할 시간이 없으신가요? 그렇다면 다양한 제트 유형으로 완벽한 샤워 경험을 선사하는 고품질 샤워기와 레인 샤워 헤드로 감각을 만족시켜 보세요.")])])], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("집에서의 웰빙과 휴식")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("집에서 완벽한 스파 느낌을 원하신다면 향, 색상, 소리가 조화롭게 어우러진 편안한 스파를 경험해 보세요. 힘든 하루를 보낸 후에도 아로마, 색색의 조명, 좋아하는 음악으로 몸과 마음을 가꾸고 모든 감각을 편안하게 해줄 수 있습니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "order-md": "2"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/infinity-showers-shower-tap-witch-black-walls.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm mb-12px mb-md-16px"
  }, [_vm._v("온몸으로 여유로움을 느껴보세요")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 가끔씩 따뜻한 샤워뿐만 아니라 찬물 샤워나 콘트라스트 샤워를 하여 온몸의 이완을 느껴보세요. 자주 피곤하고 지친다면, 예를 들어 차가운 얼굴 샤워나 차가운 팔 목욕 등이 혈액 순환을 자극할 수 있습니다. 다리 근육을 풀어주는 차가운 허벅지와 무릎 스프레이는 다리 부종과 고혈압을 예방하는 데 도움이 됩니다. 춥고 습한 날씨에는 온도가 38도까지 천천히 올라가는 족욕을 추천합니다. Villeroy & Boch에서는 이러한 용도에 도움이 되는 모든 액세서리를 구입할 수 있습니다. 예를 들어 샤워 시 최대 3가지 제트 유형을 사용하여 타겟 마사지를 가능하게 하는 핸드 샤워 등이 있습니다. "), _c('br'), _vm._v(" Tip. 단파장 적외선은 몸에 열을 발생시켜 통증완화 효과가 있어 더욱 편안한 휴식을 약속드립니다. 전문가가 관련 모듈을 샤워실에 직접 설치할 수 있으므로 이를 위해 추가 객실이 필요하지 않습니다. ")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm mb-12px mb-md-16px"
  }, [_vm._v("맛, 음악, 따뜻한 차")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("감각을 자극하는 실내 향기, 편안한 음악, 따뜻한 차와 함께 나만의 스파 경험을 완성해보세요. 라벤더, 바닐라, 레몬밤과 같은 향은 차분한 웰니스 경험을 선사합니다. 목욕물에 에센셜 오일을 한 방울씩 첨가하면 향이 코를 통해 몸 전체에 스며드는 것을 느껴보세요. 욕실에서 자연스러운 향기를 선호한다면 빌레로이 앤 보흐 꽃병에 좋아하는 꽃다발을 담아 후각을 자극해보세요.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/woman-with-drink-in-bathtub.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("웰빙 경험을 위해 눈을 감고 편안한 음악에 빠져보세요. 욕조 목욕을 보완하기 위해 차분한 효과가 있는 부드러운 악기 음악이나 자연의 소리를 추천합니다. 약간의 간식으로 웰니스 경험을 마무리하세요. 발레리안 또는 카모마일 차 한 잔은 마음을 따뜻하게 해주고 휴식을 더해줍니다. 우아한 도자기 컵으로 차를 즐겨보세요. \"Amazonia Anmut\" 또는 \"Rose Garden\"과 같은 컬렉션을 사용하면 차 맛이 두 배 더 좋아집니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm- mb-12px mb-md-16px"
  }, [_vm._v("욕실에 기분 좋은 포인트를 설정하세요")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("매일 즐길 수 있는 욕실에 기분 좋은 액센트를 만들어 보세요. 특이한 개별 요소로 웰빙 욕실을 강화하여 웰빙을 높일 수도 있습니다.")]), _c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }), _c('dot-list', [_vm._l(_vm.points, function (item) {
    return [_c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }