var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    attrs: {
      "id": "4"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "product-section-title tit-wrap tit-wrap--lg"
  }, [_c('span', {
    staticClass: "tit font-weight-regular font-tertiary white"
  }, [_vm._v("당신의 집을 위한 더 많은 아이디어")])]), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("욕실 매니아를 위한")])]), _c('v-row', [_vm._l(_vm.items, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "to": item.link
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "tit tit--sm mt-20px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm mt-8px"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), item.link ? _c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v("자세히 알아보기")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1) : _vm._e()], 1)], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }