<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/storage/visual.webp" :breadcrumbs="breadcrumbs" title="Bathroom storage ideas" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">깔끔한 욕실에 대한 영감</div>
                        <p class="txt txt--sm">욕실에는 수건, 청소 용품, 화장품 및 모든 종류의 작은 물건을 보관할 수 있는 공간이 있어야 합니다. 이렇게 하면 사용 가능한 공간을 스토리지에 효과적으로 사용할 수 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <template v-for="(item, index) in navigation">
                                <v-col cols="12" :key="index">
                                    <router-link :to="`#${index + 1}`" class="txt txt--sm d-flex align-center">
                                        <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                        {{ item }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <dot-list>
                                <template v-for="item in overview">
                                    <li :key="item">
                                        {{ item }}
                                    </li>
                                </template>
                            </dot-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="1">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">장식 및 보관 틈새를 사용하십시오.</div>
                        <p class="txt txt--sm">욕실을 계획할 때 수납 공간이 숨겨져 있는 벽감과 모서리가 거의 자동으로 생성됩니다. 혁신적이고 실용적인 수납 솔루션을 제공하여 적은 자원으로 사용되지 않는 공간을 재설계할 수 있습니다. 매립형 선반, 맞춤형 캐비닛 및 다기능 요소는 모든 욕실 필수품을 위한 공간을 만들어줍니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <section-slide :items="slide1" :path="path" />
            </v-container>
        </page-section>

        <page-section id="2">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">추가 수납 공간을 위한 욕실 가구</div>
                        <p class="txt txt--sm">
                            크거나 작은 욕실을 재설계하는 경우 가구를 선택할 때 다기능성을 강조해야 합니다. 수납공간과 디자인을 겸비한 고품질의 실용적인 가구입니다. 물론, 선택은 욕실이 제공하는 공간의 크기에 따라 달라집니다. 유용한 팁 은 욕실에 필요한 수납 공간을 어떻게 영리하게 통합할 수 있는지 확인할 수 있도록 미리 평면도를 작성하는 것입니다. <br />
                            건식 벽체와 공동은 저장 공간을 만들기 위한 추가 옵션을 제공합니다. 초기에 계획을 세우면 나중에 찬장 등이 눈에 띄지 않도록 수납 옵션을 디자인에 영리하게 통합할 수 있습니다. 이는 욕실에서 특히 우아해 보이며 욕실이 집의 웰빙 오아시스로 남아 있도록 보장합니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <section-slide :items="slide2" :path="path" />
            </v-container>
        </page-section>

        <page-section id="3">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">바구니와 선반이 있는 수납 공간</div>
                        <p class="txt txt--sm">바구니, 상자 등은 미니멀한 욕실뿐만 아니라 넉넉한 수납 공간을 제공합니다. 세련된 분위기를 연출하는 실용적인 수납 솔루션은 크고 작은 욕실에 적합합니다. 모든 욕실용품을 보관할 수 있는 웰빙의 오아시스를 만들어보세요.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col md="1" class="d-none d-md-block" />
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">세탁물과 수건 바구니</div>
                        <p class="txt txt--sm">
                            오픈 선반, 바닥의 여유 공간, 벽의 후크를 사용하면 크고 작은 바구니를 놓을 수 있습니다. 금속, 나무, 라피아 소재로 만든 큰 바구니에는 수건을 보관할 수 있고, 작은 바구니에는 헤어 액세서리를 정리하는 데 적합합니다. 세탁 바구니도 욕실의 필수품입니다. 욕실의 나머지 부분과 재료를 일치시켜 원하는 모양으로 맞춤화하세요. 예를 들어, 작은 도자기 그릇을 사용하여 작은 물건이나 화장품을 정리할 수도 있습니다. <br />
                            바구니는 공간이 넉넉할 뿐만 아니라 선반과 칸막이를 정리하는 데에도 도움이 됩니다. 몇 가지 간단한 단계만 거치면 욕실이 훨씬 더 깔끔해 보일 수 있습니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/colorado-bathtub-in-beige-bathroom.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">수건사다리가 유행이네요</div>
                        <p class="txt txt--sm">클래식 수건 걸이 또는 라디에이터는 모든 크기의 수건을 보관할 수 있는 공간을 제공합니다. 후자는 또한 욕실을 가열합니다. 수건 사다리는 스타일이 좀 더 미니멀합니다. 벽에 비스듬히 세워져 있으며 영구적으로 설치된 수건 라디에이터보다 더 좁고 유연합니다. 사다리의 계단이 조금 더 넓다면 바구니, 작은 도구, 장식 요소를 거기에 놓을 수도 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7" order-md="2">
                        <v-img :src="`${path}/finion-hanging-rail-white.webp`" />
                    </v-col>
                    <v-col cols="12" md="5" order-md="1">
                        <div class="tit mb-12px mb-md-16px">월박스의 인기가 날로 높아지고 있습니다.</div>
                        <p class="txt txt--sm">작은 욕실을 계획할 때 자동으로 수직적으로 생각하게 됩니다. 월박스는 다양한 높이에 유연하게 부착할 수 있고 다양한 물건으로 채울 수 있습니다. Villeroy & Boch Finion 컬렉션은 조명도 갖춘 실용적인 벽 상자를 제공합니다. 이것은 욕실에서 수납 도구를 눈길을 사로잡는 요소로 만듭니다. 벽에 수납할 수 있는 공간만 있으면 어디에서나 월박스를 사용할 수 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8" class="text-center">
                        <div class="tit mb-12px mb-md-16px">자신의 욕실에 더 많은 공간</div>
                        <p class="txt txt--sm">욕실은 놀라운 수납 공간을 제공할 수 있습니다. 벽감, 선반, 다기능 벤치 및 붙박이 찬장을 사용하면 시각적 손실 없이 모든 욕실 용품을 능숙하게 분류하고 정리할 수 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";

import SectionSlide from "@/components/client/dumb/section-slide.vue";
import ExpansionPanels from "@/components/client/ideas/expansion-panels.vue";
import DotList from "@/components/client/ideas/dot-list.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,

        SectionSlide,
        ExpansionPanels,
        DotList,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 개요",
                href: "/ideas/bathroom/overview",
            },
        ],
        path: "/images/ideas/bathroom/storage",
        navigation: [
            "장식 및 수납 공간 활용",
            "추가 수납 공간을 위한 욕실 가구",
            "바구니와 선반이 있는 수납 공간",
            //
        ],
        overview: [
            "선반이 있는 기존 벽감을 수납 공간으로 활용",
            "거울 캐비닛과 화장대가 있어 질서를 보장합니다.",
            "보관을 위해 특별히 개방형 선반을 사용하세요.",
            "월 박스는 벽 공간을 능숙하게 활용합니다.",
            "디자인과 실제 요구 사항이 밀접하게 연관되어 있습니다.",
            //
        ],
        slide1: [
            {
                title: "선반을 효과적으로 활용하세요",
                text: "숨겨진 미장 작업으로 인해 모든 욕실에는 이중의 빈 벽이 만들어집니다. 특정 높이에서는 수건, 샤워 젤, 목욕 거품 및 기타 도구를 보관할 수 있는 작은 틈새를 위한 충분한 공간을 제공합니다. 선반을 설치하여 틈새를 최대한 활용하십시오. 대부분의 경우 접이식 문을 설치하여 보관 공간뿐 아니라 작은 병이나 캔을 보관할 수 있는 공간도 만들 수 있습니다. 장식 요소도 이러한 공간을 찾아 욕실 분위기를 밝게 해줍니다. 방의 스타일을 살펴보세요. LED 램프를 사용하면 틈새에 타겟 조명을 만들어 능숙하게 전시할 수 있습니다.",
                image: "subway30-bathroom-furniture-in-light-wooden-bathroom.webp",
            },
            {
                title: "붙박이장이 설치되어 수납공간이 넉넉합니다",
                text: "작은 욕실에는 수납 공간이 거의 없는 경우가 많습니다. 경사진 천장 아래나 통로의 사용하지 않는 공간은 붙박이 찬장을 사용하여 유용한 수납 공간으로 탈바꿈할 수 있습니다. 개인의 필요에 맞게 붙박이 찬장을 맞춤화하고 적절한 수준의 정리를 제공하는 칸막이, 도어, 서랍을 만들 수 있습니다.\r\n문 뒤와 닫힌 서랍에는 손님이 한눈에 볼 수 있기를 원하지 않는 모든 물건을 보관할 수 있습니다. 자녀가 있는 경우, 청소용품이 숨겨져 있는 문과 서랍에 어린이용 잠금 장치를 설치하세요. 수건, 화장지 등을 붙박이 찬장의 더 넓은 공간에 보관하세요. 붙박이 옷장 전체에 문을 설치해야 하나요? 그런 다음 문을 큰 거울과 결합하여 시각적으로 방을 확대합니다.",
                image: "collaro-bathroom-furniture-white-venticello-washing-basin-white-more-to-see-mirror.webp",
            },
            {
                title: "거울장으로 물건이 사라지네요",
                text: "거울수납장은 모든 욕실의 절대적인 고전 중 하나입니다. 거울 외에도 다양한 저장 옵션을 제공합니다. 또한 많은 거울 캐비닛에는 조명과 헤어드라이어 및 면도기용 추가 소켓이 장착되어 있습니다. 싱크대 위 틈새에 공간이 있나요? 그런 다음 Villeroy & Boch My View Now 컬렉션 에서 내장 거울 캐비닛을 선택하세요 . 하이라이트: 거울 표면 뒤에 숨겨진 여러 개의 수납공간이 있습니다.\r\n고품질 거울 수납장은 칫솔과 다양한 화장품을 보관할 수 있는 공간을 제공합니다. 모든 제품을 구획별로 구체적으로 정리할 수 있습니다. 내장된 조명은 완벽한 조명을 보장합니다. 따라서 거울 수납장은 욕실에 꼭 있어야 하는 필수품입니다.",
                image: "my-view-mirror-with-light-opened.webp",
            },
            {
                title: "주차 및 충전용 벤치",
                text: "다기능 가구는 크고 작은 욕실에서 그 무게만큼 가치가 있습니다. 특히 작은 욕실에는 여러 작업을 수행하는 솔루션이 필요합니다. 벤치는 다양한 용도로 사용됩니다. 좌석 요소는 접혀서 수건과 청소 제품을 보관할 수 있는 칸을 숨길 수 있습니다. 또는 벤치에는 개방형 수납공간과 서랍이 있습니다.\r\n많은 부모들은 화장실에서 아이를 편안하게 변화시키고 변화시킬 수 있는 기회를 갖고 싶어합니다. 벤치는 일체형 좌석을 제공하고 기저귀 교체용 매트, 기저귀 등을 수용할 수 있기 때문에 이상적입니다. 두꺼운 패드를 사용하면 벤치에 있는 아이를 갈아입힐 수도 있습니다.",
                image: "finion-bathroom-ceramics-white.webp",
            },
        ],
        slide2: [
            {
                title: "클래식: 싱크대 하부장",
                text: "물론, 세면대 하부장 없이는 욕실이 없어야 합니다. 욕실 디자인에 완벽하게 어울리는 스탠딩 버전과 걸이 버전 중에서 선택하세요. 예를 들어, Villeroy & Boch의 Finion 및 Subway 3.0 컬렉션 에는 모든 욕실 용품을 보관할 수 있는 충분한 공간을 제공하는 다양한 세면대 유닛이 있습니다. 장식 요소와 조명 요소를 수용할 수 있는 개방형 수납공간이 있는 변형도 있습니다.",
                image: "subway-volcano-black-bathroom-furniture.webp",
            },
            {
                title: "오픈 선반과 코너 선반으로 공간과 깊이를 연출",
                text: "욕실에는 수납이 가능한 작은 구석이 많이 있습니다. 샤워실에는 개방형 코너 선반이 있어 샤워젤, 샴푸, 면도기를 보관할 수 있는 공간을 제공합니다. 욕실의 다른 구석에는 개방형 선반과 코너 선반이 있어 친근해 보이고 방 자체가 너무 어수선해 보이지 않도록 해줍니다.\r\n선반이 어수선해지는 것을 방지하려면 작은 물건은 상자나 바구니를 사용하세요. 선반이 너무 커 보이지 않도록 선반을 부분적으로만 채우세요. 식물과 기타 장식 요소는 개별 선반에 잘 어울립니다. 시각적으로 수납가구를 느슨하게 풀어 집 같은 분위기를 연출합니다.",
                image: "more-to-see-light-bathroom-mirrow-white-on-grey-wall+(1).webp",
            },
            {
                title: "약국 캐비닛은 추가 저장 공간을 제공합니다.",
                text: "약종 캐비닛은 실제로 부엌에서 더 일반적입니다. 좁은 서랍형 찬장은 향신료, 키친페이퍼, 기타 작은 물건을 보관할 수 있는 공간을 제공합니다. 욕실에는 약국 캐비닛이 유용한 보관 옵션이 되는 장소도 있습니다. Apothecary 캐비닛은 건식 벽체 벽, 좁은 틈새 및 큰 찬장 옆 공간에 놀라운 저장 공간을 만듭니다. 시각적으로 방의 디자인에 맞게 조정한 다음 벽이나 틈새로 자연스럽게 사라질 수 있습니다. 헤어드라이어나 고데기를 위한 멀티탭을 찬장에 통합하는 것도 가능합니다.",
                image: "finion-midnightblue-blue-bathromm-furniture-with-more-to-see-mirror.webp",
            },
            {
                title: "욕조는 추가 저장 공간을 제공할 수도 있습니다.",
                text: "작은 욕실에는 수건 선반과 욕조 아래 바닥 매트가 통합된 욕조를 선택하면 수납 공간이 확보됩니다.\r\n욕조 위에는 작은 선반이나 수건 사다리용 틈새를 사용할 수 있습니다. 욕조 근처에 필요한 모든 종류의 도구를 보관할 수 있는 공간을 제공합니다. 장식 요소와 LED 양초도 그 자리를 차지합니다. 욕조 근처에는 전기 장치를 보관하지 마십시오.",
                image: "legato-bathtub-in-modern-bathroom-with-city-view.webp",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>