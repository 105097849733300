<template>
    <page-section>
        <v-container class="position-relative pb-12px py-md-72px">
            <v-sheet class="section-bg" />
            <v-row align="center" class="row--lg">
                <v-col cols="12" md="7">
                    <v-card tile img="/images/ideas/kitchen/cleaning/cleaned-kitchen.webp" class="position-relative mb-50px mb-md-0">
                        <v-responsive :aspect-ratio="674 / 504" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="5">
                    <v-card color="grey lighten-5" class="h-md-400px d-md-flex align-center">
                        <div>
                            <div class="tit mb-12px mb-md-16px">주방 청소
                            </div>
                            <p class="txt txt--sm mb-18px mb-md-24px">건강하고 위생적인 ​​조리 환경을 만들기 위해 주방을 깨끗하고 정돈된 상태로 유지하는 방법을 알아보세요. 작업 표면, 가전제품 및 도자기를 윤기 있고 깨끗하게 유지하는 효과적인 세척 방법과 제품을 알아보세요.</p>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </page-section>
</template>

<script>
import pageSection from "@/components/client/templates/page-section.vue";

export default {
    components: { pageSection },
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
.section-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }
}
@media (min-width: 768px) {
    .section-bg {
        width: calc(100% / 12 * 8);
        height: 100%;
    }
}
</style>