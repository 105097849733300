var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    attrs: {
      "id": "3"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('div', {
    staticClass: "product-section-title"
  }, [_c('span', {
    staticClass: "tit font-weight-regular font-tertiary white"
  }, [_vm._v("모든 스타일에 맞는 제품")])])]), _c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("지금 당신의 욕실에 딱 맞는 제품을 찾아보세요!")])]), _c('v-tabs', {
    staticClass: "mb-20px mb-md-40px",
    attrs: {
      "centered": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-tab', {
      key: index
    }, [_c('v-btn', {
      staticClass: "rounded-xs",
      attrs: {
        "large": ""
      }
    }, [_vm._v(" " + _vm._s(item.tab) + " ")])], 1)];
  })], 2), _c('v-row', [_vm._l(_vm.products, function (product, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "sm": "4",
        "lg": "3"
      }
    }, [_c('shop-product-card', _vm._b({}, 'shop-product-card', {
      value: product
    }, false))], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "product-lists__pagination"
  }, [_c('pagination-component', {
    attrs: {
      "value": _vm.page,
      "count": _vm.pageCount
    },
    on: {
      "input": function (value) {
        return _vm.page = value;
      }
    }
  })], 1)])], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }