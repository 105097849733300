var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/bathroom/installing/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Installing a bathroom yourself"
    }
  }), _c('page-section', {
    staticClass: "py-40px py-md-80px"
  }, [_c('v-container', {
    staticClass: "mt-50px mt-md-100px"
  }, [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실을 올바른 스타일로 장식하세요")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_c('p', [_vm._v("욕실 디자인 방법은 주로 개인 스타일에 따라 다릅니다. 욕실 가구를 개조하는 방법에는 여러 가지가 있으며, 인기 있는 예로는 색상 구성표나 해양 테마를 들 수 있습니다. 어떤 경우든 기능적 특징과 장식적 요소가 서로 조화를 이루어 전체적인 그림을 조화롭게 만들어야 합니다.")]), _c('p', [_vm._v("예를 들어 브라운, 베이지, 짙은 녹색 등 자연스러운 색상을 선택했다면 욕실 도자기는 흰색이 아닌 크림 톤으로 선택할 수 있다. 짙은 녹색으로 장식적인 포인트를 더하고 천연 목재로 만든 욕실 수납장과 선반을 선택하세요.")]), _c('p', [_vm._v("대비를 만들고 싶다면 흰색 욕실 도자기와 검정색 설비를 결합하세요. 검은색 요소와 흰색 조명 요소가 있는 램프를 선택하세요. 어두운 수건과 흰색 타일에 검은색 욕실 매트를 사용하여 장식에 대비를 더할 수도 있습니다.")]), _c('p', [_vm._v("Villeroy & Boch 욕실 가구 컬렉션에서는 욕실에서 자신의 스타일을 쉽게 구현할 수 있는 팁을 제공합니다. 모든 스타일에 적합한 욕실 도자기와 욕실 가구를 찾으실 수 있습니다. 선명한 라인, 로맨틱한 모양, 특이한 디자인 등 원하는 것을 Villeroy & Boch에서 찾으실 수 있습니다.")])])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('three-images', {
    attrs: {
      "path": "/images/ideas/bathroom/installing",
      "first": "infinity-showers-shower-tap-witch-black-walls.webp",
      "second": "antao-toilet-and-lever-handle-plate-black.jpg.webp",
      "third": "antao-washbasin-almond-with-mirror-and-vanity-unit.webp"
    }
  })], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실에 없어서는 안 되는 것은 무엇일까요? 인테리어에 꼭 필요한 것들")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_c('p', [_vm._v("욕실을 설치하고 어떤 장비 요소가 누락되어서는 안 되는지에 대한 아이디어를 찾고 계시다면 저희가 도와드릴 수 있습니다. 물론 모든 욕실에 속하는 몇 가지 사항이 있습니다. 여기서 가장 먼저 언급할 것은 욕실 도자기, 즉 싱크대, 변기, 욕조 또는 샤워기입니다. 넓은 욕실이 있는 경우 물론 비데 또는 비데 변기뿐만 아니라 두 가지 변형을 모두 수용할 수 있습니다.")]), _c('p', [_vm._v("수건, 칫솔, 크림 및 기타 일상 용품을 보관할 수 있는 찬장이나 선반을 욕실에 두는 것도 합리적입니다. 이렇게 하면 욕실의 질서와 정리가 훨씬 쉬워집니다. 대부분의 욕실에는 문이 달린 거울 캐비닛이 하나 이상 있습니다. 이것은 종종 조명을 받기 때문에 일반적으로 싱크대 위에 조명 연결이 있습니다.")]), _c('p', [_vm._v("욕실 도자기와 수납장을 설치할 때 명심해야 할 몇 가지 사항이 있습니다. 한편으로는 물 연결이 중요합니다. 욕실을 계획할 때 나중에 화장실 등이 어디에 위치할지 결정됩니다. 후속 변경이 가능하지만 노력과 비용이 필요합니다. 반면, 수납장이나 거울의 경우 나사 구멍을 타일에 직접 뚫지 않도록 주의하세요. 그렇지 않으면 타일이 깨질 위험이 있습니다. 두 타일 사이의 접합부나 타일 표면 위에 드릴을 뚫습니다.")])]), _c('expansion-panels', {
    attrs: {
      "data": _vm.furnishings
    }
  })], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('three-images', {
    attrs: {
      "path": "/images/ideas/bathroom/installing",
      "first": "antao-bath-tub-white-in-bathroom-with-wall-of-glass.webp",
      "second": "hommage-washbasins-black-with-mirrors-oval-and-bathtub-freestanding.webp",
      "third": "finion-bathtub-white-freestanding-washbasin-white-and-shower-tray-glazed.webp"
    }
  })], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실을 가구로 꾸미기 전에 계획하기")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_c('p', [_vm._v("이제 귀하의 스타일에 맞는 현대적인 방식으로 욕실을 장식하는 방법을 알게 되셨습니다. Villeroy & Boch 욕실 플래너는 욕실 디자인을 완벽하게 구현하는 데 도움을 줄 수 있습니다. 이를 통해 완성된 욕실이 어떤 모습일지 아이디어를 얻을 수 있습니다. 또한 욕실 디자인을 시작할 수 있는 전문가의 영감 아이디어와 템플릿을 제공합니다.")]), _c('p', [_vm._v("물 연결을 포함한 욕실 평면도를 기본으로 사용하여 귀하와 귀하의 스타일에 맞는 욕실 도자기 및 욕실 가구를 선택하십시오. 이제 욕실 플래너에서 가구와 위생 시설을 배치하고 무엇이 잘 어울리는지, 무엇을 바꾸고 싶은지 즉시 확인할 수 있습니다. 욕실을 설치할 때 온라인 서비스를 사용하면 높은 비용을 들이지 않고도 몇 번의 클릭만으로 다양한 변형을 시험해 볼 수 있다는 장점이 있습니다. 그런 다음 해당 지역의 Villeroy & Boch 파트너와 함께 욕실 계획을 구현하십시오.")])])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/installing/universal-showers-shower-tap-matt-black-witch-architectura-shower-tray.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실을 계획할 때 잊지 말아야 할 것")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_c('p', [_vm._v("욕실에서는 디자인과 기분 좋은 요소 외에도 기능성과 실용성도 중요합니다. 따라서 욕실에 보관하는 모든 물건을 현명하고 실용적으로 보관할 수 있도록 공간 요소를 선택하세요. 욕실 가구는 모든 식기를 보관할 수 있는 충분한 공간을 제공해야 합니다.")]), _c('p', [_vm._v("욕실에는 적절한 조명이 필수적입니다. 한편, 샤워실과 욕조 주변의 젖은 부분은 항상 조명이 밝아야 합니다. 반면에 거울이나 싱크대 부분에는 선택적인 조명 요소가 실용적입니다. 스타일리시한 무드 조명이 휴식을 선사합니다. 욕실 아이디어를 기존 조명과 결합하여 최대한의 효과를 얻으세요.")])])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("온라인 욕실 플래너 사용 팁")])]), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('dot-list', [_c('li', [_vm._v("나중에 욕실에 대한 현실적인 그림을 얻을 수 있도록 구조적 특징, 연결 및 기타 모든 요소를 ​​욕실 플래너에 최대한 정확하게 입력하세요.")]), _c('li', [_vm._v("욕실에서 필요한 이동 영역을 고려하십시오. 이는 배리어프리 욕실에만 필수적인 것이 아닙니다.")]), _c('li', [_vm._v("욕실 계획을 시작하기 전에 스타일을 결정하세요. 현대적인 욕실은 화려한 스타일로 다른 욕실 가구와 조화를 이룹니다.")]), _c('li', [_vm._v("욕실 아이디어를 미리 모아두고 욕실 플래너를 활용해 어떤 아이디어가 잘 어울리는지 살펴보세요. 이렇게 하려면 가능한 가구나 시스템을 저장하십시오.")]), _c('li', [_vm._v("다양한 버전의 욕실 계획을 이메일로 받아 욕실에서 직접 비교하여 완벽한 버전을 찾으세요.")])])], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "md": "1"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card', {
    staticClass: "pa-24px pa-md-40px txt",
    attrs: {
      "tile": "",
      "elevation": "3"
    }
  }, [_c('p', [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', [_vm._v("mdi-information-outline")])], 1), _c('v-col', {
    staticClass: "font-weight-medium"
  }, [_vm._v("새 욕실 비용은 얼마입니까?")])], 1)], 1), _c('p', {
    staticClass: "txt--sm"
  }, [_vm._v("새 욕실 비용은 귀하의 희망 사항과 구조적 조건에 따라 크게 달라집니다. 기본적으로 세 가지 요소에 따라 가격이 결정됩니다. 선택한 위생용품 및 욕실 가구, 조립 액세서리 및 자재 비용, 외부 작업자 및 전문 장인 비용입니다.")]), _c('p', {
    staticClass: "txt--sm"
  }, [_vm._v("위생 용품 및 가구 비용에 대한 예산을 미리 설정합니다. Villeroy & Boch에서는 모든 예산에 적합한 컬렉션을 찾을 수 있습니다. 모든 Villeroy & Boch 컬렉션은 고품질이며 세부 사항까지 세심하게 디자인되었습니다. 해당 지역의 전문 판매점에서 적합한 컬렉션에 대해 기꺼이 조언해 드릴 것입니다.")]), _c('p', {
    staticClass: "txt--sm"
  }, [_vm._v("필요한 장착 액세서리는 선택한 위생 물체에 따라 다릅니다. 새 건물인 경우 파이프, 전력선, 송수관, 소켓, 타일 접착제, 그라우트 등이 필요합니다. 이로 인해 최대 4자리 비용이 빠르게 추가될 수 있습니다.")]), _c('p', {
    staticClass: "txt--sm"
  }, [_vm._v("많은 사람들이 스스로 욕실을 리모델링하거나 새 욕실을 만들고 싶어합니다. 그럼에도 불구하고 개별 작업은 전문 회사에 맡기는 것이 좋습니다. 새 욕실 비용이 얼마인지에 대한 질문은 회사의 예상 가격과 귀하가 직접 수행하는 작업에 따라 다릅니다. 배관공의 비용은 시간당 50~100유로이며, 훌륭한 전기 기술자에게도 동일하게 적용됩니다.")])])], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실 가구를 직접 만들 때의 위험")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_c('p', [_vm._v("욕실을 직접 디자인하는 경우 몇 가지 사항에 주의해야 합니다. 숙련된 전기 기술자가 아닌 경우 전문적인 배선 케이블을 보유하고 소켓을 설치하는 것이 좋습니다. 그렇지 않으면 잠재적으로 생명을 위협하는 실수가 발생할 수 있습니다. 배관 설치에도 동일하게 적용됩니다. 배관공은 더 빠르고, 더 안전하고, 더 깔끔하게 일할 수 있습니다.")]), _c('p', [_vm._v("기존 욕실을 꾸미고 싶다면 상상력을 마음껏 발휘해 직접 꾸며보세요. 욕실 가구 설치, 장식 부착, 거울이나 조명 요소 교체 등은 DIY 애호가나 아마추어도 수행할 수 있는 작업입니다. 새로운 위생 시설이 없어도 가구, 수건, 욕실 매트 및 장식을 사용하여 욕실을 완전히 새로운 모습으로 꾸밀 수 있습니다.")])])])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }