var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/bathroom/cleaning/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Bathroom cleaning and care"
    }
  }), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("위생가이드")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("편안한 욕실 경험을 위해서는 두 가지가 필수적입니다. 바로 최대의 편안함과 안정적인 위생입니다. 매일 사용하거나 여러 사람이 사용하는 욕실에서는 위생이 특히 중요합니다. 세균과 먼지가 빠르게 쌓이고 퍼집니다. 깨끗한 욕실을 위해서는 올바른 세척제를 사용하는 것이 중요할 뿐만 아니라, 현대 기술을 사용하여 박테리아와 먼지의 원인이 애초에 발생하지 않도록 할 수도 있습니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/cleaning/subway-30-washbasin-mixer-chrome-on-white-washbasin.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("산뜻함, 상쾌함, 순수함")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 맑고 반짝이는 물은 언제나 깨끗함과 신선함의 상징이었습니다. 욕실에서 우리는 이를 닦는 것부터 상쾌한 샤워, 따뜻하고 편안한 거품 목욕에 이르기까지 개인 관리를 위해 매일 욕실을 사용합니다. 빌레로이앤보흐는 청결과 위생에 중점을 두고 있습니다. 이것이 바로 우리가 전반적으로 쾌적한 욕실 경험을 위해 스테인리스 스틸로 제작된 고품질의 내구성이 뛰어난 세라믹과 욕실 부속품을 제공하는 이유입니다. 빌레로이 앤 보흐의 새로운 비데 화장실은 화장실 사용 후 깨끗한 물로 씻어내므로 산뜻하고 깔끔한 느낌을 선사합니다. 여기에서 적절한 팁을 찾을 수 있습니다. ")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "product-section-title"
  }, [_c('div', {
    staticClass: "tit white"
  }, [_vm._v("위생 주제를 한눈에")])])])], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.topics, function (item) {
    return [_c('v-col', {
      key: item.title,
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('v-card', {
      attrs: {
        "min-height": "494",
        "tile": "",
        "elevation": "4"
      }
    }, [_c('v-img', {
      attrs: {
        "src": `/images/ideas/bathroom/cleaning/${item.image}`
      }
    }), _c('v-card-title', {
      staticClass: "pb-8px pt-md-32px px-md-32px"
    }, [_c('div', {
      staticClass: "tit tit--xs"
    }, [_vm._v(" " + _vm._s(item.title) + " ")])]), _c('v-card-text', {
      staticClass: "px-md-32px pb-md-24px"
    }, [_c('p', {
      staticClass: "txt txt--sm"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])])], 1)], 1)];
  })], 2)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/cleaning/onovo-bidet-white-with-toilet-white-washbasin-white-and-shower-tray.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center pa-20px",
    attrs: {
      "height": "100%",
      "color": "grey lighten-5"
    }
  }, [_c('div', [_c('div', {
    staticClass: "tit tit--sm mb-8px mb-md-10px"
  }, [_vm._v("곰팡이 방지")]), _c('p', {
    staticClass: "txt txt"
  }, [_vm._v("욕실 곰팡이 예방 방법!")])])])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/cleaning/ceramic-tile-surface-white-whiping.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center pa-20px",
    attrs: {
      "height": "100%",
      "color": "grey lighten-5"
    }
  }, [_c('div', [_c('div', {
    staticClass: "tit tit--sm mb-8px mb-md-10px"
  }, [_vm._v("곰팡이 제거")]), _c('p', {
    staticClass: "txt txt"
  }, [_vm._v("욕실 곰팡이 제거 방법!")])])])], 1)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "color": "transparent",
      "max-width": "980"
    }
  }, [_c('v-row', {
    staticClass: "row--xxl",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_vm._l(_vm.tips, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('v-card', {
      staticClass: "mx-auto",
      attrs: {
        "tile": "",
        "flat": "",
        "color": "transparent",
        "max-width": item.maxWidth
      }
    }, [_c('v-img', {
      attrs: {
        "src": `/images/ideas/bathroom/cleaning/${item.image}`
      }
    }), _c('div', {
      staticClass: "pa-16px"
    }, [_c('div', {
      staticClass: "tit tit--xs mb-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])])], 1)], 1)];
  })], 2)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('cleaning-qnas')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }