<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/cleaning/visual.webp" :breadcrumbs="breadcrumbs" title="Bathroom cleaning and care" />

        <page-section class="page-section--grey">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">위생가이드</div>
                        <p class="txt txt--sm">편안한 욕실 경험을 위해서는 두 가지가 필수적입니다. 바로 최대의 편안함과 안정적인 위생입니다. 매일 사용하거나 여러 사람이 사용하는 욕실에서는 위생이 특히 중요합니다. 세균과 먼지가 빠르게 쌓이고 퍼집니다. 깨끗한 욕실을 위해서는 올바른 세척제를 사용하는 것이 중요할 뿐만 아니라, 현대 기술을 사용하여 박테리아와 먼지의 원인이 애초에 발생하지 않도록 할 수도 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img src="/images/ideas/bathroom/cleaning/subway-30-washbasin-mixer-chrome-on-white-washbasin.webp" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">산뜻함, 상쾌함, 순수함</div>
                        <p class="txt txt--sm">
                            맑고 반짝이는 물은 언제나 깨끗함과 신선함의 상징이었습니다. 욕실에서 우리는 이를 닦는 것부터 상쾌한 샤워, 따뜻하고 편안한 거품 목욕에 이르기까지 개인 관리를 위해 매일 욕실을 사용합니다. 빌레로이앤보흐는 청결과 위생에 중점을 두고 있습니다. 이것이 바로 우리가 전반적으로 쾌적한 욕실 경험을 위해 스테인리스 스틸로 제작된 고품질의 내구성이 뛰어난 세라믹과 욕실 부속품을 제공하는 이유입니다. 빌레로이 앤 보흐의 새로운 비데 화장실은 화장실 사용 후 깨끗한 물로 씻어내므로
                            산뜻하고 깔끔한 느낌을 선사합니다. 여기에서 적절한 팁을 찾을 수 있습니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="product-section-title">
                    <div class="tit white">위생 주제를 한눈에</div>
                </div>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row class="row--lg">
                    <template v-for="item in topics">
                        <v-col cols="12" md="4" :key="item.title">
                            <v-card min-height="494" tile elevation="4">
                                <v-img :src="`/images/ideas/bathroom/cleaning/${item.image}`" />
                                <v-card-title class="pb-8px pt-md-32px px-md-32px">
                                    <div class="tit tit--xs">
                                        {{ item.title }}
                                    </div>
                                </v-card-title>
                                <v-card-text class="px-md-32px pb-md-24px">
                                    <p class="txt txt--sm">
                                        {{ item.text }}
                                    </p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="6">
                        <v-row no-gutters>
                            <v-col cols="6">
                                <v-img src="/images/ideas/bathroom/cleaning/onovo-bidet-white-with-toilet-white-washbasin-white-and-shower-tray.webp" />
                            </v-col>
                            <v-col cols="6">
                                <v-sheet height="100%" color="grey lighten-5" class="d-flex align-center pa-20px">
                                    <div>
                                        <div class="tit tit--sm mb-8px mb-md-10px">곰팡이 방지</div>
                                        <p class="txt txt">욕실 곰팡이 예방 방법!</p>
                                    </div>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-row no-gutters>
                            <v-col cols="6">
                                <v-img src="/images/ideas/bathroom/cleaning/ceramic-tile-surface-white-whiping.webp" />
                            </v-col>
                            <v-col cols="6">
                                <v-sheet height="100%" color="grey lighten-5" class="d-flex align-center pa-20px">
                                    <div>
                                        <div class="tit tit--sm mb-8px mb-md-10px">곰팡이 제거</div>
                                        <p class="txt txt">욕실 곰팡이 제거 방법!</p>
                                    </div>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-sheet color="transparent" max-width="980" class="mx-auto">
                    <v-row justify="center" align="center" class="row--xxl">
                        <template v-for="item in tips">
                            <v-col cols="12" md="auto" :key="item">
                                <v-card tile flat color="transparent" :max-width="item.maxWidth" class="mx-auto">
                                    <v-img :src="`/images/ideas/bathroom/cleaning/${item.image}`" />
                                    <div class="pa-16px">
                                        <div class="tit tit--xs mb-8px">
                                            {{ item.title }}
                                        </div>
                                        <p class="txt txt--sm">
                                            {{ item.text }}
                                        </p>
                                    </div>
                                </v-card>
                            </v-col>
                        </template>
                    </v-row>
                </v-sheet>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <cleaning-qnas />
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import CleaningQnas from '@/components/client/ideas/bathroom/overview/cleaning/cleaning-qnas.vue';

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        CleaningQnas,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 개요",
                href: "/ideas/bathroom/overview",
            },
        ],
        topics: [
            {
                title: "깨끗한 배수구",
                text: "배수구를 올바르게 청소하는 방법",
                image: "bathroom-sink-white-water-flows-down-the-drain.webp",
            },
            {
                title: "깨끗한 욕조",
                text: "욕조를 다시 깨끗하게 만드는 방법",
                image: "theano-bathtub-white-surface-detail.webp",
            },
            {
                title: "깨끗한 세면기",
                text: "세면기를 제대로 세척하는 방법",
                image: "bathroom-sink-white-hand-cleaning.webp",
            },
            {
                title: "깨끗한 샤워실",
                text: "샤워실을 다시 깨끗하게 만드는 방법",
                image: "squaro-infinity-shower-tray-white.webp",
            },
            {
                title: "화학물질 없이 욕실을 청소하세요",
                text: "화학물질 없이 욕실을 청소하는 가장 좋은 방법",
                image: "ceramic-surface-wc-white-whiping..webp",
            },
            {
                title: "화장실을 청소하세요",
                text: "욕실을 빠르고 효율적으로 청소하는 방법",
                image: "architectura-wc-white-hand-cleaning.webp",
            },
        ],
        tips: [
            {
                title: "화장실 물때 제거",
                text: "빌레로이앤보흐 변기 물때 제거 팁",
                image: "collaro-toilet-white.jpg.webp",
                maxWidth: "338",
            },
            {
                title: "샤워 헤드의 석회질 제거",
                text: "VIlleroy & Boch 샤워 헤드 석회질 제거 팁",
                image: "wallway-shower-tray-in-modern-bathroom.webp",
                maxWidth: "438",
            },
            {
                title: "화장실을 환기시키세요",
                text: "욕실을 올바르게 환기시키는 방법",
                image: "collaro-bathroom-loft-bath-fresh-air.webp",
                maxWidth: "438",
            },
            {
                title: "소변 결석 제거",
                text: "소변 스케일을 올바르게 제거하십시오: 깨끗한 화장실을 위한 팁",
                image: "toilet-wall-mounted-open-lid-revealing-bowl.webp",
                maxWidth: "338",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>