var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "image-wrap"
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/${_vm.first}`,
      "max-width": "672"
    }
  }), _c('v-img', {
    staticClass: "positioned-image positioned-image--1",
    attrs: {
      "src": `${_vm.path}/${_vm.second}`
    }
  }), _c('v-img', {
    staticClass: "positioned-image positioned-image--2",
    attrs: {
      "src": `${_vm.path}/${_vm.third}`
    }
  }), _vm.text ? [_c('div', {
    staticClass: "mt-16px mt-md-n80px"
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('p', {
    staticClass: "txt txt--xs"
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")])])], 1)], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }