<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/solution/visual.webp" :breadcrumbs="breadcrumbs" title="Bath planning with Villeroy & Boch" />

        <page-section class="pt-40px pt-md-80px">
            <v-container class="mt-50px mt-md-100px">
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">집의 중심 생활 공간인 욕실은 결국 개인의 요구 사항을 충족해야 하기 때문에 가구를 배치할 때 많은 주의를 기울일 가치가 있습니다. 가족용 욕실인가요, 아니면 작은 손님용 화장실인가요? 장벽이 없는 화장실을 원하시나요? 집에 웰빙 천국을 만들고 싶으신가요? 아니면 모든 것이 가능할까요?</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container class="position-relative pb-12px py-md-72px">
                <swiper v-bind="{ options }">
                    <template v-for="item in items">
                        <swiper-slide :key="item.subtitle">
                            <v-row align="center" class="row--lg">
                                <v-col cols="12" md="5" order="2" order-md="1">
                                    <v-card class="h-md-400px d-md-flex align-center">
                                        <div>
                                            <div class="tit mb-12px mb-md-16px">작은 욕실과 그 가능성</div>
                                            <div class="txt txt--sm txt--dark font-weight-medium">
                                                {{ item.subtitle }}
                                            </div>
                                            <p class="txt txt--sm mb-18px mb-md-24px">
                                                {{ item.text, }}
                                            </p>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="7" order="1" order-md="2">
                                    <v-card tile :img="item.image" class="position-relative mb-50px mb-md-0">
                                        <v-responsive :aspect-ratio="674 / 504" />
                                        <div class="swiper-controler">
                                            <v-row align="center" class="row--xs">
                                                <v-col cols="auto">
                                                    <v-btn x-small icon outlined class="swiper-button swiper-button--prev">
                                                        <v-img max-width="32" src="/images/icon/icon-swiper-left.svg" />
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <div class="swiper-pagination" />
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-btn x-small icon outlined class="swiper-button swiper-button--next">
                                                        <v-img max-width="32" src="/images/icon/icon-swiper-right.svg" />
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </swiper-slide>
                    </template>
                </swiper>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 py-40px py-md-80px my-20px my-md-40px">
            <v-container>
                <div class="tit mb-12px mb-md-16px">멋진 것들을 위한 공간</div>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">따라서 욕실을 세부적으로 계획하기 전에 먼저 미래의 욕실에 대한 자신의 바람과 요구 사항을 파악하는 것이 중요합니다. 물론 욕실에 어떤 디자인 옵션을 구현할 수 있는지에 대한 개요도 알아야 합니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img src="/images/ideas/bathroom/solution/memeto-2.0-toilet-white-bathroom.webp" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit tit--sm mb-12px mb-md-16px">넓은 욕실</div>
                        <p class="txt txt--sm">아늑한 분위기를 위한 올바른 객실 배치</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7" order-md="2">
                        <v-img src="/images/ideas/bathroom/solution/finion-bathtub-in-classic-bathroom-with-white-and-blue-walls.webp" />
                    </v-col>
                    <v-col cols="12" md="5" order-md="1">
                        <div class="tit tit--sm mb-12px mb-md-16px">웰니스 목욕</div>
                        <p class="txt txt--sm">나만의 기분 좋은 오아시스를 집으로 가져오세요</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-containr>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">다양한 공간 상황에는 다양한 공간 솔루션이 필요합니다. 개인 욕실에 대한 기본 정보와 유용한 팁을 확인하고 개별 객실 솔루션에서 영감을 얻으세요.</p>
                    </v-col>
                </v-row>
            </v-containr>
        </page-section>

        <page-section class="grey lighten-5 py-40px py-md-80px my-20px my-md-40px">
            <v-container>
                <div class="tit-wrap tit-wrap--lg">
                    <div class="tit">개별 솔루션</div>
                </div>
                <v-row class="row--lg">
                    <template v-for="item in solutions">
                        <v-col cols="6" md="4" :key="item.title">
                            <v-img :src="item.image" class="mb-12px mb-md-16px" />
                            <div class="tit tit--xxs mb-6px mb-md-8px">
                                {{ item.title }}
                            </div>
                            <p class="txt txt--sm">
                                {{ item.text }}
                            </p>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        ClientPage,
        PageSection,
        ShopProductVisual,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 개요",
                href: "/ideas/bathroom/overview",
            },
        ],
        items: [
            {
                subtitle: "손님용 욕실",
                text: "아름답게 디자인된 게스트 욕실은 긍정적인 인상을 줍니다.",
                image: "/images/ideas/bathroom/solution/slide-1.webp",
            },
            {
                subtitle: "욕조가 있는 작은 욕실",
                text: "공간이 부족하더라도 욕조를 놓치지 마세요.",
                image: "/images/ideas/bathroom/solution/slide-2.webp",
            },
            {
                subtitle: "샤워 시설을 갖춘 작은 욕실",
                text: "작은 욕실에 더 많은 공간을 확보하는 효율적인 가구 솔루션",
                image: "/images/ideas/bathroom/solution/slide-3.webp",
            },
        ],
        solutions: [
            {
                title: "배리어프리 욕실",
                text: "기능성과 아름다운 인테리어 디자인을 결합했습니다.",
                image: "/images/ideas/bathroom/solution/vicare-bathroom-with-accessible-shower-tray.webp",
            },
            {
                title: "경사진 천장이 있는 욕실",
                text: "올바른 공간 컨셉으로 경사진 욕실을 최대한 활용해보세요.",
                image: "/images/ideas/bathroom/solution/antao-bathtub-white-with-washbasin-and-taps.webp",
            },
            {
                title: "가족 욕실",
                text: "온 가족이 사용할 수 있는 수납 공간을 갖춘 욕실입니다.",
                image: "/images/ideas/bathroom/solution/subway-3.0-bathroom-ceramics-white-with+bathtub-washbasin-taps.webp",
            },
        ],
        options: {
            effect: "fade",
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                },
            },
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
::v-deep(.swiper-container) {
    overflow: visible;
}
.swiper-controler {
    position: absolute;
    bottom: -56px;
    left: 0;
    .swiper-pagination {
        position: static;
        padding: 0 16px;
        ::v-deep(.swiper-pagination-bullet) {
            margin: 0 16px !important;
            background-color: initial !important;
            border: initial !important;
            width: auto !important;
            height: auto !important;
            font-weight: 500;
            color: var(--v-grey-darken3);
            border-radius: initial;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
            &-active {
                border-bottom: 1px solid var(--v-grey-darken3) !important;
            }
        }
    }
}
@media (min-width: 768px) {
}
</style>