<template>
    <div>
        <swiper v-bind="{ options }">
            <template v-for="item in items">
                <swiper-slide :key="item">
                    <v-row align="center" class="row--lg">
                        <v-col cols="12" md="5" order="2" order-md="1">
                            <v-card color="white" class="h-md-400px d-md-flex align-center">
                                <div>
                                    <div class="tit mb-12px mb-md-16px">
                                        {{ item.title }}
                                    </div>
                                    <p class="txt txt--sm mb-18px mb-md-24px">
                                        {{ item.text, }}
                                    </p>
                                    <template v-if="item.button">
                                        <v-btn large color="#fff" elevation="2" class="rounded-xs ml-1" :href="item.link" target="_blank">
                                            {{ item.button }}
                                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                        </v-btn>
                                    </template>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="7" order="1" order-md="2">
                            <v-card tile :img="item.image" class="position-relative mb-50px mb-md-0">
                                <v-responsive :aspect-ratio="674 / 504" />
                                <div class="swiper-controler">
                                    <v-row align="center" class="row--xs">
                                        <v-col cols="auto">
                                            <v-btn x-small icon outlined class="swiper-button swiper-button--prev">
                                                <v-img max-width="32" src="/images/icon/icon-swiper-left.svg" />
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="auto">
                                            <div class="swiper-pagination" />
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn x-small icon outlined class="swiper-button swiper-button--next">
                                                <v-img max-width="32" src="/images/icon/icon-swiper-right.svg" />
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </swiper-slide>
            </template>
        </swiper>
    </div>
</template>

<script>
export default {
    data: () => ({
        items: [
            {
                title: "타일이 없는 욕실",
                text: "타일을 사용하지 않고 욕실을 리모델링하면 현대적이고 우아해 보이며 곰팡이 발생도 예방할 수 있습니다. 석고 또는 장식 패널을 사용하여 타일이 없는 욕실을 디자인하세요.",
                image: "/images/ideas/bathroom/renovation/bathroom-1.webp",
            },
            {
                title: "샤워실 밀봉하기",
                text: "실리콘은 일반적으로 샤워기나 욕조를 밀봉하기 위해 밀봉 테이프와 함께 사용됩니다. 이는 곰팡이 형성을 방지합니다. 따라서 대형 샤워실을 설치할 때 밀봉이 잘 되는지 확인하는 것이 더욱 중요합니다.",
                image: "/images/ideas/bathroom/renovation/bathroom-2.webp",
            },
            {
                title: "욕실 부분 리모델링",
                text: "욕실의 일부만 개조하고 싶다면 욕실 개조에 대한 우리의 작은 팁과 요령이 도움이 될 것입니다. 몇 가지 리소스만으로 새로운 모습을 만들 수 있습니다.",
                image: "/images/ideas/bathroom/renovation/bathroom-3.webp",
            },
            {
                title: "타일이 없는 욕실",
                text: "욕실을 완전히 개조하고 싶다면 완전한 욕실 개조를 통해 최신 표준과 개인 취향에 맞게 조정하십시오.",
                image: "/images/ideas/bathroom/renovation/bathroom-4.webp",
            },
        ],
        options: {
            effect: "fade",
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                },
            },
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    .swiper-container {
        overflow: visible;
    }
}
.swiper-controler {
    position: absolute;
    bottom: -56px;
    left: 0;
    .swiper-pagination {
        position: static;
        padding: 0 16px;
        ::v-deep(.swiper-pagination-bullet) {
            margin: 0 16px !important;
            background-color: initial !important;
            border: initial !important;
            width: auto !important;
            height: auto !important;
            font-weight: 500;
            color: var(--v-grey-darken3);
            border-radius: initial;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
            &-active {
                border-bottom: 1px solid var(--v-grey-darken3) !important;
            }
        }
    }
}
</style>