var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/bathroom/trends/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Ideas & Trends"
    }
  }), _c('page-section', {
    staticClass: "py-40px py-md-80px"
  }, [_c('v-container', [_c('div', {
    staticClass: "mt-50 mt-md-100px"
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실이 트렌디한 오아시스가 됩니다!")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("최신 트렌드, 멋진 아이디어, 가장 인기 있는 욕실 스타일 - 욕실을 나만의 개성 있는 공간으로 바꾸고 일상 생활을 스타일리시하게 만드는 방법을 보여 드리겠습니다. 새로운 꿈의 욕실로 가는 길에 Villeroy & Boch의 창의적인 공간과 색상 컨셉에서 영감을 얻으세요. 지금 트렌드를 발견하고 가장 핫한 스타일을 찾아보세요!")])])], 1)], 1)])], 1), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px mb-40px mb-md-80px"
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "border-md-r",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium mb-18p mb-md-24px"
  }, [_vm._v("모든 취향에 맞는 욕실 스타일")]), _c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#1"
    }
  }, [_vm._v(" 스타일 살펴보기 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "border-md-r",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium mb-18p mb-md-24px"
  }, [_vm._v("욕실의 색상 트렌드")]), _c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#2"
    }
  }, [_vm._v(" 색상 살펴보기 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "border-md-r",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium mb-18p mb-md-24px"
  }, [_vm._v("모든 스타일에 맞는 제품")]), _c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#3"
    }
  }, [_vm._v(" 욕실용품을 만나보세요 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium mb-18p mb-md-24px"
  }, [_vm._v("더 많은 아이디어")]), _c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#4"
    }
  }, [_vm._v(" 영감을 찾아보세요 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--xxl",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/trends/trend-1.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs mb-20px grey--text font-weight-light"
  }, [_vm._v("2024년 트렌드")]), _c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("현재 욕실 트렌드: 올해의 필수 아이템")]), _c('p', {
    staticClass: "txt txt--sm mb-18px mb-md-24px"
  }, [_vm._v("2024년에는 욕실이 현대적인 디자인과 편안함을 상징하게 될 것입니다. 이러한 발전을 반영하는 10가지 트렌드를 소개합니다.")]), _c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/ideas/bathroom/trends/2024"
    }
  }, [_vm._v(" 지금 트렌드를 알아보세요 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "1"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "product-section-title"
  }, [_c('span', {
    staticClass: "tit font-weight-regular font-tertiary white"
  }, [_vm._v("최고의 욕실 스타일")])])])], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("모든 취향에 맞는 영감")])]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.inspirations, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "to": item.link
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "txt grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v(_vm._s(item.title) + " 욕실 알아보기")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px my-20px my-md-40px",
    attrs: {
      "id": "2"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "product-section-title tit-wrap tit-wrap--lg"
  }, [_c('span', {
    staticClass: "tit font-weight-regular font-tertiary grey lighten-5"
  }, [_vm._v("색상 트렌드")])]), _c('h3', {
    staticClass: "tit text-center mb-12px mb-md-16px"
  }, [_vm._v("마법 같은 색상의 매력")]), _c('p', {
    staticClass: "txt txt--sm text-center"
  }, [_vm._v("이런 연출로 욕실이 단번에 시선을 사로잡을 것입니다.")]), _c('div', {
    staticClass: "d-flex justify-center mt-12px mt-md-16px"
  }, [_c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/ideas/bathroom/overview/colours"
    }
  }, [_vm._v(" 색상 살펴보기 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1), _c('div', {
    staticClass: "color-image-wrap"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/trends/trend-color-1.webp",
      "max-width": "672"
    }
  }), _c('v-img', {
    staticClass: "positioned-image positioned-image--1",
    attrs: {
      "src": "/images/ideas/bathroom/trends/trend-color-2.webp"
    }
  }), _c('v-img', {
    staticClass: "positioned-image positioned-image--2",
    attrs: {
      "src": "/images/ideas/bathroom/trends/trend-color-3.webp"
    }
  }), _c('div', {
    staticClass: "color-text"
  }, [_vm._v(" \"색상은 분위기, 크기, "), _c('br'), _vm._v(" 방의 정의 등 거의 모든 것을 "), _c('br'), _vm._v(" 바꿀 수 있습니다.\" "), _c('br'), _vm._v(" 디자이너 - 게사 한센 ")])], 1)])], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("당신의 욕실에는 어떤 색상이 어울리나요?")])]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.colors, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "href": item.link,
        "target": "_blank"
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "txt grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), item.link ? _c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v(_vm._s(item.button) + " 욕실 알아보기")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1) : _vm._e()], 1)], 1)];
  })], 2)], 1)], 1), _c('page-section', [_c('trends-slide')], 1), _c('trends-products-tabs'), _c('page-section', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "lg": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "max-height": "508",
      "src": "/images/ideas/bathroom/trends/trend-idea.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "lg": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v(" 더 많은 욕실 아이디어를 "), _c('br'), _vm._v(" 원하시나요? ")]), _c('p', {
    staticClass: "txt txt--sm mb-18px mb-md-24px"
  }, [_vm._v(" 그렇다면 지금 욕실에 대한 객실 아이디어를 살펴보거나 "), _c('br'), _vm._v(" 가까운 전시회를 방문하는 것이 가장 좋습니다. ")]), _c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/ideas/bathroom/overview"
    }
  }, [_vm._v(" 욕실에 대한 아이디어 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)], 1)], 1), _c('trends-more-ideas'), _c('trends-interested'), _c('trends-exploration')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }