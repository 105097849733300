<template>
    <swiper v-bind="{ options }">
        <template v-for="item in items">
            <swiper-slide :key="item">
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5" order="2" order-md="1">
                        <v-card :color="item.color" class="min-h-200px h-md-400px d-md-flex align-center">
                            <div>
                                <template v-if="item.subtitle">
                                    <div class="txt txt--xs mb-20px grey--text font-weight-light">
                                        {{ item.subtitle }}
                                    </div>
                                </template>
                                <div class="tit tit--sm mb-12px mb-md-16px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm text-pre-wrap">{{ item.text, }}</p>
                                <template v-if="item.button">
                                    <v-btn large color="#fff" elevation="2" class="rounded-xs ml-1 mt-18px mt-md-24px" :to="item.link">
                                        {{ item.button }}
                                        <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                    </v-btn>
                                </template>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="7" order="1" order-md="2">
                        <v-card tile :img="`${path}/${item.image}`" class="position-relative mb-50px mb-md-0">
                            <v-responsive :aspect-ratio="674 / 504" />
                            <div class="swiper-controler">
                                <v-row align="center" class="row--xs">
                                    <v-col cols="auto">
                                        <v-btn x-small icon outlined class="swiper-button swiper-button--prev">
                                            <v-img max-width="32" src="/images/icon/icon-swiper-left.svg" />
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="auto">
                                        <div class="swiper-pagination" />
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn x-small icon outlined class="swiper-button swiper-button--next">
                                            <v-img max-width="32" src="/images/icon/icon-swiper-right.svg" />
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </swiper-slide>
        </template>
    </swiper>
</template>

<script>
export default {
    props: {
        items: { type: String },
        path: { type: String },
    },
    data: () => ({
        options: {
            effect: "fade",
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                },
            },
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
.swiper-slide {
    padding-bottom: 56px;
}
.swiper-controler {
    position: absolute;
    bottom: -56px;
    left: 0;
    .swiper-pagination {
        position: static;
        padding: 0 16px;
        ::v-deep(.swiper-pagination-bullet) {
            margin: 0 16px !important;
            background-color: initial !important;
            border: initial !important;
            width: auto !important;
            height: auto !important;
            font-weight: 500;
            color: var(--v-grey-darken3);
            border-radius: initial;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
            &-active {
                border-bottom: 1px solid var(--v-grey-darken3) !important;
            }
        }
    }
}
</style>