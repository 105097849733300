var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expansion-panels', {
    attrs: {
      "tile": ""
    }
  }, [_vm._l(_vm.data, function (item) {
    return [_c('v-expansion-panel', {
      key: item.header
    }, [_c('v-expansion-panel-header', {
      staticClass: "txt txt--lg txt--dark"
    }, [_vm._v(" " + _vm._s(item.header) + " ")]), _c('v-expansion-panel-content', {
      staticClass: "txt txt--sm txt--dark"
    }, [item.list ? [item.content ? [_c('div', {
      staticClass: "text-pre-wrap"
    }, [_vm._v(" " + _vm._s(item.content) + " ")])] : _vm._e(), _c('dot-list', [_vm._l(item.list, function (child) {
      return [_c('li', {
        key: child,
        staticClass: "mb-0"
      }, [_vm._v(" " + _vm._s(child) + " ")])];
    })], 2)] : [_vm._v(" " + _vm._s(item.content) + " ")]], 2)], 1)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }