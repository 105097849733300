<template>
    <div>
        <page-section id="2">
            <v-container>
                <div class="product-section-title">
                    <span class="tit font-weight-regular font-tertiary white">욕실을 위한 혁신</span>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container class="position-relative pb-12px py-md-72px">
                <v-sheet class="section-bg" />

                <swiper v-bind="{ options }">
                    <template v-for="item in revolutions">
                        <swiper-slide :key="item">
                            <v-row align="center" class="row--lg">
                                <v-col cols="12" md="5" order="2" order-md="1">
                                    <v-card color="grey lighten-5" class="h-md-400px d-md-flex align-center">
                                        <div>
                                            <div class="txt txt--xs mb-20px grey--text font-weight-light">
                                                {{ item.subtitle }}
                                            </div>
                                            <div class="tit mb-12px mb-md-16px">
                                                {{ item.title }}
                                            </div>
                                            <p class="txt txt--sm mb-18px mb-md-24px">
                                                {{ item.text, }}
                                            </p>
                                            <template v-if="item.button">
                                                <v-btn large color="#fff" elevation="2" class="rounded-xs ml-1" :href="item.link" target="_blank">
                                                    {{ item.button }}
                                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                                </v-btn>
                                            </template>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="7" order="1" order-md="2">
                                    <v-card tile :img="item.image" class="position-relative mb-50px mb-md-0">
                                        <v-responsive :aspect-ratio="674 / 504" />
                                        <div class="swiper-controler">
                                            <v-row align="center" class="row--xs">
                                                <v-col cols="auto">
                                                    <v-btn x-small icon outlined class="swiper-button swiper-button--prev">
                                                        <v-img max-width="32" src="/images/icon/icon-swiper-left.svg" />
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <div class="swiper-pagination" />
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-btn x-small icon outlined class="swiper-button swiper-button--next">
                                                        <v-img max-width="32" src="/images/icon/icon-swiper-right.svg" />
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </swiper-slide>
                    </template>
                </swiper>
            </v-container>
        </page-section>
    </div>
</template>

<script>
import pageSection from "@/components/client/templates/page-section.vue";

export default {
    components: { pageSection },
    data: () => ({
        revolutions: [
            {
                subtitle: "컬렉션",
                title: "Antao",
                text: "이슬방울에서 영감을 받아 자연의 아름다움과 평온함을 집에 직접 가져다주는 새로운 Antao 컬렉션으로 자연의 마법 같은 에너지를 경험해 보세요.",
                image: "/images/ideas/bathroom/revolution-1.webp",
                button: "Antao 컬렉션 보러 가기",
                link: "https://www.villeroy-boch.at/b/antao/",
            },
            {
                subtitle: "최고의 조합",
                title: "욕실 설비",
                text: "욕실의 조화로운 디자인과 최적의 기능성을 위해 다양한 욕실 설비를 만나보세요!",
                image: "/images/ideas/bathroom/revolution-2.webp",
                button: "욕실 설비 보러 가기",
                link: "https://www.villeroy-boch.at/c/badarmaturen/",
            },
            {
                subtitle: "공간 솔루션",
                title: "손님용 욕실",
                text: "Artis 세면기와 Collaro 베이스 캐비닛을 갖춘 세련된 게스트 욕실입니다. 작은 공간을 위한 영감을 주는 솔루션.",
                image: "/images/ideas/bathroom/revolution-3.webp",
            },
        ],
        options: {
            effect: "fade",
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                },
            },
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
.section-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }
}
::v-deep(.swiper-container) {
    overflow: visible;
}
.swiper-controler {
    position: absolute;
    bottom: -56px;
    left: 0;
    .swiper-pagination {
        position: static;
        padding: 0 16px;
        ::v-deep(.swiper-pagination-bullet) {
            margin: 0 16px !important;
            background-color: initial !important;
            border: initial !important;
            width: auto !important;
            height: auto !important;
            font-weight: 500;
            color: var(--v-grey-darken3);
            border-radius: initial;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
            &-active {
                border-bottom: 1px solid var(--v-grey-darken3) !important;
            }
        }
    }
}
@media (min-width: 768px) {
    .section-bg {
        width: calc(100% / 12 * 8);
        height: 100%;
    }
}
</style>