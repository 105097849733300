<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/repairs/visual.webp" :breadcrumbs="breadcrumbs" title="Bathroom repairs" />

        <page-section class="pt-40px pt-md-80px">
            <v-container class="mt-50px mt-md-100px">
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">우리 욕실이 웰빙의 오아시스로 남아 있기 위해서는 때때로 수리 작업을 수행해야 합니다. 욕실의 작은 결함을 수리하는 방법을 설명합니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">욕실 수리에 유용한 팁</div>
                        <div class="txt txt--sm">
                            <p>때때로 욕실에는 케이블, 파이프, 변기, 부속품 또는 샤워실 문과 같은 결함이 있습니다. 그렇다면 어느 정도 광범위한 욕실 수리가 필요합니다. 배수구가 막히거나, 배관에서 물이 새거나, 수도꼭지에서 물이 떨어지면 불쾌합니다. 반면에 귀중한 물은 손실됩니다.</p>
                            <p>
                                하지만 때때로 욕실의 다른 것들도 수리해야 합니다. 예를 들어, 여기에는 타일 사이의 실리콘 조인트 수리가 포함됩니다. 그리고 타일 자체도 손상될 수 있습니다. 아침에 피곤해서 거울 앞에 서서 헤어드라이어를 찾으려고 손을 뻗었는데 헤어드라이어가 손에서 떨어졌다고 상상해 보세요. 헤어드라이어는 바닥에 대한 충격을 견뎌냈지만 이제 바닥 타일에 균열이 생겼습니다. 다치는 사람이 없도록 당연히 교체해야 합니다. 그러나 욕실 수리를 불가피하게 만드는 것은 단순한 마모의 징후인 경우가
                                많습니다. 스스로 할 수 있는 욕실 수리와 필요한 도구 및 재료에 대해 설명하겠습니다.
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img src="/images/ideas/bathroom/repairs/venticello-toilet-in-bathroom-with-squaro-infinity-shower-tray.webp" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">욕실 배관 문제 해결</div>
                        <p class="txt txt--sm">
                            누구나 이전에 경험해 본 적이 있을 것입니다. 변기의 물이 더 이상 내려가지 않고 물이 계속 흐릅니다. 비용이 발생할 수 있습니다. 동시에 물은 불필요하게 낭비됩니다. 따라서 수리를 통해 문제를 신속하게 해결하는 것이 중요합니다. 씰, 플로트, 흡입 밸브 등 플러셔의 대부분 구성 요소는 쉽게 수리하거나 교체할 수 있습니다. 구매 시 교체 부품이 변기 수세식에 맞는지 확인하세요. 그리고 위생도기가 손상되면 어떻게 되나요? 싱크대나 샤워 트레이의 가벼운 긁힘은 특수 수리 키트를 사용하여
                            수리할 수 있습니다. 큰 손상이 있으면 새 것을 구입하는 것이 좋습니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <swiper v-bind="{ options }">
                    <template v-for="item in items">
                        <swiper-slide :key="item.title">
                            <v-row align="center" class="row--lg">
                                <v-col cols="12" md="5" order="2" order-md="1">
                                    <v-card class="h-md-400px d-md-flex align-center">
                                        <div>
                                            <div class="tit mb-12px mb-md-16px">
                                                {{ item.title }}
                                            </div>
                                            <div class="txt txt--sm txt--dark font-weight-medium">
                                                {{ item.subtitle }}
                                            </div>
                                            <p class="txt txt--sm mb-18px mb-md-24px">
                                                {{ item.text, }}
                                            </p>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="7" order="1" order-md="2">
                                    <v-card tile :img="item.image" class="position-relative mb-50px mb-md-0">
                                        <v-responsive :aspect-ratio="674 / 504" />
                                        <div class="swiper-controler">
                                            <v-row align="center" class="row--xs">
                                                <v-col cols="auto">
                                                    <v-btn x-small icon outlined class="swiper-button swiper-button--prev">
                                                        <v-img max-width="32" src="/images/icon/icon-swiper-left.svg" />
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <div class="swiper-pagination" />
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-btn x-small icon outlined class="swiper-button swiper-button--next">
                                                        <v-img max-width="32" src="/images/icon/icon-swiper-right.svg" />
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </swiper-slide>
                    </template>
                </swiper>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 py-40px py-md-80px my-20px my-md-40px">
            <v-container>
                <div class="tit mb-12px mb-md-16px">욕실 타일 수리</div>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">
                            타일은 마모와 습기로부터 벽을 보호합니다. 그러나 충격에 취약합니다. 그러나 몇 가지 요령을 사용하면 손상된 욕실 타일을 수리할 수 있습니다. 작은 균열이나 흠집은 터치업 펜으로 쉽게 제거할 수 있습니다. 특수 타일 접착제로 부서진 부분을 다시 부착합니다. 클래식 필러는 더 큰 균열과 드릴 구멍을 막는 데 이상적입니다. 그러나 필러에는 석고가 포함되어 있어 수분을 흡수합니다. 그래서 많은 사람들이 실리콘을 선택합니다. 이 소재는 다양한 색상으로 제공되며 습기에 견딜 수 있습니다.
                            에폭시 수지는 타일의 균열을 메우는 데에도 적합합니다.
                        </p>
                        <div class="tit-wrap tit-wrap--lg"></div>
                        <div class="tit mb-12px mb-md-16px">타일 ​​수리용 도구 및 재료</div>
                        <p class="txt txt--sm">타일을 수리하려면 장인의 기술은 물론 다양한 도구와 재료가 필요합니다.</p>
                        <div class="txt txt--sm">
                            <ul class="list">
                                <li>타일을 수리하는 데 필요한 가장 중요한 도구에는 무선 드릴, 멀티 샌더 및 멀티 도구가 있습니다.</li>
                                <li>그 외에도 다양한 보조 수단이 필요합니다. 다음 도구는 특히 중요합니다: 브러시, 긁는 도구, 천 또는 청소용 브러시, 필러 대패, 플라스틱 주걱, 펜치, 와이퍼, 진공 청소기 및 장갑.</li>
                                <li>또한 멀티 샌더 또는 손 샌딩(예: 240방)을 위한 사포, 실리콘 또는 2성분 필러 및 타일 페인트가 필요합니다.</li>
                            </ul>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center" align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <v-img src="/images/ideas/bathroom/repairs/architectura-shower-tray-anthracite-with-grey-tiles.webp" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">적합한 교체 타일 선택</div>
                        <p class="txt txt--sm">타일의 작은 흠집이나 손상은 비교적 쉽게 수리할 수 있습니다. 그러나 큰 균열이나 균열이 있는 경우 유일한 해결책은 종종 새 타일로 교체하는 것입니다. 하지만 올바른 기술과 도구를 사용하면 스스로 할 수 있습니다. 이상적으로는 이 상황을 위해 일부 타일을 저장했습니다. 그런 다음 즉시 타일 교체를 시작할 수 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit-wrap tit-wrap--lg">
                            <div class="tit mb-12px mb-md-16px">욕실 문제 예방을 위한 팁</div>
                            <p class="txt txt--sm">욕실은 따뜻하고 습합니다. 이러한 사실과 일상적인 사용으로 인해 손상이 빠르게 발생할 수 있습니다. 그러나 다음 팁을 사용하면 이러한 문제를 예방할 수 있는 경우가 많습니다.</p>
                        </div>

                        <ol class="tip-list">
                            <template v-for="(item, index) in tips">
                                <li :key="index">
                                    <v-row class="row--xs">
                                        <v-col cols="auto">
                                            <v-avatar size="18" class="border border-dark font-size-12 txt--dark d-md-none">
                                                {{ index + 1 }}
                                            </v-avatar>
                                            <v-avatar size="24" class="border border-dark txt txt--sm txt--dark d-none d-md-block">
                                                {{ index + 1 }}
                                            </v-avatar>
                                        </v-col>
                                        <v-col>
                                            <div class="txt txt--sm mt-2px mt-md-0">
                                                <div class="font-weight-medium txt--dark mb-4px">
                                                    {{ item.title }}
                                                </div>
                                                <div>
                                                    {{ item.text }}
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </li>
                            </template>
                        </ol>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        ClientPage,
        PageSection,
        ShopProductVisual,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 개요",
                href: "/ideas/bathroom/overview",
            },
        ],
        items: [
            {
                title: "수도꼭지 씰 교체",
                text: "화장실에서 물이 샌다면 즉시 조치를 취해야 합니다! 이는 파이프 누수 및 물이 떨어지는 수도꼭지에도 적용됩니다. 이는 씰 때문일 수 있습니다. 몇 단계만 거치면 직접 변경할 수 있습니다. 이 문서에서는 이것이 어떻게 작동하는지와 주의해야 할 사항에 대해 설명합니다.",
                image: "/images/ideas/bathroom/repairs/slide-1.webp",
            },
            {
                title: "화장실이 막혔나요?",
                text: "화장실이 막혔나요? 문제는 변기 솔, 흡입 컵, 파이프 청소 케이블과 같은 도구뿐 아니라 식초, 베이킹 소다, 뜨거운 물과 같은 간단한 가정 요법으로 신속하게 제거될 수 있습니다. 어떤 옵션이 있는지, 막힌 변기를 뚫는 방법을 알려드리겠습니다.",
                image: "/images/ideas/bathroom/repairs/slide-2.webp",
            },
            {
                title: "수도꼭지에서 물이 떨어지나요?",
                text: "때로는 욕실 설비를 수리해야 할 때도 있습니다. 예를 들어 수도꼭지에서 물이 떨어지면 석회질이 원인인 경우가 많습니다. 이를 제거하려면 수도꼭지 입구에서 통풍 장치 (통기 장치)를 풀고 식초와 물의 용액에 넣으십시오. 수도꼭지에서 물이 떨어지는 원인이 되는 다른 문제와 그에 대해 무엇을 할 수 있는지 알아보세요.",
                image: "/images/ideas/bathroom/repairs/slide-3.webp",
            },
            {
                title: "실리콘 조인트를 직접 수리하세요",
                text: "모든 실리콘 조인트는 시간이 지남에 따라 균열이 발생하여 곰팡이 형성을 촉진합니다. 가능하다면 습기가 벽에 침투하는 것을 방지하기 위해 오래된 실리콘을 교체해야 합니다. 카트리지 건을 사용하여 조인트에 새 재료를 주입하기 전에 특수 긁는 도구를 사용하여 오래된 실리콘을 쉽게 제거할 수 있습니다. 여기에서는 욕실 줄눈 수리에 대한 유용한 팁과 요령을 찾을 수 있습니다.",
                image: "/images/ideas/bathroom/repairs/slide-4.webp",
            },
        ],
        tips: [
            {
                title: "정기적으로 뜨거운 물로 파이프를 세척하세요.",
                text: "정기적으로 뜨거운 물로 씻어내면 배관이 막히는 것을 방지할 수 있습니다.",
            },
            {
                title: "오래된 실리콘 조인트를 갱신하십시오.",
                text: "습기가 벽에 침투하여 곰팡이가 생기는 것을 방지하려면 오래되고 갈라진 실리콘 조인트를 교체해야 합니다. ",
            },
            {
                title: "식초 세척제는 석회화를 방지합니다.",
                text: "사용 후 극세사 천으로 건조시키거나 식초 세척제로 닦아주시면 피팅의 석회화를 방지할 수 있습니다.",
            },
            {
                title: "욕실 세트는 바닥 타일을 보호합니다.",
                text: "고품질 욕실 세트는 욕실을 시각적으로 돋보이게 할 뿐만 아니라, 떨어지는 물체로 인해 바닥 타일이 손상되는 것을 방지해 줍니다.",
            },
        ],
        options: {
            effect: "fade",
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                },
            },
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
::v-deep(.swiper-container) {
    overflow: visible;
}
.swiper-controler {
    position: absolute;
    bottom: -56px;
    left: 0;
    .swiper-pagination {
        position: static;
        padding: 0 16px;
        ::v-deep(.swiper-pagination-bullet) {
            margin: 0 16px !important;
            background-color: initial !important;
            border: initial !important;
            width: auto !important;
            height: auto !important;
            font-weight: 500;
            color: var(--v-grey-darken3);
            border-radius: initial;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
            &-active {
                border-bottom: 1px solid var(--v-grey-darken3) !important;
            }
        }
    }
}

.list {
    margin: 40px 0 0;
    > li {
        padding-left: 24px;
        position: relative;
        margin-bottom: 12px;
        &::before {
            content: "";
            position: absolute;
            left: 10px;
            top: 8px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--v-grey-base);
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.tip-list {
    > li {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
@media (min-width: 768px) {
    .list {
        margin: 80px 0 0;
        > li {
            padding-left: 32px;
            margin-bottom: 16px;
            &::before {
                width: 6px;
                height: 6px;
                left: 6px;
                top: 9px;
            }
        }
    }
    .tip-list {
        > li {
            margin-bottom: 16px;
        }
    }
}
</style>