<template>
    <v-row class="row--lg">
        <v-col cols="12" md="4">
            <!-- Mobile -->
            <v-tabs hide-slider v-model="tab" class="d-md-none">
                <template v-for="item in items">
                    <v-tab :key="item.title">
                        <span class="tit tit--xs text-pre-wrap">
                            {{ item.title }}
                        </span>
                    </v-tab>
                </template>
            </v-tabs>
            <!-- Mobile -->

            <!-- PC -->
            <v-tabs vertical v-model="tab" class="d-none d-md-flex">
                <template v-for="item in items">
                    <v-tab :key="item.title">
                        <span class="tit tit--xs text-pre-wrap">
                            {{ item.title }}
                        </span>
                    </v-tab>
                </template>
            </v-tabs>
            <!-- // PC -->
        </v-col>
        <v-col cols="12" md="8">
            <v-tabs-items v-model="tab">
                <template v-for="item in items">
                    <v-tab-item :key="item.title">
                        <expansion-panels :data="item.children" />
                    </v-tab-item>
                </template>
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>
import ExpansionPanels from "@/components/client/ideas/expansion-panels.vue";

export default {
    components: {
        ExpansionPanels,
    },
    data: () => ({
        tab: null,
        items: [
            {
                title: "위생도자기 - CeramicPlus",
                children: [
                    {
                        header: "CeramicPlus의 데일리 케어는 어떤 모습인가요?",
                        content: "CeramicPlus를 정기적으로 관리하려면 시중에 판매되는 만능 세척제와 긁힘 방지 표면이 부드러운 주방 스펀지 또는 극세사 천을 사용하는 것이 가장 적합합니다. 연마성 분말 세제를 사용하면 시간이 지남에 따라 물이 흘러내릴 수 있으므로 피해야 합니다.",
                    },
                    {
                        header: "CeramicPlus로 위생적인 ​​도자기를 어떻게 인식하나요?",
                        content: '모든 CeramicPlus 제품에는 "Cplus" 스탬프가 있습니다. 일부 제품에는 글자와 스탬프가 결합되어 있습니다. 즉, "CeramicPlus Villeroy & Boch"가 추가된 큰 C입니다.',
                    },
                    {
                        header: "CeramicPlus는 어떻게 작동하나요?",
                        content: "CeramicPlus는 표면 에너지가 매우 낮기 때문에 발수성 및 발유성(소수성 및 소유성)입니다. 이 효과는 더 나은 비딩 효과와 접착 방지 효과를 기반으로 합니다.",
                    },
                    {
                        header: "CeramicPlus는 어떻게 작동하나요?",
                        content: "CeramicPlus는 표면 에너지가 매우 낮기 때문에 발수성 및 발유성(소수성 및 소유성)입니다. 이 효과는 더 나은 비딩 효과와 접착 방지 효과를 기반으로 합니다.",
                    },
                    {
                        header: "CeramicPlus에는 어떤 청소 제품을 사용할 수 있나요?",
                        content: "CeramicPlus는 시중에서 판매되는 모든 세척제에 내성이 있습니다. 이는 산, 용제, 계면활성제가 포함된 제품에도 적용됩니다. 유일한 예외는 연마제와 알칼리성 제품(파이프 클리너, 곰팡이 제거제)과의 장기간 접촉입니다.",
                    },
                    {
                        header: "CeramicPlus는 어떤 모델과 색상에 사용할 수 있나요?",
                        content: "CeramicPlus는 당사의 전체 브랜드 욕실 세라믹 및 세라믹 싱크대에 사용할 수 있습니다. 스타화이트, 베이지, 맨해튼, 에델바이스 색상은 CeramicPlus 버전에서만 사용할 수 있습니다. 흰색과 페르가몬은 CeramicPlus 유무에 관계없이 사용할 수 있습니다.",
                    },
                    {
                        header: "화장품이나 헤어 컬러링 제품이 CeramicPlus를 손상시킬 수 있나요?",
                        content: "CeramicPlus는 화장품의 일반적인 구성 요소인 용제(알코올, 아세톤) 및 화학 물질에 대한 내성이 있습니다.",
                    },
                    {
                        header: "CeramicPlus에 소독제를 사용할 수 있나요?",
                        content: "CeramicPlus는 소독제에 내성이 있습니다. 이러한 제품에 대한 제조업체의 지침을 준수해야 합니다. PH 12가 함유된 제품과 염소가 함유된 제품은 예외입니다. 염소가 함유된 제품은 오랫동안 CeramicPlus 표면과 접촉할 경우 효과가 저하될 수 있습니다.",
                    },
                ],
            },
            {
                title: "위생도기 - ViFresh",
                children: [
                    {
                        header: "변기 블록/젤의 마개(뚜껑)는 어린이에게 안전합니까?",
                        content: "뚜껑을 돌릴 때 약간의 딸깍 소리가 들릴 때만 뚜껑이 들어올릴 수 있는 위치에 있는 것입니다. 이것은 어린 아이들이 대처하기 어렵습니다. ",
                    },
                    {
                        header: "변기 블록/젤을 교체해야 하는지 어떻게 알 수 있나요?",
                        content: "더 이상 상쾌한 냄새가 나지 않고 변기에 거품이 거의 또는 전혀 형성되지 않으면 ViFresh에 새 변기 블록/젤을 다시 채워야 합니다.",
                    },
                    {
                        header: "뚜껑이 깨지거나 열려 있으면 물이 나올 수 있나요?",
                        content: "뚜껑은 내구성이 뛰어난 플라스틱으로 제작되어 매우 안정적입니다. 뚜껑은 정상적인 사용 중에 깨지지 않아야 합니다. 항상 ViFresh 뚜껑을 닫은 상태에서 변기 물을 내리십시오. 그렇지 않으면 물이 새어 나올 수 있습니다.",
                    },
                    {
                        header: "ViFresh 기술이 적용된 변기를 어디에서 볼 수 있나요?",
                        content: "이에 대해서는 설치업체/도매업체 에 문의하세요.",
                    },
                    {
                        header: "비프레쉬의 세정력/상큼한 향은 얼마나 강한가요?",
                        content: "이는 변기 블록/젤에 따라 다릅니다. ViFresh는 이러한 스톤/젤을 간단하고 무엇보다도 위생적으로 사용할 수 있도록 해줍니다.",
                    },
                    {
                        header: "바구니/뚜껑이 부러지면 어떻게 해야 하나요?",
                        content: "ViFresh 어댑터는 내구성이 매우 뛰어납니다. 혹시라도 제대로 작동하지 않는 경우에는 교체 부품을 제공해 드립니다. 이러한 경우에는  설치업체/도매업체 에 문의하세요 .",
                    },
                    {
                        header: "ViFresh의 적절한 관리는 어떤 모습인가요?",
                        content: "장기간 사용하려면 젤/화장석을 교체한 후 흐르는 물에 바구니를 철저히 헹구십시오. 화장실을 청소하려면 순한 비눗물을 사용하세요. 변기 시트와 경첩을 젖은 상태로 두지 말고 부드러운 천으로 닦아서 말려주세요. 시트/뚜껑 및 경첩이 변기 세척제나 염소, 연마제 또는 부식제가 함유된 세척제에 닿으면 손상되거나 녹슬 수 있습니다. 그러므로 항상 변기 시트와 뚜껑을 접어서 세제를 씻어내시기 바랍니다.",
                    },
                    {
                        header: "화장실 젤과 화장실 블록은 어떻게 바꾸나요?",
                        content: "조용한 딸깍 소리가 들릴 때까지 ViFresh 뚜껑을 왼쪽/오른쪽으로 돌리기만 하면 됩니다. 뚜껑을 들어올리고 바구니를 꺼냅니다. 잔여물을 제거하려면 흐르는 물에 바구니를 세척하십시오. 바구니에 새 변기 블록/젤을 채워보세요. 그런 다음 바구니를 플러싱 파이프에 다시 넣고 뚜껑을 닫습니다. 씰이 깨끗하고 건조하며 올바른 위치에 있는지 확인하십시오.",
                    },
                    {
                        header: "ViFresh 변기에는 어떤 제품을 사용할 수 있나요?",
                        content: "슈퍼마켓, 약국 또는 온라인에서 다양한 제품을 찾을 수 있습니다. ViFresh 바스켓 크기를 초과하지 않는 시중에서 판매되는 변기 향석이나 시중에서 판매되는 젤 린스를 사용하는 것이 좋습니다.",
                    },
                    {
                        header: "화장석/화장실 젤 교체 시 고려해야 할 사항은 무엇인가요?",
                        content: "기본적으로 모든 것이 직관적으로 실행되므로 작동 오류를 사실상 없앨 수 있습니다. 그러나 ViFresh 변기의 장기적인 이점을 활용하려면 스톤/젤을 교체할 때마다 흐르는 물에 바구니를 헹구는 것이 좋습니다. 다시 설치할 때 씰이 깨끗하고 올바른 위치에 있는지 확인하십시오.",
                    },
                    {
                        header: "어떤 변기에 ViFresh를 사용할 수 있나요?",
                        content: "ViFresh는 현재 표준 프로젝션 Subway 2.0 DirectFlush 및 Vivia DirectFlush를 갖춘 벽걸이형 양변기에 사용할 수 있습니다.",
                    },
                ],
            },
            {
                title: "ViClean 비데 변기",
                children: [
                    {
                        header: "ViClean이란 무엇인가요?",
                        list: ["ViClean은 변기와 비데의 완벽한 조화입니다.", "ViClean은 비데 변기와 전자 비데 시트 제품군에 속합니다.", "화장실 사용 후 ViClean은 따뜻한 물을 분사하여 은밀한 부위를 부드럽게 청소합니다.", "ViClean은 피부를 특별히 세정하는 노즐을 확장합니다.", "ViClean 컬렉션의 비데 변기는 최적의 친밀한 관리와 더 큰 웰빙을 위한 다양한 편의 기능을 제공합니다. 세심하게 고안된 샤워 제트 기술 외에도 편안한 작동과 개별 조정 기능도 이를 보장합니다."],
                    },
                    {
                        header: "ViClean에는 소프트 클로징 기술이 있나요?",
                        content: "예. 두 ViClean 모델 모두 혁신적인 Soft Closing 기술을 갖추고 있어 뚜껑과 시트가 조용하고 조용하게 닫힙니다.",
                    },
                    {
                        header: "ViClean 부착물은 영구적으로 설치됩니까, 아니면 이동식입니까? 직접 조립하고 분해할 수 있습니까?",
                        content: "ViClean 부착물은 영구적으로 설치되므로 전문가의 도움 없이는 직접 조립하거나 분해해서는 안 됩니다. ViClean 부착물은 이러한 목적으로 제작된 Villeroy & Boch 세라믹에만 적합합니다.",
                    },
                    {
                        header: "ViClean을 보고, 테스트하고, 구입할 수 있는 가까운 대리점은 어디입니까?",
                        content: "원칙적으로 ViClean은 해당 지역의 전문 판매점에서 구입할 수 있습니다. 딜러 검색을 통해 해당 지역에서 전시 모델과 테스트 화장실을 제공하는 전문 딜러를 찾으세요.",
                    },
                    {
                        header: "누가 ViClean을 사용할 수 있나요? 어린이도 ViClean을 사용할 수 있나요?",
                        content: "누구나 ViClean을 사용할 수 있습니다. ViClean을 사용하기 전에 어린이에게 ViClean 사용 방법을 설명해야 합니다. 수온, 샤워 노즐 위치 등 개인 취향을 앱을 통해 사용자 프로필에 편리하게 저장할 수 있습니다.",
                    },
                    {
                        header: "ViClean 뚜껑 위에 앉을 수 있나요?",
                        content: "기존 변기 시트의 경우 세라믹에 있는 경첩이 하중을 흡수합니다. ViClean을 사용하면 경첩이 플라스틱 하우징 내에서 더 높은 위치에 있습니다. 뚜껑에 자주 하중이 가해지면 경첩의 수명이 단축될 수 있습니다.",
                    },
                    {
                        header: "ViClean에는 어떤 기능이 있나요?",
                        content: "기능에 대한 자세한 내용은 ViClean 웹사이트( ViClean-I 100 ,  ViClean-I 200 )에서 확인할 수 있습니다.",
                    },
                    {
                        header: "열선시트를 사용하면 화상을 입을 수 있나요?",
                        content: "ViClean에는 시트 과열을 방지하는 여러 가지 안전 기능이 있습니다.",
                    },
                    {
                        header: "화장지는 여전히 필요합니까?",
                        content: "비데 변기를 사용할 경우 화장지를 사용하지 않고 청소할 수 있습니다. 개인의 필요에 따라 화장지를 사용하여 건조할 수 있습니다. 온풍건조기(ViClean-I 200)가 장착된 모델에서는 건조용으로 사용할 수 있습니다.",
                    },
                    {
                        header: "ViClean을 어떤 색상으로 주문할 수 있나요? 어떤 재료로 만들어졌나요?",
                        content: "ViClean은 알파인 화이트와 퓨어 블랙 색상으로 제공됩니다. Villeroy & Boch의 CeramicPlus 표면 개선으로 변기 청소가 더욱 쉬워졌습니다.\r\n\r\n재료 및 재료 특성:",
                        list: ["시트 및 뚜껑: 듀로플라스트(Duroplast)", "하우징(보이는 부분): ABS", "샤워 노즐: 알루미늄 튜브와 교체 가능한 플라스틱 노즐(ABS)", "세라믹: 먼지 방지 CeramicPlus 표면 포함"],
                    },
                ],
            },
            {
                title: "ViClean에 관한 수도 및 전기 관련 질문",
                children: [
                    {
                        header: "ViClean은 얼마나 많은 물을 사용합니까?",
                        content: "ViClean의 최대 물 소비량은 500ml/min입니다. 청소 과정별로. 또한 일반 변기와 마찬가지로 변기 물을 내리려면 물을 씻어야 합니다.",
                    },
                    {
                        header: "ViClean은 물과 전기로 구동됩니다. 여기에 위험이 있나요?",
                        content: "아니요. 유럽에는 하나의 장치에 물과 전기를 사용해야 하는 수많은 규정이 있습니다. 물론 ViClean은 모든 법적 요구 사항을 충족합니다.",
                    },
                    {
                        header: "정전이 발생해도 ViClean을 계속 사용할 수 있나요?",
                        content: "예, ViClean은 여전히 ​​물을 내리므로 변기로 사용할 수 있습니다. 정전 시 샤워 및 비데 변기의 편의 기능만 사용할 수 없습니다.",
                    },
                    {
                        header: "1인/4인 가구 기준 바이클린의 연간 평균 전력 소비량은 얼마입니까?",
                        content: "전기 소비량은 가구 구성원 수와 사용 습관에 따라 크게 달라집니다. 일반적으로 소비는 표준 현대 가전 제품 수준이라고 말할 수 있습니다. 편안함을 위한 시트 난방 기능이 이에 큰 기여를 합니다.",
                    },
                    {
                        header: "화장실을 사용할 때마다 ViClean을 켜고 꺼야 합니까?",
                        content: "아니요, 그럴 필요는 없습니다. ViClean은 지능형 에너지 관리 시스템 덕분에 에너지를 절약합니다.",
                    },
                ],
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.v-tabs {
    .v-tab {
        border-bottom: 1px solid var(--v-grey-lighten3);
        text-transform: initial;
        padding: 12px;
        height: auto;
        width: 50%;
        &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 4px;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            background-color: var(--v-primary-base);
            transition: all 0.2s;
        }

        &--active {
            &::after {
                width: 100%;
            }
        }
    }
    ::v-deep {
        .v-tabs-bar {
            height: auto;
        }
        .v-slide-group__wrapper {
            width: 100%;
        }
        .v-slide-group__content {
            width: 100%;
            flex-wrap: wrap;
            flex: initial !important;
        }
        .v-slide-group__prev .v-slide-group__next {
            display: none;
        }
    }
}

@media (min-width: 768px) {
    .v-tabs {
        border-left: 1px solid var(--v-grey-lighten3);
        .v-tab {
            border-bottom: none;
            width: auto;
            padding: 24px;
            justify-content: start;
            > span {
                text-align: start;
            }

            &::after {
                content: "";
                position: absolute;
                width: 4px;
                height: 0;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: var(--v-primary-base);
                transition: all 0.2s;
            }

            &--active {
                &::after {
                    height: 100%;
                }
            }
        }
    }
}
</style>