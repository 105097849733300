<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/planning/visual.webp" :breadcrumbs="breadcrumbs" title="Bathroom design tips from Villeroy & Boch" />

        <page-section class="pt-40px pt-md-80px">
            <v-container class="mt-50px mt-md-100px">
                <v-row justify="center" class="row--lg text-center">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">개인이 기분 좋은 욕실로 가는 길에 가능한 최고의 지원을 제공하기 위해 Villeroy & Boch는 욕실 계획에 관한 귀중한 팁을 모았습니다. 전문가와 함께 욕실을 계획하고 싶으신가요?</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container class="position-relative pb-12px py-md-72px">
                <v-sheet class="section-bg" />

                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7" order="1" order-md="2">
                        <v-img src="/images/ideas/bathroom/planning/woman-with-drink-in-bathtub.webp" />
                    </v-col>
                    <v-col cols="12" md="5" order="2" order-md="1">
                        <v-card color="grey lighten-5" class="h-md-400px d-md-flex align-center">
                            <div>
                                <div class="tit mb-12px mb-md-16px">영감에서 실현까지</div>
                                <div class="txt txt--sm">
                                    <p>Villeroy & Boch의 고품질 제품은 다양한 디자인 옵션을 제공합니다. 기능성과 디자인은 항상 신중한 방식으로 서로 조화를 이룹니다. 다양한 객실 아이디어에서 영감을 얻고 독점적인 욕실 트렌드, 효과적인 조명 컨셉, 맞춤형 웰니스 솔루션을 만나보세요.</p>
                                    <p>꿈의 욕실을 계획할 때 개인적인 바람과 요구 사항은 공간 조건만큼 중요한 역할을 합니다. 혁신적인 온라인 욕실 플래너를 사용하고 미래의 욕실을 사실적으로 디자인하여 아이디어와 아이디어를 생생하게 채워보세요. 귀하 지역의 수많은 전문 소매점에서도 귀하가 좋아하는 제품을 직접 체험할 수 있는 기회를 제공하고 전문적인 지원을 제공해 드릴 수 있습니다.</p>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container class="position-relative pb-12px py-md-72px">
                <v-sheet class="section-bg section-bg--secondary" />

                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img src="/images/ideas/bathroom/planning/architectura-bathtub-with-hand-shower-and-toilet.webp" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-card color="grey lighten-5" class="h-md-400px d-md-flex align-center">
                            <div>
                                <div class="tit mb-12px mb-md-16px">새 욕실을 구입하는 데 실제로 드는 비용은 얼마입니까?</div>
                                <div class="txt txt--sm">욕실을 개조하거나 개조할 계획이고 예상되는 비용을 미리 알고 싶으십니까? 전문 장인이 욕실 수리 작업과 비용을 전형적인 사례를 통해 정리했습니다. 또한 귀하가 활용할 수 있는 자금 조달 기회와 세금 절감에 대한 팁도 제공합니다.</div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <template v-for="item in items">
                        <v-col cols="12" md="6" :key="item.title">
                            <div>
                                <template v-if="item.image">
                                    <v-img :src="item.image" class="mb-18px mb-md-24px" />
                                </template>
                                <div class="tit tit--xs mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm">
                                    {{ item.text }}
                                </p>
                            </div>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 py-40px py-md-80px my-20px my-md-40px">
            <v-container>
                <div class="product-section-title tit-wrap tit-wrap--lg">
                    <div class="tit grey lighten-5">고품질 보급형 컬렉션 또는 고급스러운 프리미엄 장비?</div>
                </div>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="txt txt--sm">
                            <p>욕실 개조 또는 재설계의 범위와 기존 객실 상황에 따라 비용이 매우 달라집니다. 설치 및 위생 기술 재설치를 포함하여 전체 개조를 수행하려면 필요한 자재 및 인건비에 대한 계획을 세워야 합니다.</p>
                            <p>귀하가 꿈꾸는 욕실을 꾸밀 수 있도록 Villeroy & Boch는 각각 고유한 스타일과 다양한 가구 요구 사항을 충족하는 다양한 컬렉션을 제공합니다. 고품질의 보급형 컬렉션부터 독점적인 디자인 컬렉션, 럭셔리한 프리미엄 컬렉션까지 모든 예산에 맞는 제품이 있습니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        ClientPage,
        PageSection,
        ShopProductVisual,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 개요",
                href: "/ideas/bathroom/overview",
            },
        ],
        items: [
            {
                title: "욕실 개조 - 최고의 팁과 요령",
                text: "기존 욕실을 새롭게 디자인하고 싶다면 주의해야 할 사항에 대한 다양한 팁을 모아봤습니다. 기획과 디자인부터 시작해서 비용까지. 그리고 욕실 개조에 대한 법적 의무와 기한에 대한 설명은 세입자에게만 흥미로운 것이 아닙니다.",
            },
            {
                title: "타일 ​​없이 욕실을 개조하세요",
                text: "오늘날에는 미네랄 또는 점토 기반 석고로 벽을 석고로 칠하는 것과 같이 욕실의 고전적인 타일 백스플래시에 대한 다양한 매력적인 대안이 있습니다. 벽과 바닥을 클래딩하는 가장 중요한 건축 자재와 기술을 소개하고 타일 없이 욕실을 웰빙의 오아시스로 바꾸는 방법에 대한 팁을 알려드립니다.",
            },
            {
                title: "욕실용 샤워 세트 선택하기",
                text: "샤워할 때 편안함을 위해 필수적인 샤워 세트입니다. 다양한 디자인과 변형을 소개하고 현대식 핸드 샤워기를 설치하는 것이 왜 가치가 있는지 설명하겠습니다. 또한 새 샤워 세트를 올바르게 부착하는 방법과 설치를 더 쉽게 하기 위해 주의해야 할 사항도 배우게 됩니다.",
                image: "/images/ideas/bathroom/planning/antao-organic-fittings-bathroom.webp",
            },
            {
                title: "변기 시트를 위한 완벽한 소재 선택",
                text: "변기 시트는 다양한 재료로 만들어집니다. 그러므로 이에 대해 미리 생각하는 것이 합리적입니다. 가장 일반적인 재료와 구체적인 장점 및 단점을 소개합니다. 그리고 새 변기 시트를 찾을 때 재질 외에 무엇을 주의해야 하는지 설명해보세요.",
                image: "/images/ideas/bathroom/planning/architectura-toilet-white-in-scandic-bathroom.webp",
            },
            {
                title: "욕조에서 샤워할 때도 기분 좋은 요소",
                text: "작은 욕실에는 샤워기와 욕조를 모두 설치할 공간이 부족한 경우가 많습니다. 클래식 욕조에 대한 공간 절약형 대안을 소개하고, 새로운 (샤워) 욕조에 가장 적합한 소재를 선택하는 팁을 제공하며, 샤워 또는 목욕 시 에너지와 물을 절약할 수 있는 방법을 설명합니다.",
                image: "/images/ideas/bathroom/planning/subway-30-bathtub-with-grey-paneling-and-bath-mixer.webp",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.section-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }

    &--secondary {
        left: initial;
        right: 0;
        &::before {
            left: initial;
            right: -100vw;
        }
    }
}

@media (min-width: 768px) {
    .section-bg {
        width: calc(100% / 12 * 8);
        height: 100%;
    }
}
</style>