<template>
    <v-expansion-panels tile>
        <template v-for="item in data">
            <v-expansion-panel :key="item.header">
                <v-expansion-panel-header class="txt txt--lg txt--dark">
                    {{ item.header }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="txt txt--sm txt--dark">
                    <template v-if="item.list">
                        <template v-if="item.content">
                            <div class="text-pre-wrap">
                                {{ item.content }}
                            </div>
                        </template>
                        <dot-list>
                            <template v-for="child in item.list">
                                <li :key="child" class="mb-0">
                                    {{ child }}
                                </li>
                            </template>
                        </dot-list>
                    </template>
                    <template v-else>
                        {{ item.content }}
                    </template>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </template>
    </v-expansion-panels>
</template>

<script>
import dotList from "./dot-list.vue";

export default {
    components: { dotList },
    props: {
        data: { type: String },
    },
};
</script>

<style lang="scss" scoped>
.v-expansion-panels {
    .v-expansion-panel {
        background-color: transparent;
        &::before {
            box-shadow: none;
        }
        &-header {
            padding: 18px 12px 18px 0;
        }
        &-content {
            ::v-deep(.v-expansion-panel-content__wrap) {
                padding-left: 0;
                padding-right: 56px;
            }
        }
    }
}

@media (min-width: 768px) {
    .v-expansion-panels {
        .v-expansion-panel {
            &-header {
                padding: 24px 16px 24px 0;
            }
        }
    }
}
</style>