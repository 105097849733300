<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/kitchen/material/ceramicplus-visual.webp" :breadcrumbs="breadcrumbs" title="CeramicPlus in The Kitchen" />

        <page-section class="py-40px py-md-80px my-40px my-md-80px grey lighten-5">
            <v-container>
                <v-row justify="center" class="row--lg text-center">
                    <v-col cols="12" md="8">
                        <div class="tit tit--sm mb-12px mb-md-16px">더 많은 청결함 - 삶의 질을 위한 더 많은 시간</div>
                        <p class="txt txt--sm">
                            CeramicPlus는 깨끗하고 특히 관리가 쉬운 세라믹 표면을 위한 혁신적인 개발품입니다. CeramicPlus를 사용한 세라믹 마감은 결정적인 이점을 제공합니다. 접촉 시 액체는 즉시 방울에서 구슬로 결합되어 싱크대로 굴러갑니다. 먼지, 석회 등의 잔여물은 표면에 거의 들러붙지 않고 즉시 용해되어 제거됩니다. 이렇게 하면 CeramicPlus로 코팅된 세라믹을 더 쉽게 청소할 수 있습니다. 건조된 석회질 얼룩은 강력하고 마모성이 있으며 환경에 유해한 세제를 사용하지 않고도 즉시 제거할 수 있습니다.
                            CeramicPlus는 모든 표면에 최적의 위생과 상쾌한 청결을 제공합니다. 이 소재는 의료 및 화장품을 포함한 일반적인 산 및 알칼리 기반 가정용품에 내성이 있습니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="image-wrap">
                    <v-img src="/images/ideas/kitchen/material/ceramic-plus-1.webp" max-width="672" />
                    <v-img src="/images/ideas/kitchen/material/ceramic-plus-2.webp" class="positioned-image positioned-image--1" />
                    <v-img src="/images/ideas/kitchen/material/ceramic-plus-3.webp" class="positioned-image positioned-image--2" />
                </div>
            </v-container>
        </page-section>

        <page-section class="py-40px py-md-80px my-40px my-md-80px grey lighten-5">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit tit--sm mb-12px mb-md-16px">손쉬운 관리로 최고의 품질</div>
                        <ul class="list txt txt--sm">
                            <li>긁힘 방지 및 내구성이 뛰어난 표면</li>
                            <li>위생적이고 깨끗함</li>
                            <li>알칼리 및 산에 둔감함</li>
                            <li>사용된 재료에 대한 높은 품질 기준</li>
                        </ul>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="txt txt--sm txt--dark text-center pb-24px pb-md-40px">
                    <div><strong class="font-weight-bold">이미지 1:</strong> 세련된 CeramicPlus</div>
                    <div><strong class="font-weight-bold">이미지 2:</strong> 기존 세라믹</div>
                </div>
                <v-row justify="space-between" align="center" class="row--lg">
                    <v-col cols="12" md="auto">
                        <v-img src="/images/ideas/kitchen/material/comparison-1.webp" max-width="672" class="mx-auto" />
                    </v-col>
                    <v-col cols="12" md="auto">
                        <v-img max-width="368" src="/images/ideas/kitchen/material/comparison-2.webp" class="mx-auto" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "주방 소재",
                href: "/ideas/kitchen/material",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}

.image-wrap {
    position: relative;
    margin-top: 30px;
    .positioned-image {
        &--1 {
            max-width: 288px;
            margin-top: -60px;
            margin-right: calc(-1 * var(--container-gutter));
            margin-left: auto;
            position: relative;
            z-index: 1;
        }
        &--2 {
            max-width: 240px;
            margin-left: calc(-1 * var(--container-gutter));
            margin-top: -180px;
        }
    }
}
@media (min-width: 768px) {
    .image-wrap {
        margin-top: 40px;
        .positioned-image {
            max-width: 368px;
            &--1 {
                margin-top: -130px;
                margin-right: 266px;
            }
            &--2 {
                margin: initial;
                position: absolute;
                top: 100px;
                right: 0;
            }
        }
    }
}
@media (min-width: 1024px) {
    .color-image-wrap {
        margin-top: 40px;
        .positioned-image {
            max-width: 368px;
            &--1 {
                margin-top: -130px;
                margin-right: 266px;
            }
            &--2 {
                margin: initial;
                position: absolute;
                top: 100px;
                right: 0;
            }
        }
    }
}

.list {
    margin: 40px 0;
    > li {
        padding-left: 24px;
        position: relative;
        margin-bottom: 12px;
        &::before {
            content: "";
            position: absolute;
            left: 10px;
            top: 8px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--v-grey-base);
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
@media (min-width: 768px) {
    .list {
        margin: 80px 0;
        > li {
            padding-left: 32px;
            margin-bottom: 16px;
            &::before {
                width: 6px;
                height: 6px;
                left: 6px;
                top: 9px;
            }
        }
    }
}
</style>