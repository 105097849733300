<template>
    <v-row class="row--xxl">
        <template v-for="item in items">
            <v-col :key="item" cols="12" md="6">
                <v-card tile flat>
                    <v-img :src="item.image" class="mb-6px mb-md-8px" />
                    <div class="pa-16px">
                        <div class="tit tit--xs mb-6px mb-md-8px">
                            {{ item.name }}
                        </div>
                        <p class="txt txt--sm mb-6px mb-md-8px">
                            {{ item.text }}
                        </p>
                        <router-link :to="item.link" class="font-weight-medium txt txt--xs txt--dark d-flex align-center">
                            {{ item.name }}에 대한 추가 정보
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </router-link>
                    </div>
                </v-card>
            </v-col>
        </template>
    </v-row>
</template>

<script>
export default {
    data: () => ({
        items: [
            {
                name: "작은 주방",
                text: "한 가구는 가족과는 다른 주방 요구 사항을 가지고 있습니다. 조리 시설은 종종 작은 방에 통합되어야 합니다. 1인용 주방은 여기에서 매우 인기가 있습니다. 목표는 공간을 최적으로 활용하는 것입니다. 갤리 주방에도 마찬가지입니다. 이 미니 주방에는 필수품만 보관됩니다. 조리대는 없습니다.",
                image: "/images/ideas/kitchen/overview/kitchenware-1.webp",
                link: "/",
            },
            {
                name: "오픈형 주방과 주방 식당",
                text: "요리하는 동안 가족이나 친구와 채팅을 하시나요? 오픈 플랜 주방이라면 문제 없습니다. 식사 주방과 오픈 플랜 주방은 생활, 요리, 식사를 결합한 주방 유형입니다. 요리하는 동안 사교와 소통이 모든 사람에게 보이지 않는 주방 기구와 중고 조리도구보다 더 중요하다면 식사 주방은 실용적이고 현대적인 주방 솔루션입니다.",
                image: "/images/ideas/kitchen/overview/kitchenware-2.webp",
                link: "/",
            },
            {
                name: "주방 설비",
                text: "평화롭고 조용하게 요리하는 것을 선호한다면, 맞춤형 주방이 이상적인 솔루션입니다. 분리되어 있고 독립되어 있습니다. 클래식 주방과 달리, 맞춤형 주방은 주방 공간에 맞게 맞춤 제작되어 방을 가득 채우는 주방 정면을 만듭니다. 여기서는 편의성, 보관 공간 및 최적의 공간 활용에 중점을 둡니다.",
                image: "/images/ideas/kitchen/overview/kitchenware-3.webp",
            },
            {
                name: "클래식 주방",
                text: "클래식 주방은 주방 유형이라기보다는 스타일에 가깝습니다. 매끄럽거나 약간 질감이 있는 단순한 전면이 이 주방의 그림을 지배합니다. 목재 사용과 조화로운 재료의 혼합은 이 주방의 전형입니다. 클래식 주방은 시대를 초월한 우아함과 높은 수준의 기능성을 결합합니다.",
                image: "/images/ideas/kitchen/overview/kitchenware-4.webp",
            },
        ],
    }),
};
</script>