var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/bathroom/planning/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Bathroom design tips from Villeroy & Boch"
    }
  }), _c('page-section', {
    staticClass: "pt-40px pt-md-80px"
  }, [_c('v-container', {
    staticClass: "mt-50px mt-md-100px"
  }, [_c('v-row', {
    staticClass: "row--lg text-center",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("개인이 기분 좋은 욕실로 가는 길에 가능한 최고의 지원을 제공하기 위해 Villeroy & Boch는 욕실 계획에 관한 귀중한 팁을 모았습니다. 전문가와 함께 욕실을 계획하고 싶으신가요?")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', {
    staticClass: "position-relative pb-12px py-md-72px"
  }, [_c('v-sheet', {
    staticClass: "section-bg"
  }), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "order": "1",
      "order-md": "2"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/planning/woman-with-drink-in-bathtub.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "order": "2",
      "order-md": "1"
    }
  }, [_c('v-card', {
    staticClass: "h-md-400px d-md-flex align-center",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("영감에서 실현까지")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_c('p', [_vm._v("Villeroy & Boch의 고품질 제품은 다양한 디자인 옵션을 제공합니다. 기능성과 디자인은 항상 신중한 방식으로 서로 조화를 이룹니다. 다양한 객실 아이디어에서 영감을 얻고 독점적인 욕실 트렌드, 효과적인 조명 컨셉, 맞춤형 웰니스 솔루션을 만나보세요.")]), _c('p', [_vm._v("꿈의 욕실을 계획할 때 개인적인 바람과 요구 사항은 공간 조건만큼 중요한 역할을 합니다. 혁신적인 온라인 욕실 플래너를 사용하고 미래의 욕실을 사실적으로 디자인하여 아이디어와 아이디어를 생생하게 채워보세요. 귀하 지역의 수많은 전문 소매점에서도 귀하가 좋아하는 제품을 직접 체험할 수 있는 기회를 제공하고 전문적인 지원을 제공해 드릴 수 있습니다.")])])])])], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', {
    staticClass: "position-relative pb-12px py-md-72px"
  }, [_c('v-sheet', {
    staticClass: "section-bg section-bg--secondary"
  }), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/planning/architectura-bathtub-with-hand-shower-and-toilet.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-card', {
    staticClass: "h-md-400px d-md-flex align-center",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("새 욕실을 구입하는 데 실제로 드는 비용은 얼마입니까?")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실을 개조하거나 개조할 계획이고 예상되는 비용을 미리 알고 싶으십니까? 전문 장인이 욕실 수리 작업과 비용을 전형적인 사례를 통해 정리했습니다. 또한 귀하가 활용할 수 있는 자금 조달 기회와 세금 절감에 대한 팁도 제공합니다.")])])])], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item.title,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('div', [item.image ? [_c('v-img', {
      staticClass: "mb-18px mb-md-24px",
      attrs: {
        "src": item.image
      }
    })] : _vm._e(), _c('div', {
      staticClass: "tit tit--xs mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])], 2)])];
  })], 2)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px my-20px my-md-40px"
  }, [_c('v-container', [_c('div', {
    staticClass: "product-section-title tit-wrap tit-wrap--lg"
  }, [_c('div', {
    staticClass: "tit grey lighten-5"
  }, [_vm._v("고품질 보급형 컬렉션 또는 고급스러운 프리미엄 장비?")])]), _c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('p', [_vm._v("욕실 개조 또는 재설계의 범위와 기존 객실 상황에 따라 비용이 매우 달라집니다. 설치 및 위생 기술 재설치를 포함하여 전체 개조를 수행하려면 필요한 자재 및 인건비에 대한 계획을 세워야 합니다.")]), _c('p', [_vm._v("귀하가 꿈꾸는 욕실을 꾸밀 수 있도록 Villeroy & Boch는 각각 고유한 스타일과 다양한 가구 요구 사항을 충족하는 다양한 컬렉션을 제공합니다. 고품질의 보급형 컬렉션부터 독점적인 디자인 컬렉션, 럭셔리한 프리미엄 컬렉션까지 모든 예산에 맞는 제품이 있습니다.")])])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }