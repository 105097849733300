var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/kitchen/cleaning/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Kitchen Cleaning"
    }
  }), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px my-40px my-md-80px"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg text-center",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("일반 관리 정보")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("반짝반짝 빛나는 새 주방으로 집에 돌아오는 것을 누가 싫어하겠습니까? 가능한 한 오랫동안 모든 것을 새 것처럼 유지하는 데 많은 시간이 걸리지 않습니다. Villeroy & Boch의 주방 컬렉션은 특히 쉽게 만들어줍니다. 이 제품은 세척과 관리를 쉽고 많은 노력 없이 거의 지나가는 것처럼 수행할 수 있도록 개발되었습니다. 간단한 가정용 제품만으로도 충분합니다. 이는 환경에도 좋고 여러분에게도 확실히 좋습니다. 따라서 예를 들어 편안한 목욕으로 시간을 최대한 활용할 수 있습니다. 이 메뉴 항목에서 주방을 오랫동안 새 것처럼 유지하는 데 도움이 되는 많은 정보를 찾을 수 있습니다. 또한 직원들의 수년간의 컨설팅 경험에서 얻은 몇 가지 추가 팁과 연구의 새로운 결과도 있습니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('cleaning-images')], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px my-20px my-md-40px"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("일상 관리")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 매일 관리 Villeroy & Boch 주방 세라믹은 모든 일반 가정용 산과 알칼리에 강한 매끄럽고 조밀하며 거의 긁히지 않는 표면을 가지고 있습니다. 매일 관리하려면 종종 깨끗한 물과 축축한 천만 있으면 됩니다. 물 자국, 기름 및 비누 잔여물은 욕조 또는 식초 세척제로 쉽게 제거할 수 있습니다. \"화학 물질\"을 전혀 피하고 싶다면 가정용 식초나 희석된 식초 에센스를 사용하세요. 이렇게 하면 석회질이 빠르게 제거됩니다. 얼룩이 조금 더 완고하다면 식초에 담근 가정용 천을 밤새도록 두어 제거할 수 있습니다. 전문 소매점에서 가벼운 석회질 제거제 또는 식초 세척제도 구입할 수 있으며 제조업체의 권장 사항에 따라 사용할 수 있습니다. 녹 제거제 및 치약에 들어 있는 것과 같이 불소 함량이 높은 모든 물질에는 주의가 필요합니다. 장시간 노출되면 얼룩이 생길 수 있습니다. 따라서 잔여물은 즉시 제거하세요! 더 심하게 더러워진 경우 액체 또는 크림 형태의 비연마성 욕실 세척제를 권장합니다. 부드럽고 긁히지 않는 스펀지를 사용하면 제품의 효과가 더욱 향상됩니다. ")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("중요사항")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_c('p', [_vm._v("제조업체의 사용 권장 사항을 주의 깊게 읽어야 합니다. 의심되는 경우 세척 및 관리 제품을 보이지 않는 부분에서 테스트할 수 있습니다. 세라믹 표면을 긁을 수 있고 Ceramicplus의 뛰어난 특성을 손상시키거나 지속적으로 사용하면 파괴할 수 있는 매우 공격적인 세척제는 반드시 피해야 합니다. 예:")]), _c('ul', {
    staticClass: "caution-list"
  }, [_c('li', [_vm._v("세척용 밀크 또는 세척용 파우더")]), _c('li', [_vm._v("고농축 배수구 세척제")]), _c('li', [_vm._v("밸브 및 스틸 클리너")]), _c('li', [_vm._v("분말형 WC 세척제")])]), _c('p', [_vm._v("이러한 제품의 사용을 전적으로 피하는 것이 좋으며, 하수구 세척제, 수도꼭지 세척제 또는 강철 세척제의 경우 Kermaik에 직접 닿지 않도록 주의하세요.")])])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "12",
        "lg": "4"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "color": "transparent",
        "to": item.link
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "tit tit--xxs mt-12px mt-md-16px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v("자세히 알아보기")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)], 1)], 1)];
  })], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }