<template>
    <v-sheet max-width="60%" class="artis-slide">
        <swiper class="swiper" :options="options">
            <template v-for="item in items">
                <swiper-slide :key="item">
                    <v-card max-width="700" :img="`/images/ideas/bathroom/colours/${item}`" class="mx-auto">
                        <v-responsive :aspect-ratio="700 / 467"></v-responsive>
                    </v-card>
                </swiper-slide>
            </template>
            <div slot="pagination">
                <div class="swiper-pagination" />
            </div>
        </swiper>
        <v-btn icon outlined class="swiper-button-prev v-size--xx-large">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn icon outlined class="swiper-button-next v-size--xx-large">
            <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
    </v-sheet>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data: () => ({
        options: {
            allowTouchMove: true,
            centeredSlides: false,
            speed: 500,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        },

        items: [
            "artis-1.webp",
            "artis-2.webp",
            "artis-3.webp",
            "artis-4.webp",
            "artis-5.webp",
            "artis-6.webp",
            "artis-7.webp",
            "artis-8.webp",
            "artis-9.webp",
            "artis-10.webp",
            //
        ],
    }),
};
</script>

<style lang="scss" scoped>
.artis-slide {
    position: relative;
    margin: 0 auto;
}
.swiper-button-prev,
.swiper-button-next {
    margin: initial;
    border-radius: 50%;
    background-color: white;
    &::before {
        display: none;
    }

    &:hover {
        border-color: var(--v-primary-base);
        .v-icon {
            color: var(--v-primary-base);
        }
    }
}
.swiper-button-prev {
    left: -40px !important;
    transform: translateX(-100%);
}
.swiper-button-next {
    right: -40px !important;
    transform: translateX(100%);
}
.swiper-pagination {
    justify-content: center;
    position: static;
    margin: 0;
    ::v-deep {
        .swiper-pagination-bullet {
            width: 10px !important;
            height: 10px !important;
            background-color: var(--v-grey-lighten3) !important;
            border: none;
            &-active {
                background-color: var(--v-primary-base) !important;
            }
        }
    }
}
</style>