var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/bathroom/lighting/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Bathroom lighting"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("기분 좋고, 편안하고, 아침저녁 바디케어 - 욕실은 이용빈도가 높은 공간입니다. 모든 일상 상황에서 조명이 잘 들어오는 것이 더욱 중요합니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_vm._l(_vm.navigation, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('router-link', {
      staticClass: "txt txt--sm d-flex align-center",
      attrs: {
        "to": `#${index + 1}`
      }
    }, [_c('v-img', {
      staticClass: "mr-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-down.svg"
      }
    }), _vm._v(" " + _vm._s(item) + " ")], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('dot-list', [_vm._l(_vm.overview, function (item) {
    return [_c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "1"
    }
  }, [_c('v-container', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("화장실에는 어떤 조명이 있나요?")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실 조명 아이디어를 찾고 계십니까? Villeroy & Boch 컬렉션에서는 욕실을 선보일 수 있는 다양한 옵션을 찾을 수 있습니다. 거울 램프, 선반의 간접 조명 요소, 조명 스트립을 조화롭게 배치하여 분위기 있는 액센트를 연출하세요. 욕실의 완벽한 조명을 위해 이러한 요소를 천장 램프와 결합하세요. 여기에서 아이디어를 찾을 수 있습니다.")]), _c('three-images', {
    attrs: {
      "path": _vm.path,
      "first": "collaro-washbasin-in-lighted-bathroom-with-bathtub-and-shelves.webp",
      "second": "finion-hanging-rail-white.webp",
      "third": "finion-bathroom-furniture-with-lighting-and-washbasin.webp"
    }
  })], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실의 기본 조명")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 처음에는 방의 기본 조명에 초점을 맞춰야 합니다. 욕실 조명은 일반적으로 천장에서 설계됩니다. 천장등은 넓은 면적을 고르게 비춰주고, 쉽고 빠르게 빛을 제공할 수 있는 장점이 있습니다. 이는 욕실에서의 사고 위험을 최소화합니다. 기본 조명은 이에 따라 넓은 면적에 걸쳐 설계되었습니다. 수많은 매립형 조명이나 스포트라이트는 욕실 전체에 실용적인 기본 조명을 제공합니다. 방이 클수록 하나 또는 두 개의 스위치만으로 켜고 끌 수 있는 균일한 기본 조명을 갖는 것이 더 중요합니다. ")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/artis-washbasin-white-with-conum-tap-and-finion-bath.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실의 개별 구역 조명")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 구역 조명은 기본 조명을 완벽하게 보완합니다. 이러한 유형의 LED 욕실 조명은 특정 지점에 타겟 조명을 제공합니다. 여기에는 싱크대의 거울 조명이나 샤워기나 욕조에 특별히 배치된 지점이 포함될 수 있습니다. 욕실에 세탁기와 건조기가 있는 세탁 공간이 있는 경우 여기에 타겟 조명을 사용할 수도 있습니다. "), _c('br'), _vm._v(" 다음 욕실 조명 팁은 구역 조명을 목표한 방식으로 사용하는 데 도움이 됩니다. 개별 조명 요소가 해당 구역에 맞게 조정되었는지 확인하십시오. 구역 조명은 좋은 조명만 제공해야 합니까, 아니면 분위기 있고 휴식에도 기여해야 합니까? 목적에 따라 조명 유형을 선택하십시오. ")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "order-md": "2"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/my-view-mirror-with-light-opened.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("천장, 벽 또는 거울 조명?")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 욕실용 대면적 천장 조명은 여전히 ​​고전적입니다. 욕실에서 시선을 사로잡을 간단한 천장 조명, 많은 스포트라이트 또는 대형 펜던트 조명 중에서 선택하세요. 또한 추가 조명 요소를 반드시 통합해야 합니다. 벽 조명은 특히 대형 욕실에서 개별 공간을 밝히는 데 이상적인 추가 장치입니다. 또한 디자인 요소로도 작용합니다. "), _c('br'), _vm._v(" 거울 조명은 여러 가지 용도로 사용됩니다. 빌레로이 앤 보흐 My View Now 거울 의 측면 조명은 면도, 메이크업 또는 피부 관리 시 얼굴을 완벽하게 비춥니다. 거울 조명은 거울 캐비닛의 내용물을 강조하고, 싱크대를 밝히며, Zigbee Home Automation을 통해 스마트 홈 네트워크에 연결할 수 있습니다. ")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/finion-bathtub-white-in-modern-grey-bathroom-with-lighting.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("더 많은 조화를 위한 작은 조명 액센트")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 악센트 설정: Villeroy & Boch Finion 컬렉션 의 간접 조명 선반 , 조명 욕조 및 방 전체에 분산된 작은 광원. 욕실의 클래식 LED 조명에 추가로 방수 기능도 갖춘 특수 LED 양초가 추가되었습니다. 욕조 가장자리에 딱 맞으며 편안하고 기분 좋은 분위기를 연출합니다. "), _c('br'), _vm._v(" 이러한 조명 액센트의 장점은 분명합니다. 현대적인 욕실 조명은 다양성을 바탕으로 발전합니다. 다양한 조명 요소를 결합하고 시간과 요구 사항에 따라 사용하면 전체적으로 조화로운 그림이 탄생합니다. 조명 액센트와 결합된 구역 조명으로 충분하므로 저녁에 목욕할 때 기본 조명을 꺼둔 채로 둘 수 있습니다. ")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey",
    attrs: {
      "id": "2"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실 조명 – 고려해야 할 사항")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 물론 욕실 조명은 주로 방을 고르게 비추고 모든 것을 볼 수 있도록 도와야 합니다. 동시에 현대적인 욕실 조명은 다양한 요구 사항을 충족해야 합니다. 이는 특히 사고 예방에 관한 것이므로 젖은 욕실에 충분한 조명이 필수적입니다. "), _c('br'), _vm._v(" 기본적으로 특히 조명이 필요한 모든 영역을 먼저 표시하는 욕실 조명 계획을 세우는 것이 합리적입니다. 이는 특히 싱크대 주변 영역일 뿐만 아니라 샤워실 및 세탁기와 건조기가 있는 틈새일 수도 있습니다. 어느 지역에 얼마나 많은 빛이 필요한지 계획한 다음 욕실에 딱 맞는 조명을 디자인하세요. ")]), _c('expansion-panels', {
    attrs: {
      "data": _vm.points
    }
  })], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "3"
    }
  }, [_c('v-container', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("최적의 조명으로 완벽한 기분 좋은 분위기")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실의 편안한 분위기를 조성하는 데에는 여러 가지 측면이 있습니다. 욕실 조명에 대한 팁을 활용하면 완벽한 기분 좋은 분위기를 조성할 수 있습니다. 여기에는 밝은 색상, 적합한 램프 및 간접 조명 요소가 포함됩니다. 빌레로이앤보흐 조명으로 스타일에 매료되세요.")]), _c('three-images', {
    attrs: {
      "path": _vm.path,
      "first": "antao-washbasin-almond-with-mirror-and-vanity-unit.webp",
      "second": "pic_L4801200_S.png",
      "third": "collaro-washbasin-white-with-gold-features.webp"
    }
  })], 1)], 1), _c('page-section', [_c('v-container', [_c('section-slide', {
    attrs: {
      "path": _vm.path,
      "items": _vm.slides
    }
  })], 1)], 1), _c('page-section', {
    attrs: {
      "id": "4"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/avento-washbasin-white-with-mirror-and-vanity-unit.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("적합한 욕실 램프 찾기 ")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("우아하고 현대적이며 동시에 안전한 욕실 조명은 기분 좋은 분위기를 조성합니다. 모든 상황에 적합한 조명을 제공하려면 다양한 조명 요소를 선택하세요. LED는 비교할 수 없을 정도로 다양한 색온도, 색상 및 밝기를 제공합니다. 밝은 거울 조명은 아침에 상쾌하고 깨어나는 기분을 느끼게 해줍니다.")])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }