<template>
    <page-section>
        <v-container>
            <div class="tit-wrap tit-wrap--lg text-center">
                <div class="tit">관심 가질만한 내용</div>
            </div>
            <v-row class="row--lg">
                <template v-for="(item, index) in items">
                    <v-col cols="12" lg="4" :key="index">
                        <v-card tile flat :to="item.link">
                            <v-img :src="item.image" />
                            <div class="tit tit--xxs mt-12px mt-md-16px">
                                {{ item.title }}
                            </div>
                            <div v-if="item.link" class="mt-12px mt-md-16px d-flex align-center">
                                <span class="txt txt--xs txt--dark font-weight-medium">자세히 알아보기</span>
                                <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                            </div>
                        </v-card>
                    </v-col>
                </template>
            </v-row>
        </v-container>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";
export default {
    components: {
        PageSection,
    },
    data: () => ({
        items: [
            {
                title: "욕실을 위한 창의적인 장식 아이디어",
                image: "/images/ideas/bathroom/trends/interested-1.webp",
                // link: "/",
            },
            {
                title: "오픈 플랜 주방을 위한 아이디어",
                image: "/images/ideas/bathroom/trends/interested-2.webp",
                // link: "/",
            },
            {
                title: "최신 식기 트렌드",
                image: "/images/ideas/bathroom/trends/interested-3.webp",
                // link: "/",
            },
        ]
    }),
};
</script>