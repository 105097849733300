var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/bathroom/features/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Bathroom features"
    }
  }), _c('page-section', {
    staticClass: "py-40px py-md-80px my-40px my-md-80px"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm mb-12px mb-md-16px"
  }, [_vm._v("Villeroy & Boch의 욕실 기능")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실 도자기는 상대적으로 정적인 반면, 특별한 욕실 ​​기능을 통해 기능적인 욕실을 웰빙의 오아시스로 바꿀 수 있습니다. 물론 디자인도 중요한 역할을 하지만 외모만 중요한 것은 아닙니다. 특별한 욕실 ​​도구는 프로세스를 단순화하고 일상 생활을 지원하는 데 도움이 됩니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "color": "transparent",
      "max-width": "940"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("화장실 수세식")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("고품질 변기 외에도 혁신적인 변기 수세기는 욕실의 유용한 기능입니다. 욕실 화장실 장비는 현대 기술과 결합되어 화장실 청소 및 유지 관리가 더 쉬워졌습니다.")])]), _c('v-row', {
    staticClass: "row--xxl",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-card', {
      staticClass: "mx-auto",
      attrs: {
        "max-width": "776",
        "color": "transparent",
        "tile": "",
        "flat": ""
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "px-12px px-md-16px"
    }, [_c('div', {
      staticClass: "tit tit--xs grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])])], 1)], 1)];
  })], 2)], 1)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px my-20px my-md-40px"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("화장실 조립 혁신")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("점점 더 많은 사람들이 욕실을 스스로 개조하기로 결정하고 있습니다. 이러한 개발을 통해 배관 시스템을 설치하는 혁신적인 방법이 점점 더 많아지고 DIY 사용자도 이를 수행할 수 있게 되었습니다. 그러나 전문가들은 작업을 더 쉽고 짧게 만들어주는 간단한 조립 시스템의 이점도 누릴 수 있습니다.")])])])], 1), _c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "color": "transparent",
      "max-width": "940"
    }
  }, [_c('v-row', {
    staticClass: "row--xxl",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "mb-12px mb-md-16px",
    attrs: {
      "src": "/images/ideas/bathroom/features/suprafix-technology-with-toilet.webp"
    }
  }), _c('div', {
    staticClass: "px-12px px-md-16px"
  }, [_c('div', {
    staticClass: "tit tit--xs grey--text text--darken-3 mb-6px mb-md-8px"
  }, [_vm._v("수프라픽스 3.0")]), _c('p', {
    staticClass: "txt txt--sm grey--text text--darken-3"
  }, [_vm._v("욕실에 벽걸이형 변기를 설치하고 싶으신가요? 새로운 SupraFix 3.0 부착 장치를 사용하면 이 작업을 매우 짧은 시간에 완료할 수 있습니다. 벽면 브래킷을 부착하고 변기를 씌운 다음 Vario 너트를 끼우고 그 위에 시트를 놓으면 완료됩니다.")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "mb-12px mb-md-16px",
    attrs: {
      "src": "/images/ideas/bathroom/features/toilet-seat-gets-attached-to-toilet+(1).webp"
    }
  }), _c('div', {
    staticClass: "px-12px px-md-16px"
  }, [_c('div', {
    staticClass: "tit tit--xs grey--text text--darken-3 mb-6px mb-md-8px"
  }, [_vm._v("퀵 릴리스")]), _c('p', {
    staticClass: "txt txt--sm grey--text text--darken-3"
  }, [_vm._v("최신 QuickRelease 부착물은 변기 설치를 단순화할 뿐만 아니라 이 시스템은 또한 시트 아래와 뒤의 접근하기 어려운 부분을 쉽게 청소할 수 있도록 지원합니다. 단 한 번의 동작으로 변기 시트를 제거하고 청소 후 다시 장착할 수 있습니다.")])])], 1)], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("메탈림 기술")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실은 단순한 화장실이 아닙니다. 샤워에는 혁신적인 MetalRim 기술과 같은 특별한 기능도 포함되어 있습니다. 샤워기 가장자리 부분에 아연도금 강철 보강재를 사용하여 소재를 특히 안정적으로 만듭니다. 이를 통해 욕실에 통합될 수 있는 평면 디자인이 가능해졌습니다.")])])], 1)], 1)], 1), _c('features-metalrim'), _c('page-section', [_c('v-container', {
    staticClass: "text-center"
  }, [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("ViFlow")]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("배수구와 오버플로는 단순히 욕조와 싱크대의 일부입니다. 각 욕실 도자기와 눈에 띄지 않고 우아하게 조화를 이루어야 합니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.viflow, function (item) {
    return [_c('v-col', {
      key: item.title,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.image,
        "aspect-ratio": 1 / 1
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('v-sheet', {
      staticClass: "d-flex align-center pa-20px",
      attrs: {
        "color": "grey lighten-5",
        "height": "100%"
      }
    }, [_c('div', [_c('div', {
      staticClass: "tit tit--sm mb-10px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])])])], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('page-section', [_c('v-container', {
    staticClass: "position-relative pb-12px py-md-72px"
  }, [_c('v-sheet', {
    staticClass: "section-bg section-bg--secondary"
  }), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/features/vifresh-technology-rim-block-filling.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-card', {
    staticClass: "h-md-400px d-md-flex align-center",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("비프레쉬")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("화장실 주변의 위생을 더 원하시나요? 그렇다면 Villeroy & Boch의 최신 위생 솔루션을 사용해 보세요. ViFresh는 혁신적이며 욕실에 독특하고 신선한 향기를 선사합니다. 표준 변기 블록으로 변기에 통합된 칸막이를 채웁니다.")])])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }