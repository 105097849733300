<template>
    <page-section class="grey lighten-5 my-20px my-md-40px py-40px py-md-80px">
        <v-container>
            <div class="tit-wrap tit-wrap--lg text-center">
                <h2 class="tit mb-12px mb-md-16px">욕실 제품 살펴보기</h2>
                <p class="txt txt--sm">
                    시대를 초월한 우아함, 혁신적인 디자인, 뛰어난 품질: <br />
                    스타일리쉬한 세면기부터 웰니스 욕조까지 다양한 제품군을 살펴보고 귀하의 집에 딱 맞는 기분 좋은 제품을 찾아보세요.
                </p>
            </div>
            <v-sheet color="transparent" max-width="940" class="mx-auto">
                <v-row justify="center" align="center" class="row--xxl">
                    <template v-for="item in items">
                        <v-col :cols="item.cols" :key="item">
                            <v-card max-width="776" color="transparent" tile flat :href="item.link" class="mx-auto">
                                <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                <div class="px-12px px-md-16px">
                                    <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                        {{ item.title }}
                                    </div>
                                    <p class="txt txt--sm grey--text text--darken-3">
                                        {{ item.text }}
                                    </p>
                                    <template v-if="item.link">
                                        <div class="mt-12px mt-md-16px d-flex align-center">
                                            <span class="txt txt--xs txt--dark font-weight-medium">모든 {{ item.title }} 보러 가기</span>
                                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                        </div>
                                    </template>
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-sheet>
        </v-container>
    </page-section>
</template>

<script>
import pageSection from "@/components/client/templates/page-section.vue";
export default {
    components: { pageSection },
    data: () => ({
        items: [
            {
                title: "세면기",
                text: "세련된 세면기로 욕실에 우아하고 기능적인 하이라이트를 더해보세요.",
                image: "/images/ideas/bathroom/category-1.webp",
                link: "/shop/products?category=bathroom_sink",
                cols: "6",
            },
            {
                title: "거울",
                text: "우리 거울은 우아한 시각적 액센트를 줄 뿐만 아니라 가벼운 거울로서 일상 관리에 완벽한 조명을 제공합니다.",
                image: "/images/ideas/bathroom/category-2.webp",
                link: "/shop/products?category=bathroom_mirror",
                cols: "6",
            },
            {
                title: "욕실 수전",
                text: "욕조, 샤워실, 세면기 등 저희의 수전은 항상 형태와 기능이 완벽하게 조화를 이룹니다.",
                image: "/images/ideas/bathroom/category-3.webp",
                link: "/shop/products?category=bathroom_fittings",
                cols: "12",
            },
            {
                title: "화장실과 양변기",
                text: "현대적인 고품질 화장실과 양변기는 최고 수준의 편안함과 위생을 제공합니다.",
                image: "/images/ideas/bathroom/category-4.webp",
                link: "/shop/products?category=toilet_seat_and_Toilet_lid",
                cols: "6",
            },
            {
                title: "비데",
                text: "우아한 비데는 욕실에 위생을 더하고 고급스러운 느낌을 더해줍니다.",
                image: "/images/ideas/bathroom/category-5.png",
                link: "/shop/products?category=Bidets",
                cols: "6",
            },
            {
                title: "샤워 트레이",
                text: "실용적이고 세련된 샤워 트레이는 일상적인 샤워 경험에 이상적인 환경을 제공합니다.",
                image: "/images/ideas/bathroom/category-6.webp",
                // link: "/",
                cols: "6",
            },
            {
                title: "욕조",
                text: "벽 앞이든 독립형이든 상관없이 편안하고 우아한 욕조를 통해 순수한 휴식에 빠져들 수 있습니다.",
                image: "/images/ideas/bathroom/category-7.webp",
                link: "/shop/products?category=bathtub",
                cols: "6",
            },
            {
                title: "욕실 가구",
                text: "저희 욕실 가구는 욕실에 통일감, 다양성, 그리고 세련된 스타일을 더해줍니다. 익숙한 높은 품질로 제작되었으며, 항상 저희 욕실 도기와 완벽하게 조화를 이룹니다.",
                image: "/images/ideas/bathroom/category-8.webp",
                link: "/shop/products?category=Washbasin_base_cabinets",
                cols: "12",
            },
        ],
    }),
};
</script>