var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "position-relative"
  }, [_c('v-img', {
    staticClass: "cleaning-image--1",
    attrs: {
      "src": "/images/ideas/kitchen/cleaning/cleaning-1.webp"
    }
  }), _c('v-img', {
    staticClass: "cleaning-image--2",
    attrs: {
      "src": "/images/ideas/kitchen/cleaning/cleaning-2.webp"
    }
  }), _c('v-img', {
    staticClass: "cleaning-image--3",
    attrs: {
      "src": "/images/ideas/kitchen/cleaning/cleaning-3.webp"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }