var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-container', [_c('v-sheet', {
    staticClass: "section-bg section-bg--secondary"
  }), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "full-image-wrap"
  }, [_c('v-img', {
    staticClass: "d-md-none",
    attrs: {
      "src": "/images/ideas/bathroom/features/metalrim.webp"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', [_c('h2', {
    staticClass: "tit font-tertiary font-weight-regular mb-14px mb-md-16px"
  }, [_vm._v("무테 샤워 바닥")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Architectura MetalRim 컬렉션의 매우 평평한 샤워 바닥은 모든 욕실 디자인에 매끄럽고 조화롭게 어울립니다. 완벽한 욕실을 위해 다양한 치수 중에서 선택하세요.")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }