var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.options
    }
  }, [_vm._l(_vm.images, function (image) {
    return [_c('swiper-slide', {
      key: image
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "img": image
      }
    }, [_c('v-responsive', {
      staticClass: "d-md-none",
      attrs: {
        "aspect-ratio": 4 / 3
      }
    }), _c('v-responsive', {
      staticClass: "d-none d-md-block",
      attrs: {
        "aspect-ratio": 5 / 2
      }
    })], 1)], 1)];
  }), _c('div', {
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-button-prev"
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('div', {
    staticClass: "swiper-button-next"
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }