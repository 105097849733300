<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/kitchen/overview/visual.webp" :breadcrumbs="breadcrumbs" title="Kitchen Overview" />

        <page-section class="pt-40px pt-md-80px">
            <v-container class="mt-50px mt-md-100px">
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">다양한 주방 스타일 외에도 다양한 유형과 모양의 주방도 구별됩니다. 우리는 그것들이 무엇이라고 불리는지, 그리고 그것들이 당신에게 어떤 이점을 제공하는지 말해줍니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <overview-types />

        <overview-cleaning />
    </client-page>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import OverviewTypes from "@/components/client/ideas/kitchen/overview/overview-types.vue";
import OverviewCleaning from '@/components/client/ideas/kitchen/overview/overview-cleaning.vue';

export default {
    components: {
        Swiper,
        SwiperSlide,
        ClientPage,
        PageSection,
        ShopProductVisual,
        OverviewTypes,
        OverviewCleaning,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "주방에 대한 영감",
                href: "/ideas/bathroom/overview",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>