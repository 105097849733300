<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/kitchen/material/visual.webp" :breadcrumbs="breadcrumbs" title="Materials in The Kitchen" />

        <page-section class="py-40px py-md-80px my-40px my-md-80px">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">그릇, 칼, 나무 도마, 세라믹 싱크대를 깨끗하게 유지하기 위한 유용한 팁과 가이드를 통해 주방 청소는 간단하고 효율적인 프로세스가 됩니다. 주방을 위생적이고 반짝반짝하게 유지하는 데 도움이 되는 입증된 방법을 발견하여 주방에서 항상 편안하고 건강하게 느낄 수 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap">
                    <div class="tit">주방 소재</div>
                </div>
                <v-row class="row--lg">
                    <template v-for="item in items">
                        <v-col cols="12" md="6" :key="item">
                            <v-card tile flat :to="item.link">
                                <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                <div class="tit tit--xs mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm">
                                    {{ item.text }}
                                </p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">자세히 알아보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "주방 소재",
                href: "/ideas/kitchen/material",
            },
        ],
        items: [
            {
                title: "빌레로이 세라믹의 장점",
                text: "위생적이고, 청소가 쉬우며, 견고하고 다재다능합니다. 세라믹 싱크대의 장점을 알아보세요.",
                image: "/images/ideas/kitchen/material/titanceram.webp",
                link: "/ideas/kitchen/material/advantages",
            },
            {
                title: "세라믹플러스",
                text: "주방 싱크대를 위한 CeramicPlus의 혁신성과 내구성을 확인해 보세요.",
                image: "/images/ideas/kitchen/material/ceramicplus.webp",
                link: "/ideas/kitchen/material/ceramicplus",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>