<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/lighting/visual.webp" :breadcrumbs="breadcrumbs" title="Bathroom lighting" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">기분 좋고, 편안하고, 아침저녁 바디케어 - 욕실은 이용빈도가 높은 공간입니다. 모든 일상 상황에서 조명이 잘 들어오는 것이 더욱 중요합니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <template v-for="(item, index) in navigation">
                                <v-col cols="12" :key="index">
                                    <router-link :to="`#${index + 1}`" class="txt txt--sm d-flex align-center">
                                        <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                        {{ item }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <dot-list>
                                <template v-for="item in overview">
                                    <li :key="item">
                                        {{ item }}
                                    </li>
                                </template>
                            </dot-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="1">
            <v-container class="text-center">
                <div class="tit mb-12px mb-md-16px">화장실에는 어떤 조명이 있나요?</div>
                <p class="txt txt--sm">욕실 조명 아이디어를 찾고 계십니까? Villeroy & Boch 컬렉션에서는 욕실을 선보일 수 있는 다양한 옵션을 찾을 수 있습니다. 거울 램프, 선반의 간접 조명 요소, 조명 스트립을 조화롭게 배치하여 분위기 있는 액센트를 연출하세요. 욕실의 완벽한 조명을 위해 이러한 요소를 천장 램프와 결합하세요. 여기에서 아이디어를 찾을 수 있습니다.</p>
                <three-images :path="path" first="collaro-washbasin-in-lighted-bathroom-with-bathtub-and-shelves.webp" second="finion-hanging-rail-white.webp" third="finion-bathroom-furniture-with-lighting-and-washbasin.webp" />
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">욕실의 기본 조명</div>
                        <p class="txt txt--sm">
                            처음에는 방의 기본 조명에 초점을 맞춰야 합니다. 욕실 조명은 일반적으로 천장에서 설계됩니다. 천장등은 넓은 면적을 고르게 비춰주고, 쉽고 빠르게 빛을 제공할 수 있는 장점이 있습니다. 이는 욕실에서의 사고 위험을 최소화합니다. 기본 조명은 이에 따라 넓은 면적에 걸쳐 설계되었습니다. 수많은 매립형 조명이나 스포트라이트는 욕실 전체에 실용적인 기본 조명을 제공합니다. 방이 클수록 하나 또는 두 개의 스위치만으로 켜고 끌 수 있는 균일한 기본 조명을 갖는 것이 더 중요합니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/artis-washbasin-white-with-conum-tap-and-finion-bath.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">욕실의 개별 구역 조명</div>
                        <p class="txt txt--sm">
                            구역 조명은 기본 조명을 완벽하게 보완합니다. 이러한 유형의 LED 욕실 조명은 특정 지점에 타겟 조명을 제공합니다. 여기에는 싱크대의 거울 조명이나 샤워기나 욕조에 특별히 배치된 지점이 포함될 수 있습니다. 욕실에 세탁기와 건조기가 있는 세탁 공간이 있는 경우 여기에 타겟 조명을 사용할 수도 있습니다. <br />
                            다음 욕실 조명 팁은 구역 조명을 목표한 방식으로 사용하는 데 도움이 됩니다. 개별 조명 요소가 해당 구역에 맞게 조정되었는지 확인하십시오. 구역 조명은 좋은 조명만 제공해야 합니까, 아니면 분위기 있고 휴식에도 기여해야 합니까? 목적에 따라 조명 유형을 선택하십시오.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7" order-md="2">
                        <v-img :src="`${path}/my-view-mirror-with-light-opened.webp`" />
                    </v-col>
                    <v-col cols="12" md="5" order-md="1">
                        <div class="tit mb-12px mb-md-16px">천장, 벽 또는 거울 조명?</div>
                        <p class="txt txt--sm">
                            욕실용 대면적 천장 조명은 여전히 ​​고전적입니다. 욕실에서 시선을 사로잡을 간단한 천장 조명, 많은 스포트라이트 또는 대형 펜던트 조명 중에서 선택하세요. 또한 추가 조명 요소를 반드시 통합해야 합니다. 벽 조명은 특히 대형 욕실에서 개별 공간을 밝히는 데 이상적인 추가 장치입니다. 또한 디자인 요소로도 작용합니다. <br />
                            거울 조명은 여러 가지 용도로 사용됩니다. 빌레로이 앤 보흐 My View Now 거울 의 측면 조명은 면도, 메이크업 또는 피부 관리 시 얼굴을 완벽하게 비춥니다. 거울 조명은 거울 캐비닛의 내용물을 강조하고, 싱크대를 밝히며, Zigbee Home Automation을 통해 스마트 홈 네트워크에 연결할 수 있습니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/finion-bathtub-white-in-modern-grey-bathroom-with-lighting.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">더 많은 조화를 위한 작은 조명 액센트</div>
                        <p class="txt txt--sm">
                            악센트 설정: Villeroy & Boch Finion 컬렉션 의 간접 조명 선반 , 조명 욕조 및 방 전체에 분산된 작은 광원. 욕실의 클래식 LED 조명에 추가로 방수 기능도 갖춘 특수 LED 양초가 추가되었습니다. 욕조 가장자리에 딱 맞으며 편안하고 기분 좋은 분위기를 연출합니다. <br />
                            이러한 조명 액센트의 장점은 분명합니다. 현대적인 욕실 조명은 다양성을 바탕으로 발전합니다. 다양한 조명 요소를 결합하고 시간과 요구 사항에 따라 사용하면 전체적으로 조화로운 그림이 탄생합니다. 조명 액센트와 결합된 구역 조명으로 충분하므로 저녁에 목욕할 때 기본 조명을 꺼둔 채로 둘 수 있습니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey" id="2">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">욕실 조명 – 고려해야 할 사항</div>
                        <p class="txt txt--sm">
                            물론 욕실 조명은 주로 방을 고르게 비추고 모든 것을 볼 수 있도록 도와야 합니다. 동시에 현대적인 욕실 조명은 다양한 요구 사항을 충족해야 합니다. 이는 특히 사고 예방에 관한 것이므로 젖은 욕실에 충분한 조명이 필수적입니다. <br />
                            기본적으로 특히 조명이 필요한 모든 영역을 먼저 표시하는 욕실 조명 계획을 세우는 것이 합리적입니다. 이는 특히 싱크대 주변 영역일 뿐만 아니라 샤워실 및 세탁기와 건조기가 있는 틈새일 수도 있습니다. 어느 지역에 얼마나 많은 빛이 필요한지 계획한 다음 욕실에 딱 맞는 조명을 디자인하세요.
                        </p>
                        <expansion-panels :data="points" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="3">
            <v-container class="text-center">
                <div class="tit mb-12px mb-md-16px">최적의 조명으로 완벽한 기분 좋은 분위기</div>
                <p class="txt txt--sm">욕실의 편안한 분위기를 조성하는 데에는 여러 가지 측면이 있습니다. 욕실 조명에 대한 팁을 활용하면 완벽한 기분 좋은 분위기를 조성할 수 있습니다. 여기에는 밝은 색상, 적합한 램프 및 간접 조명 요소가 포함됩니다. 빌레로이앤보흐 조명으로 스타일에 매료되세요.</p>
                <three-images :path="path" first="antao-washbasin-almond-with-mirror-and-vanity-unit.webp" second="pic_L4801200_S.png" third="collaro-washbasin-white-with-gold-features.webp" />
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <section-slide :path="path" :items="slides" />
            </v-container>
        </page-section>

        <page-section id="4">
            <v-container>
                <v-row align="center" justify="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <v-img :src="`${path}/avento-washbasin-white-with-mirror-and-vanity-unit.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">적합한 욕실 램프 찾기
                        </div>
                        <p class="txt txt--sm">우아하고 현대적이며 동시에 안전한 욕실 조명은 기분 좋은 분위기를 조성합니다. 모든 상황에 적합한 조명을 제공하려면 다양한 조명 요소를 선택하세요. LED는 비교할 수 없을 정도로 다양한 색온도, 색상 및 밝기를 제공합니다. 밝은 거울 조명은 아침에 상쾌하고 깨어나는 기분을 느끼게 해줍니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";

import SectionSlide from "@/components/client/dumb/section-slide.vue";
import ExpansionPanels from "@/components/client/ideas/expansion-panels.vue";
import DotList from "@/components/client/ideas/dot-list.vue";
import ThreeImages from "@/components/client/ideas/three-images.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,

        SectionSlide,
        ExpansionPanels,
        DotList,
        ThreeImages,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 개요",
                href: "/ideas/bathroom/overview",
            },
        ],
        path: "/images/ideas/bathroom/lighting",
        navigation: [
            "화장실에는 어떤 조명이 있나요?",
            "욕실 조명 - 고려해야 할 사항",
            "최적의 조명으로 기분 좋은 분위기",
            "적합한 욕실 램프 찾기",
            //
        ],
        overview: [
            "천장 램프는 균일한 기본 조명을 제공합니다.",
            "다용도 거울 및 액센트 램프 사용",
            "욕실의 램프는 IP 보호 등급에 부합해야 합니다.",
            "일광 백색광은 아침 루틴에 도움이 됩니다.",
            "스마트 홈을 사용하여 조명을 맞춤 설정하세요",
            //
        ],
        points: [
            {
                header: "욕실에는 어떤 램프가 적합합니까?",
                content:
                    '`욕실의 LED 조명은 여러 가지 이유로 의미가 있습니다. 이제 기존 할로겐 전구가 금지되었을 뿐만 아니라 LED는 수많은 장점을 제공합니다. 가열되지 않고, 에너지 효율적이며, 일반적으로 백열전구보다 습기에 더 잘 견딥니다. LED에는 스포트라이트, 스트립, 고정 벽 및 천장 램프, 작은 점 등 다양한 버전이 있습니다.\r\n대부분의 경우 LED 욕실 조명에는 리모컨이나 스위치를 사용하여 켤 수 있는 "어두움/따뜻함" 또는 다양한 색상과 같은 추가 기능이 탑재되어 있습니다. 즉, 많은 노력을 들이지 않고도 생산되는 조명을 필요에 맞게 조정할 수 있습니다.',
            },
            {
                header: "욕실의 IP 보호 등급",
                content: "욕실용 램프가 충족해야 하는 요구 사항에 대한 명확한 사양이 있습니다. 최소한 보호 등급 IPX7(방수)을 갖춘 욕조나 샤워실에만 램프를 설치할 수 있습니다. 샤워기 또는 욕조의 수직 경계 내에 있는 램프는 보호 등급 IPX5(워터 제트 보호)를 갖춰야 합니다. 욕조 또는 샤워기 주변 60cm와 그 경계에서 램프는 보호 등급 IPX4(방수)를 갖춰야 합니다.",
            },
            {
                header: "조명이 필요한 욕실 공간은 어디입니까?",
                content: "기본적으로 욕실 전체의 조명은 고르게 켜져야 합니다. 기본 조명이 이에 기여합니다. 특별히 조명해야 하는 개별 영역도 있습니다. 여기서 특히 언급할 가치가 있는 것은 매일 사용되는 싱크대입니다. 불쾌한 그림자가 없고 얼굴이 고르게 조명되는 것이 더욱 중요합니다.",
            },
            {
                header: "욕실에는 몇 루멘이 필요합니까?",
                content: "웅덩이, 얼룩, 물체는 욕실에서 빠르게 위험의 원인이 될 수 있습니다. 따라서 모든 표면이 밝고 고르게 조명되는 것이 더욱 중요해졌습니다. 화장실에서 사고 위험을 최소화하는 방법. 밝은 조명을 제공하려면 평방 미터당 250~300루멘을 기대하세요. 방이 더 크고 각도가 많을수록, 가구와 벽이 더 어두울수록 더 많은 루멘을 목표로 해야 합니다. 물론 이 값은 기본 조명에 적용됩니다. 반면에 대기 지점에서는 루멘이 더 적을 수 있습니다.",
            },
        ],
        slides: [
            {
                title: "욕실의 이상적인 색온도",
                text: "욕실의 완벽한 조명 색상은 램프의 위치, 시간, 실내의 자연 채광 조건에 따라 달라집니다. 아침에 상쾌하고 에너지 넘치는 하루를 시작하려면 중성 백색광이 필요합니다. 여기서는 약 5,300켈빈이 권장됩니다. 저녁 시간에 독립형 Villeroy & Boch Finion 욕조에서 편안하게 목욕하며 하루를 마무리하고 싶다면 최대 3,000켈빈의 따뜻한 백색광을 추천합니다.\r\n기본적으로 기본 조명과 미러 조명은 더 많은 일광 백색광을 방출해야 하며, 무드 조명과 작은 조명 액센트는 따뜻한 백색광 색상을 가져야 합니다. 조명 색상을 개별적으로 조정할 수 있는 램프는 실용적이며 필요에 따라 조정됩니다.",
                image: "finion-bathtub-white-in-beige-bathroom.webp",
            },
            {
                title: "스마트 홈을 통한 조명 효과",
                text: "물론 스마트 홈은 욕실에도 적용되었습니다. 전체적인 스마트 홈 시스템을 사용하면 난방과 광원을 모두 쉽게 조정할 수 있습니다. Villeroy & Boch More To See 컬렉션에는 개별 조절이 가능한 LED 욕실 조명이 포함되어 있습니다. 즉, 단 한 번의 클릭으로 상황에 맞게 조명을 조정할 수 있습니다.\r\n스마트 홈 효과를 위한 다른 옵션으로는 다채로운 조명이 있습니다. 버튼을 누르면 무지개의 모든 색상으로 불이 켜지며 무엇을 하든 분위기 있는 조명을 제공합니다. LED 요소에는 일반적으로 특수 리모콘이 함께 제공됩니다. 스마트폰으로 직접 조명을 제어할 수 있는 스마트 홈 시스템도 있습니다. 리모콘은 손님을 포함해 누구나 사용할 수 있다는 장점이 있으며 조작이 간단하고 직관적입니다.",
                image: "artis-washbasin-french-linen-and-light-mirror.webp",
            },
            {
                title: "현대적이고 안전한 욕실 조명",
                text: "IP 보호 등급은 욕실 조명 요소의 안전에 많은 기여를 합니다. 동시에 조명은 욕실에서의 안전과 관련하여 중요한 역할을 합니다. 욕실에서 안전하게 이동할 수 있도록 디자인 측면은 뒷전으로 밀립니다.\r\n안전이 보장된다면 디자인에 집중하세요. Villeroy & Boch의 Antao 컬렉션 과 같은 현대적인 모양 이나 Homage 컬렉션과 같은 특이한 색상 컨셉은 욕실에서 즐거운 시간을 보낼 수 있도록 해줍니다.",
                image: "antao-bathromm-mirror-illuminated-in-modern-bathroom.webp",
            },
        ]
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>