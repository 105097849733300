var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/kitchen/overview/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Kitchen Overview"
    }
  }), _c('page-section', {
    staticClass: "pt-40px pt-md-80px"
  }, [_c('v-container', {
    staticClass: "mt-50px mt-md-100px"
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("다양한 주방 스타일 외에도 다양한 유형과 모양의 주방도 구별됩니다. 우리는 그것들이 무엇이라고 불리는지, 그리고 그것들이 당신에게 어떤 이점을 제공하는지 말해줍니다.")])])], 1)], 1)], 1), _c('overview-types'), _c('overview-cleaning')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }