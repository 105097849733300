var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/bathroom/storage/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Bathroom storage ideas"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("깔끔한 욕실에 대한 영감")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실에는 수건, 청소 용품, 화장품 및 모든 종류의 작은 물건을 보관할 수 있는 공간이 있어야 합니다. 이렇게 하면 사용 가능한 공간을 스토리지에 효과적으로 사용할 수 있습니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_vm._l(_vm.navigation, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('router-link', {
      staticClass: "txt txt--sm d-flex align-center",
      attrs: {
        "to": `#${index + 1}`
      }
    }, [_c('v-img', {
      staticClass: "mr-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-down.svg"
      }
    }), _vm._v(" " + _vm._s(item) + " ")], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('dot-list', [_vm._l(_vm.overview, function (item) {
    return [_c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "1"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("장식 및 보관 틈새를 사용하십시오.")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실을 계획할 때 수납 공간이 숨겨져 있는 벽감과 모서리가 거의 자동으로 생성됩니다. 혁신적이고 실용적인 수납 솔루션을 제공하여 적은 자원으로 사용되지 않는 공간을 재설계할 수 있습니다. 매립형 선반, 맞춤형 캐비닛 및 다기능 요소는 모든 욕실 필수품을 위한 공간을 만들어줍니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('section-slide', {
    attrs: {
      "items": _vm.slide1,
      "path": _vm.path
    }
  })], 1)], 1), _c('page-section', {
    attrs: {
      "id": "2"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("추가 수납 공간을 위한 욕실 가구")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 크거나 작은 욕실을 재설계하는 경우 가구를 선택할 때 다기능성을 강조해야 합니다. 수납공간과 디자인을 겸비한 고품질의 실용적인 가구입니다. 물론, 선택은 욕실이 제공하는 공간의 크기에 따라 달라집니다. 유용한 팁 은 욕실에 필요한 수납 공간을 어떻게 영리하게 통합할 수 있는지 확인할 수 있도록 미리 평면도를 작성하는 것입니다. "), _c('br'), _vm._v(" 건식 벽체와 공동은 저장 공간을 만들기 위한 추가 옵션을 제공합니다. 초기에 계획을 세우면 나중에 찬장 등이 눈에 띄지 않도록 수납 옵션을 디자인에 영리하게 통합할 수 있습니다. 이는 욕실에서 특히 우아해 보이며 욕실이 집의 웰빙 오아시스로 남아 있도록 보장합니다. ")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('section-slide', {
    attrs: {
      "items": _vm.slide2,
      "path": _vm.path
    }
  })], 1)], 1), _c('page-section', {
    attrs: {
      "id": "3"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("바구니와 선반이 있는 수납 공간")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("바구니, 상자 등은 미니멀한 욕실뿐만 아니라 넉넉한 수납 공간을 제공합니다. 세련된 분위기를 연출하는 실용적인 수납 솔루션은 크고 작은 욕실에 적합합니다. 모든 욕실용품을 보관할 수 있는 웰빙의 오아시스를 만들어보세요.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "md": "1"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("세탁물과 수건 바구니")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 오픈 선반, 바닥의 여유 공간, 벽의 후크를 사용하면 크고 작은 바구니를 놓을 수 있습니다. 금속, 나무, 라피아 소재로 만든 큰 바구니에는 수건을 보관할 수 있고, 작은 바구니에는 헤어 액세서리를 정리하는 데 적합합니다. 세탁 바구니도 욕실의 필수품입니다. 욕실의 나머지 부분과 재료를 일치시켜 원하는 모양으로 맞춤화하세요. 예를 들어, 작은 도자기 그릇을 사용하여 작은 물건이나 화장품을 정리할 수도 있습니다. "), _c('br'), _vm._v(" 바구니는 공간이 넉넉할 뿐만 아니라 선반과 칸막이를 정리하는 데에도 도움이 됩니다. 몇 가지 간단한 단계만 거치면 욕실이 훨씬 더 깔끔해 보일 수 있습니다. ")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/colorado-bathtub-in-beige-bathroom.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("수건사다리가 유행이네요")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("클래식 수건 걸이 또는 라디에이터는 모든 크기의 수건을 보관할 수 있는 공간을 제공합니다. 후자는 또한 욕실을 가열합니다. 수건 사다리는 스타일이 좀 더 미니멀합니다. 벽에 비스듬히 세워져 있으며 영구적으로 설치된 수건 라디에이터보다 더 좁고 유연합니다. 사다리의 계단이 조금 더 넓다면 바구니, 작은 도구, 장식 요소를 거기에 놓을 수도 있습니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "order-md": "2"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/finion-hanging-rail-white.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("월박스의 인기가 날로 높아지고 있습니다.")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("작은 욕실을 계획할 때 자동으로 수직적으로 생각하게 됩니다. 월박스는 다양한 높이에 유연하게 부착할 수 있고 다양한 물건으로 채울 수 있습니다. Villeroy & Boch Finion 컬렉션은 조명도 갖춘 실용적인 벽 상자를 제공합니다. 이것은 욕실에서 수납 도구를 눈길을 사로잡는 요소로 만듭니다. 벽에 수납할 수 있는 공간만 있으면 어디에서나 월박스를 사용할 수 있습니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("자신의 욕실에 더 많은 공간")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실은 놀라운 수납 공간을 제공할 수 있습니다. 벽감, 선반, 다기능 벤치 및 붙박이 찬장을 사용하면 시각적 손실 없이 모든 욕실 용품을 능숙하게 분류하고 정리할 수 있습니다.")])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }