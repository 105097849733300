<template>
    <page-section>
        <v-container>
            <v-sheet class="section-bg" />

            <swiper v-bind="{ options }">
                <template v-for="item in items">
                    <swiper-slide :key="item">
                        <v-row align="center" class="row--lg">
                            <v-col cols="12" md="5" order="2" order-md="1">
                                <v-card color="grey lighten-5" class="h-md-400px d-md-flex align-center">
                                    <div>
                                        <div class="tit mb-12px mb-md-16px">
                                            {{ item.title }}
                                        </div>
                                        <p class="txt txt--sm mb-18px mb-md-24px">
                                            {{ item.text, }}
                                        </p>
                                        <template v-if="item.button">
                                            <v-btn large color="#fff" elevation="2" class="rounded-xs ml-1" :href="item.link" target="_blank">
                                                {{ item.button }}
                                                <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                            </v-btn>
                                        </template>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="7" order="1" order-md="2">
                                <v-card tile :img="item.image" class="position-relative mb-50px mb-md-0">
                                    <v-responsive :aspect-ratio="674 / 504" />
                                    <div class="swiper-controler">
                                        <v-row align="center" class="row--xs">
                                            <v-col cols="auto">
                                                <v-btn x-small icon outlined class="swiper-button swiper-button--prev">
                                                    <v-img max-width="32" src="/images/icon/icon-swiper-left.svg" />
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <div class="swiper-pagination" />
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn x-small icon outlined class="swiper-button swiper-button--next">
                                                    <v-img max-width="32" src="/images/icon/icon-swiper-right.svg" />
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </swiper-slide>
                </template>
            </swiper>
        </v-container>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        PageSection,
    },
    data: () => ({
        items: [
            {
                title: "L자형 주방",
                text: "L자형 주방은 폐쇄형과 개방형 방에 모두 통합할 수 있습니다. L자형 주방은 두 개의 주방 유닛으로 구성되어 있습니다. 이들은 서로 직각으로 배치됩니다. 이것이 코너 주방 또는 코너 주방이라고도 불리는 이유입니다. 이것은 거의 모든 레이아웃에서 실현할 수 있는 고전적인 맞춤형 주방입니다. 다양한 디자인으로 제공됩니다. L자형은 개방형 주방에도 적합합니다. 여기서는 순식간에 우아한 룸 디바이더가 됩니다. 주방을 계획할 때는 모서리를 지능적으로 활용하는 것을 잊지 마세요.",
                image: "/images/ideas/kitchen/overview/kitchen-type-1.webp",
            },
            {
                title: "주방 유닛",
                text: "주방을 현대화하고 싶지만 큰돈을 쓰고 싶지 않으신가요? 그렇다면 가전제품이 있는 주방 유닛이 저렴한 대안입니다. 주방에 사용할 수 있는 공간이 작다면 좋은 해결책이기도 합니다. 전기 제품이 있는 주방 유닛은 첫 집에도 이상적입니다. 적은 예산으로도 주방을 완벽하게 꾸밀 수 있기 때문입니다. 그러나 방은 이에 적합해야 합니다. 주방 유닛에는 소켓이 있는 긴 벽, 전기 밥솥 연결부 및 물 연결이 필요합니다. 이러한 유형의 주방으로 개인의 요구 사항을 확실히 실현할 수 있지만 가격이 상당히 올라갈 수 있습니다.",
                image: "/images/ideas/kitchen/overview/kitchen-type-2.webp",
            },
            {
                title: "조리대가 있는 주방",
                text: "쿠킹 아일랜드가 있는 주방은 매우 현대적입니다. 쿠킹 아일랜드는 주방 겸 거실에서 자주 사용되는 구성 요소입니다. 일반적으로 요리사가 음식을 준비할 때 식탁을 바라볼 수 있도록 배치됩니다. 추출 보닛은 일반적으로 쿠킹 아일랜드 위에 설치됩니다. 또 다른 옵션은 창문 앞에 쿠킹 아일랜드를 두는 것입니다. 그러면 요리하는 동안 환기에 사용할 수 있습니다. 쿠킹 아일랜드가 있는 주방은 L자형과 U자형 주방, G자형 주방에 모두 적합합니다. 모든 면에서 접근할 수 있어 주방에서 자유롭게 움직일 수 있고 공동체 의식을 증진합니다.",
                image: "/images/ideas/kitchen/overview/kitchen-type-3.webp",
            },
            {
                title: "U자형 주방",
                text: "주방에 쓸 수 있는 넓은 공간이 있나요? 그렇다면 U자형 주방이 여러분에게 적합한 옵션일 수 있습니다. 정사각형 방과 대형 주방에 이상적입니다. U자형 주방은 현대적이며 충분한 수납 공간을 제공합니다. 주방 유닛은 다양한 길이와 모양을 가질 수 있습니다. 짧은 다리, 긴 다리 또는 비대칭 다리, 방에 한 면이 독립형이거나 모든 캐비닛 면이 한 벽에 붙어 있습니다. 이는 또한 해당 방의 레이아웃에 따라 달라집니다. 각 유닛 사이에 충분한 공간(최소 2m)이 있어야 주방을 쉽게 돌아다닐 수 있습니다.",
                image: "/images/ideas/kitchen/overview/kitchen-type-4.webp",
            },
        ],
        options: {
            effect: "fade",
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                },
            },
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
}
.section-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }
}
::v-deep(.swiper-container) {
    overflow: visible;
}
.swiper-controler {
    position: absolute;
    bottom: -56px;
    left: 0;
    .swiper-pagination {
        position: static;
        padding: 0 16px;
        ::v-deep(.swiper-pagination-bullet) {
            margin: 0 16px !important;
            background-color: initial !important;
            border: initial !important;
            width: auto !important;
            height: auto !important;
            font-weight: 500;
            color: var(--v-grey-darken3);
            border-radius: initial;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
            &-active {
                border-bottom: 1px solid var(--v-grey-darken3) !important;
            }
        }
    }
}
@media (min-width: 768px) {
    .container {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    .section-bg {
        width: calc(100% / 12 * 8);
        height: 100%;
    }
}
</style>