var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-container', {
    staticClass: "position-relative pb-12px py-md-72px"
  }, [_c('v-sheet', {
    staticClass: "section-bg"
  }), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-card', {
    staticClass: "position-relative mb-50px mb-md-0",
    attrs: {
      "tile": "",
      "img": "/images/ideas/kitchen/overview/types.webp"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 674 / 504
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-card', {
    staticClass: "h-md-400px d-md-flex align-center",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("주방의 종류")]), _c('p', {
    staticClass: "txt txt--sm mb-18px mb-md-24px"
  }, [_vm._v("다양한 주방 스타일을 살펴보고 귀하의 요구에 딱 맞는 디자인을 찾는 방법을 알아보세요. 작고 컴팩트한 주방이든 넓고 개방형 주방-거실이든, 다양한 주방 유형에 맞게 특별히 맞춤화된 실용적인 팁과 세련된 아이디어를 제공합니다. 작은 공간에도 편안함과 스타일을 더하는 방법을 알아보세요.")]), _c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/ideas/kitchen/overview/types"
    }
  }, [_vm._v(" 주방 유형 살펴보기 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }