var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/bathroom/solution/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Bath planning with Villeroy & Boch"
    }
  }), _c('page-section', {
    staticClass: "pt-40px pt-md-80px"
  }, [_c('v-container', {
    staticClass: "mt-50px mt-md-100px"
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("집의 중심 생활 공간인 욕실은 결국 개인의 요구 사항을 충족해야 하기 때문에 가구를 배치할 때 많은 주의를 기울일 가치가 있습니다. 가족용 욕실인가요, 아니면 작은 손님용 화장실인가요? 장벽이 없는 화장실을 원하시나요? 집에 웰빙 천국을 만들고 싶으신가요? 아니면 모든 것이 가능할까요?")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', {
    staticClass: "position-relative pb-12px py-md-72px"
  }, [_c('swiper', _vm._b({}, 'swiper', {
    options: _vm.options
  }, false), [_vm._l(_vm.items, function (item) {
    return [_c('swiper-slide', {
      key: item.subtitle
    }, [_c('v-row', {
      staticClass: "row--lg",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "5",
        "order": "2",
        "order-md": "1"
      }
    }, [_c('v-card', {
      staticClass: "h-md-400px d-md-flex align-center"
    }, [_c('div', [_c('div', {
      staticClass: "tit mb-12px mb-md-16px"
    }, [_vm._v("작은 욕실과 그 가능성")]), _c('div', {
      staticClass: "txt txt--sm txt--dark font-weight-medium"
    }, [_vm._v(" " + _vm._s(item.subtitle) + " ")]), _c('p', {
      staticClass: "txt txt--sm mb-18px mb-md-24px"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])])])], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "7",
        "order": "1",
        "order-md": "2"
      }
    }, [_c('v-card', {
      staticClass: "position-relative mb-50px mb-md-0",
      attrs: {
        "tile": "",
        "img": item.image
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 674 / 504
      }
    }), _c('div', {
      staticClass: "swiper-controler"
    }, [_c('v-row', {
      staticClass: "row--xs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "swiper-button swiper-button--prev",
      attrs: {
        "x-small": "",
        "icon": "",
        "outlined": ""
      }
    }, [_c('v-img', {
      attrs: {
        "max-width": "32",
        "src": "/images/icon/icon-swiper-left.svg"
      }
    })], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "swiper-pagination"
    })]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "swiper-button swiper-button--next",
      attrs: {
        "x-small": "",
        "icon": "",
        "outlined": ""
      }
    }, [_c('v-img', {
      attrs: {
        "max-width": "32",
        "src": "/images/icon/icon-swiper-right.svg"
      }
    })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px my-20px my-md-40px"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("멋진 것들을 위한 공간")]), _c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("따라서 욕실을 세부적으로 계획하기 전에 먼저 미래의 욕실에 대한 자신의 바람과 요구 사항을 파악하는 것이 중요합니다. 물론 욕실에 어떤 디자인 옵션을 구현할 수 있는지에 대한 개요도 알아야 합니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/solution/memeto-2.0-toilet-white-bathroom.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm mb-12px mb-md-16px"
  }, [_vm._v("넓은 욕실")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("아늑한 분위기를 위한 올바른 객실 배치")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "order-md": "2"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/solution/finion-bathtub-in-classic-bathroom-with-white-and-blue-walls.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm mb-12px mb-md-16px"
  }, [_vm._v("웰니스 목욕")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("나만의 기분 좋은 오아시스를 집으로 가져오세요")])])], 1)], 1)], 1), _c('page-section', [_c('v-containr', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("다양한 공간 상황에는 다양한 공간 솔루션이 필요합니다. 개인 욕실에 대한 기본 정보와 유용한 팁을 확인하고 개별 객실 솔루션에서 영감을 얻으세요.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5 py-40px py-md-80px my-20px my-md-40px"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개별 솔루션")])]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.solutions, function (item) {
    return [_c('v-col', {
      key: item.title,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "tit tit--xxs mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])], 1)];
  })], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }