<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/features/visual.webp" :breadcrumbs="breadcrumbs" title="Bathroom features" />

        <page-section class="py-40px py-md-80px my-40px my-md-80px">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit tit--sm mb-12px mb-md-16px">Villeroy & Boch의 욕실 기능</div>
                        <p class="txt txt--sm">욕실 도자기는 상대적으로 정적인 반면, 특별한 욕실 ​​기능을 통해 기능적인 욕실을 웰빙의 오아시스로 바꿀 수 있습니다. 물론 디자인도 중요한 역할을 하지만 외모만 중요한 것은 아닙니다. 특별한 욕실 ​​도구는 프로세스를 단순화하고 일상 생활을 지원하는 데 도움이 됩니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-sheet color="transparent" max-width="940" class="mx-auto">
                    <div class="tit-wrap tit-wrap--lg">
                        <div class="tit mb-12px mb-md-16px">화장실 수세식</div>
                        <p class="txt txt--sm">고품질 변기 외에도 혁신적인 변기 수세기는 욕실의 유용한 기능입니다. 욕실 화장실 장비는 현대 기술과 결합되어 화장실 청소 및 유지 관리가 더 쉬워졌습니다.</p>
                    </div>
                    <v-row justify="center" align="center" class="row--xxl">
                        <template v-for="item in items">
                            <v-col cols="12" md="6" :key="item">
                                <v-card max-width="776" color="transparent" tile flat class="mx-auto">
                                    <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                    <div class="px-12px px-md-16px">
                                        <div class="tit tit--xs grey--text text--darken-3 mb-6px mb-md-8px">
                                            {{ item.title }}
                                        </div>
                                        <p class="txt txt--sm grey--text text--darken-3">
                                            {{ item.text }}
                                        </p>
                                    </div>
                                </v-card>
                            </v-col>
                        </template>
                    </v-row>
                </v-sheet>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 py-40px py-md-80px my-20px my-md-40px">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit-wrap tit-wrap--lg">
                            <div class="tit mb-12px mb-md-16px">화장실 조립 혁신</div>
                            <div class="txt txt--sm">점점 더 많은 사람들이 욕실을 스스로 개조하기로 결정하고 있습니다. 이러한 개발을 통해 배관 시스템을 설치하는 혁신적인 방법이 점점 더 많아지고 DIY 사용자도 이를 수행할 수 있게 되었습니다. 그러나 전문가들은 작업을 더 쉽고 짧게 만들어주는 간단한 조립 시스템의 이점도 누릴 수 있습니다.</div>
                        </div>
                    </v-col>
                </v-row>
                <v-sheet color="transparent" max-width="940" class="mx-auto">
                    <v-row justify="center" align="center" class="row--xxl">
                        <v-col cols="12" md="6">
                            <v-img src="/images/ideas/bathroom/features/suprafix-technology-with-toilet.webp" class="mb-12px mb-md-16px" />
                            <div class="px-12px px-md-16px">
                                <div class="tit tit--xs grey--text text--darken-3 mb-6px mb-md-8px">수프라픽스 3.0</div>
                                <p class="txt txt--sm grey--text text--darken-3">욕실에 벽걸이형 변기를 설치하고 싶으신가요? 새로운 SupraFix 3.0 부착 장치를 사용하면 이 작업을 매우 짧은 시간에 완료할 수 있습니다. 벽면 브래킷을 부착하고 변기를 씌운 다음 Vario 너트를 끼우고 그 위에 시트를 놓으면 완료됩니다.</p>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-img src="/images/ideas/bathroom/features/toilet-seat-gets-attached-to-toilet+(1).webp" class="mb-12px mb-md-16px" />
                            <div class="px-12px px-md-16px">
                                <div class="tit tit--xs grey--text text--darken-3 mb-6px mb-md-8px">퀵 릴리스</div>
                                <p class="txt txt--sm grey--text text--darken-3">최신 QuickRelease 부착물은 변기 설치를 단순화할 뿐만 아니라 이 시스템은 또한 시트 아래와 뒤의 접근하기 어려운 부분을 쉽게 청소할 수 있도록 지원합니다. 단 한 번의 동작으로 변기 시트를 제거하고 청소 후 다시 장착할 수 있습니다.</p>
                            </div>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">메탈림 기술</div>
                        <div class="txt txt--sm">욕실은 단순한 화장실이 아닙니다. 샤워에는 혁신적인 MetalRim 기술과 같은 특별한 기능도 포함되어 있습니다. 샤워기 가장자리 부분에 아연도금 강철 보강재를 사용하여 소재를 특히 안정적으로 만듭니다. 이를 통해 욕실에 통합될 수 있는 평면 디자인이 가능해졌습니다.</div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <features-metalrim />

        <page-section>
            <v-container class="text-center">
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">ViFlow</div>
                        <div class="txt txt--sm">배수구와 오버플로는 단순히 욕조와 싱크대의 일부입니다. 각 욕실 도자기와 눈에 띄지 않고 우아하게 조화를 이루어야 합니다.</div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <template v-for="item in viflow">
                        <v-col :key="item.title" cols="12" md="6">
                            <v-row no-gutters>
                                <v-col cols="6">
                                    <v-img :src="item.image" :aspect-ratio="1 / 1" />
                                </v-col>
                                <v-col cols="6">
                                    <v-sheet color="grey lighten-5" height="100%" class="d-flex align-center pa-20px">
                                        <div>
                                            <div class="tit tit--sm mb-10px">
                                                {{ item.title }}
                                            </div>
                                            <p class="txt txt--sm">
                                                {{ item.text }}
                                            </p>
                                        </div>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container class="position-relative pb-12px py-md-72px">
                <v-sheet class="section-bg section-bg--secondary" />

                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img src="/images/ideas/bathroom/features/vifresh-technology-rim-block-filling.webp"></v-img>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-card color="grey lighten-5" class="h-md-400px d-md-flex align-center">
                            <div>
                                <div class="tit mb-12px mb-md-16px">비프레쉬</div>
                                <p class="txt txt--sm">화장실 주변의 위생을 더 원하시나요? 그렇다면 Villeroy & Boch의 최신 위생 솔루션을 사용해 보세요. ViFresh는 혁신적이며 욕실에 독특하고 신선한 향기를 선사합니다. 표준 변기 블록으로 변기에 통합된 칸막이를 채웁니다.</p>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import FeaturesMetalrim from "@/components/client/ideas/bathroom/overview/features/features-metalrim.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        FeaturesMetalrim,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "주방 소재",
                href: "/ideas/kitchen/material",
            },
        ],
        items: [
            {
                title: "트위스트 플러시",
                text: "물을 절약하는 변기 수세식 TwistFlush는 항균 효과가 있으며 변기 관리에 도움을 줍니다. 혁신적인 기술을 사용하여 가장 작은 먼지 입자까지 제거합니다. TwistFlush는 변기솔 없이 지내고 싶은 가족에게 이상적입니다.",
                image: "/images/ideas/bathroom/features/universo-twistflush-bathroom-white-toilet-flushes-on-light-green-background+(1).webp",
            },
            {
                title: "비클린",
                text: "종이가 없는 화장실? 이 새로운 트렌드는 ViClean-I 비데 변기를 통해 여러분의 집에도 적용되고 있습니다. 따뜻하고 쾌적한 샤워 제트를 사용하여 화장실 사용 후 청소를 실시하므로 항상 상쾌하고 깨끗한 느낌을 받을 수 있습니다. ",
                image: "/images/ideas/bathroom/features/vicleani200-shower-toilet-with-water-jet.webp",
            },
            {
                title: "다이렉트플러시",
                text: "혁신적이고 테두리가 없는 디자인 덕분에 DirectFlush가 적용된 양변기는 잔여물과 침전물로부터 보호됩니다. 앞치마는 물이 튀는 것을 방지하고 물은 잔류물을 강력하게 제거합니다. DirectFlush 변기의 디자인이 인상적입니다.",
                image: "/images/ideas/bathroom/features/rimless-toilet-without-directflush-technologie.webp",
            },
            {
                title: "사전 예방+",
                text: "혁신적인 ProActive+ 플러시 플레이트를 사용하면 시대를 초월한 디자인과 최신 기술을 욕실에 적용할 수 있습니다. 플러시 작업은 수동으로 또는 모션 센서를 통해 트리거될 수 있으므로 화장실을 방문할 때마다 위생적인 ​​결과를 보장합니다. 통합 센서와 시간 기록은 언제든지 필요한 세척수의 양을 감지하므로 물 낭비는 과거의 일이 됩니다.",
                image: "/images/ideas/bathroom/features/subway-30-toilet-with-flushplate.webp",
            },
        ],
        viflow: [
            {
                title: "욕조",
                text: "ViFlow가 탑재된 Villeroy & Boch 욕조는 디자인을 손상시키지 않고 청소가 쉬운 통합형 오버플로 기능을 갖춘 영리한 배수 장치를 갖추고 있습니다.",
                image: "/images/ideas/bathroom/features/avento-bathtub-white-with-tap-black-and-dark-wood-panelling.webp",
            },
            {
                title: "세면기",
                text: "ViFlow 배수 및 오버플로는 세면대를 위한 시대를 초월한 위생 솔루션을 제공합니다. 배수구는 넘침을 방지하고 모든 레버 피팅과 결합될 수 있습니다.",
                image: "/images/ideas/bathroom/features/venticello-washbasin-white-with-tap-silver-and-mirror.webp",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.section-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }

    &--secondary {
        left: initial;
        right: 0;
        &::before {
            left: initial;
            right: -100vw;
        }
    }
}

@media (min-width: 768px) {
    .section-bg {
        width: calc(100% / 12 * 8);
        height: 100%;
    }
}
</style>