<template>
    <page-section id="4">
        <v-container>
            <div class="product-section-title tit-wrap tit-wrap--lg">
                <span class="tit font-weight-regular font-tertiary white">당신의 집을 위한 더 많은 아이디어</span>
            </div>
            <div class="tit-wrap">
                <div class="tit">욕실 매니아를 위한</div>
            </div>
            <v-row>
                <template v-for="(item, index) in items">
                    <v-col cols="12" lg="6" :key="index">
                        <v-card tile :to="item.link">
                            <v-img :src="item.image" />
                            <div class="tit tit--sm mt-20px">
                                {{ item.title }}
                            </div>
                            <p class="txt txt--sm mt-8px">
                                {{ item.text }}
                            </p>
                            <div v-if="item.link" class="mt-12px mt-md-16px d-flex align-center">
                                <span class="txt txt--xs txt--dark font-weight-medium">자세히 알아보기</span>
                                <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                            </div>
                        </v-card>
                    </v-col>
                </template>
            </v-row>
        </v-container>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        PageSection,
    },
    data: () => ({
        items: [
            {
                title: "욕실 리노베이션 및 리모델링",
                text: "욕실 리모델링에 필요한 모든 정보",
                image: "/images/ideas/bathroom/trends/mania-1.webp",
                // link: "/",
            },
            {
                title: "세라믹",
                text: "시대를 초월한 아름다움, 세련된 도자기로 완성되는 욕실",
                image: "/images/ideas/bathroom/trends/mania-2.webp",
                // link: "/",
            },
            {
                title: "웰니스",
                text: "욕실을 당신만의 웰빙 휴식공간으로 바꿔보세요",
                image: "/images/ideas/bathroom/trends/mania-3.webp",
                // link: "/",
            },
            {
                title: "수납공간",
                text: "욕실 수납공간에 필요한 모든 정보",
                image: "/images/ideas/bathroom/trends/mania-4.webp",
                // link: "/",
            },
        ],
    }),
};
</script>