var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "artis-slide",
    attrs: {
      "max-width": "60%"
    }
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.options
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('swiper-slide', {
      key: item
    }, [_c('v-card', {
      staticClass: "mx-auto",
      attrs: {
        "max-width": "700",
        "img": `/images/ideas/bathroom/colours/${item}`
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 700 / 467
      }
    })], 1)], 1)];
  }), _c('div', {
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-pagination"
  })])], 2), _c('v-btn', {
    staticClass: "swiper-button-prev v-size--xx-large",
    attrs: {
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-left")])], 1), _c('v-btn', {
    staticClass: "swiper-button-next v-size--xx-large",
    attrs: {
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-right")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }