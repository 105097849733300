<template>
    <page-section id="3">
        <v-container>
            <div class="tit-wrap tit-wrap--lg">
                <div class="product-section-title">
                    <span class="tit font-weight-regular font-tertiary white">모든 스타일에 맞는 제품</span>
                </div>
            </div>
            <div class="tit-wrap text-center">
                <div class="tit">지금 당신의 욕실에 딱 맞는 제품을 찾아보세요!</div>
            </div>
            <v-tabs v-model="tab" centered class="mb-20px mb-md-40px">
                <template v-for="(item, index) in items">
                    <v-tab :key="index">
                        <v-btn large class="rounded-xs">
                            {{ item.tab }}
                        </v-btn>
                    </v-tab>
                </template>
            </v-tabs>
            <v-row>
                <v-col v-for="(product, index) in products" :key="index" cols="12" sm="4" lg="3">
                    <shop-product-card v-bind="{ value: product }" />
                </v-col>
                <v-col cols="12">
                    <div class="product-lists__pagination">
                        <pagination-component :value="page" :count="pageCount" @input="(value) => (page = value)"></pagination-component>
                    </div>
                </v-col>
            </v-row>

            <!-- <v-tabs-items v-model="tab">
                <v-tab-item eager>
                    <v-row>
                        <v-col cols="6" sm="4" lg="3">
                            <shop-product-card />
                        </v-col>
                    </v-row>
                    <div class="product-lists__pagination">
                        <pagination-component :value="page" :count="pageCount" @input="(page) => $router.push({ query: { ...$route.query, page } })"></pagination-component>
                    </div>
                </v-tab-item>
            </v-tabs-items> -->
        </v-container>
    </page-section>
</template>

<script>
import api from "@/api";
import pageSection from "@/components/client/templates/page-section.vue";
import ShopProductCard from "@/components/client/shop/shop-product-card.vue";
import PaginationComponent from "@/components/client/pagination-component.vue";
import { DISPLAY_CODES } from "@/assets/variables";

export default {
    components: {
        pageSection,
        ShopProductCard,
        PaginationComponent,
    },
    data: () => ({
        tab: 0,
        items: [
            {
                tab: "모든 제품",
                code: "Bath_and_Wellness",
            },
            {
                tab: "세면기",
                code: "bathroom_sink",
            },
            // {
            //     tab: "욕조 및 샤워 트레이",
            //     code: "Bathtub_fittings",
            // },
            {
                tab: "피팅",
                code: "Bathroom_fittings",
            },
        ],

        summary: { totalCount: 0 },
        products: [],
        category: {},

        limit: 4,
        page: 1,
    }),
    computed: {
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.searchCategory(this.items[this.tab].code);
            await this.search();
        },
        async searchCategory(code) {
            try {
                let { categories } = await api.v1.shop.categories.gets({ params: { code } });

                this.category = categories[0];
            } catch (error) {
                this.$handleError(error);
            }
        },
        async search() {
            try {
                let { summary, products } = await api.v1.shop.products.gets({
                    headers: { skip: this.skip, limit: this.limit },
                    params: { _category: this.category?._id, groups: { $in: [DISPLAY_CODES.MAIN_SHOWS.value] } },
                });

                this.summary = summary;
                this.products = products;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    watch: {
        tab() {
            this.init();
            this.page = 1;
        },
        page() {
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
.v-tab {
    &::before {
        display: none;
    }
    .v-btn {
        border: 1px solid var(--v-grey-lighten3);
        background-color: transparent !important;
        color: var(--v-grey-darken3) !important;
        text-transform: initial;
    }
    &--active {
        .v-btn {
            border-color: var(--v-primary-base);
            background-color: var(--v-primary-base) !important;
            color: #fff !important;
        }
    }
}
</style>