import { render, staticRenderFns } from "./trends-2024.vue?vue&type=template&id=0200edde&scoped=true"
import script from "./trends-2024.vue?vue&type=script&lang=js"
export * from "./trends-2024.vue?vue&type=script&lang=js"
import style0 from "./trends-2024.vue?vue&type=style&index=0&id=0200edde&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0200edde",
  null
  
)

export default component.exports