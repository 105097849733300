var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-container', {
    staticClass: "position-relative pb-12px py-md-72px"
  }, [_c('v-sheet', {
    staticClass: "section-bg"
  }), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-card', {
    staticClass: "position-relative mb-50px mb-md-0",
    attrs: {
      "tile": "",
      "img": "/images/ideas/kitchen/cleaning/cleaned-kitchen.webp"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 674 / 504
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-card', {
    staticClass: "h-md-400px d-md-flex align-center",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("주방 청소")]), _c('p', {
    staticClass: "txt txt--sm mb-18px mb-md-24px"
  }, [_vm._v("건강하고 위생적인 ​​조리 환경을 만들기 위해 주방을 깨끗하고 정돈된 상태로 유지하는 방법을 알아보세요. 작업 표면, 가전제품 및 도자기를 윤기 있고 깨끗하게 유지하는 효과적인 세척 방법과 제품을 알아보세요.")]), _c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "large": "",
      "to": "/ideas/kitchen/cleaning",
      "color": "#fff",
      "elevation": "2"
    }
  }, [_vm._v(" 청소 팁으로 이동 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }