<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/kitchen/material/advantage-visual.webp" :breadcrumbs="breadcrumbs" title="Advantages of our Ceramics" />

        <page-section class="py-40px py-md-80px my-40px my-md-80px grey lighten-5">
            <v-container>
                <v-row justify="center" class="row--lg text-center">
                    <v-col cols="12" md="8">
                        <div class="tit tit--sm mb-12px mb-md-16px">평생 최고의 품질</div>
                        <p class="txt txt--sm">그릇, 칼, 나무 도마, 세라믹 싱크대를 깨끗하게 유지하기 위한 유용한 팁과 가이드를 통해 주방 청소는 간단하고 효율적인 프로세스가 됩니다. 주방을 위생적이고 반짝반짝하게 유지하는 데 도움이 되는 입증된 방법을 발견하여 주방에서 항상 편안하고 건강하게 느낄 수 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <template v-for="item in items">
                        <v-col :key="item.title" cols="12" md="6">
                            <v-row no-gutters>
                                <v-col cols="6">
                                    <v-img :src="item.image" :aspect-ratio="1 / 1" />
                                </v-col>
                                <v-col cols="6">
                                    <v-sheet color="grey lighten-5" height="100%" class="d-flex align-center pa-20px">
                                        <div>
                                            <div class="tit tit--sm mb-10px">
                                                {{ item.title }}
                                            </div>
                                            <p class="txt txt--sm">
                                                {{ item.text }}
                                            </p>
                                        </div>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "주방 소재",
                href: "/ideas/kitchen/material",
            },
        ],
        items: [
            {
                title: "위생적이고 식품에 안전함",
                text: "천연 소재는 무취, 무미하므로 식품에 전혀 안전합니다.",
                image: "/images/ideas/kitchen/material/advantage-1.webp",
            },
            {
                title: "스크래치 및 절단 방지",
                text: "고품질 표면은 매우 견고하여 칼을 사용해도 자국이 거의 남지 않습니다.",
                image: "/images/ideas/kitchen/material/advantage-2.webp",
            },
            {
                title: "충격 방지",
                text: "소재의 안정성이 높아 냄비나 프라이팬도 흔들면서 내려놓을 수 있습니다.",
                image: "/images/ideas/kitchen/material/advantage-3.webp",
            },
            {
                title: "내열성이 뛰어남",
                text: "Villeroy & Boch의 세라믹 싱크대는 뜨거운 오븐 그릇을 놓아도 아무런 영향을 받지 않습니다.",
                image: "/images/ideas/kitchen/material/advantage-4.webp",
            },
            {
                title: "청소가 용이함",
                text: "우리 도자기는 청소가 매우 쉽습니다. CeramicPlus로 마감하면 먼지와 석회질이 거의 저절로 용해됩니다.",
                image: "/images/ideas/kitchen/material/advantage-5.webp",
            },
            {
                title: "믿을 수 없을 만큼 내산성",
                text: "우리의 세라믹은 산성 식품이나 가정용 세제와의 접촉을 쉽게 견딜 수 있습니다.",
                image: "/images/ideas/kitchen/material/advantage-6.webp",
            },
            {
                title: "모든 색상에서 내광성",
                text: "오랫동안 강렬한 햇빛에 노출된 후에도 우리의 세라믹 색상 하나하나는 첫날처럼 아름다운 상태를 유지합니다.",
                image: "/images/ideas/kitchen/material/advantage-7.webp",
            },
            {
                title: "놀라운 얼룩 방지 기능",
                text: "음식과 음료는 세라믹 싱크대 표면에 지속적인 흔적을 남기지 않습니다.",
                image: "/images/ideas/kitchen/material/advantage-8.webp",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>