<template>
    <div class="image-wrap">
        <v-img :src="`${path}/${first}`" max-width="672" />
        <v-img :src="`${path}/${second}`" class="positioned-image positioned-image--1" />
        <v-img :src="`${path}/${third}`" class="positioned-image positioned-image--2" />
        <template v-if="text">
            <div class="mt-16px mt-md-n80px">
                <v-row class="row--lg">
                    <v-col cols="12" md="5">
                        <p class="txt txt--xs">
                            {{ text }}
                        </p>
                    </v-col>
                </v-row>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        path: { type: String },
        first: { type: String },
        second: { type: String },
        third: { type: String },
        text: { type: String, default: null },
    },
};
</script>

<style lang="scss" scoped>
.image-wrap {
    position: relative;
    margin-top: 30px;
    .positioned-image {
        &--1 {
            max-width: 288px;
            margin-top: -60px;
            margin-right: calc(-1 * var(--container-gutter));
            margin-left: auto;
            position: relative;
            z-index: 1;
        }
        &--2 {
            max-width: 240px;
            margin-left: calc(-1 * var(--container-gutter));
            margin-top: -180px;
        }
    }
}
@media (min-width: 768px) {
    .image-wrap {
        margin-top: 40px;
        .positioned-image {
            max-width: 368px;
            &--1 {
                margin-top: -130px;
                margin-right: 266px;
            }
            &--2 {
                margin: initial;
                position: absolute;
                top: 100px;
                right: 0;
            }
        }
    }
}
@media (min-width: 1024px) {
    .image-wrap {
        margin-top: 40px;
        .positioned-image {
            max-width: 368px;
            &--1 {
                margin-top: -130px;
                margin-right: 266px;
            }
            &--2 {
                margin: initial;
                position: absolute;
                top: 100px;
                right: 0;
            }
        }
    }
}
</style>