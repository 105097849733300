<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/kitchen/overview/visual.webp" :breadcrumbs="breadcrumbs" title="Types of Kitchen" />

        <page-section class="pt-40px pt-md-80px">
            <v-container class="mt-50px mt-md-100px">
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">다양한 주방 스타일 외에도 다양한 유형과 모양의 주방도 구별됩니다. 우리는 그것들이 무엇이라고 불리는지, 그리고 그것들이 당신에게 어떤 이점을 제공하는지 말해줍니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 py-40px py-md-80px mb-40px mb-md-80px">
            <v-container>
                <v-row align="center">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit font-tertiary">컨텐츠</div>
                        </div>
                        <v-row>
                            <v-col cols="12">
                                <router-link to="#1" class="txt txt--sm d-flex align-center">
                                    <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                    주방 유형: 개요
                                </router-link>
                            </v-col>
                            <v-col cols="12">
                                <router-link to="#2" class="txt txt--sm d-flex align-center">
                                    <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                    주방 틀
                                </router-link>
                            </v-col>
                            <v-col cols="12">
                                <router-link to="#3" class="txt txt--sm d-flex align-center">
                                    <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                    Villeroy & Boch의 현대식 주방
                                </router-link>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card tile elevation="2" class="pa-24px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">한눈에 보기</div>
                            </div>
                            <ul class="txt txt--sm glance-list">
                                <li>주방에는 다양한 유형이 있습니다. 예를 들어, 내장형 주방이나 오픈형 주방 등이 있습니다.</li>
                                <li>또한 L자형, U자형 등 다양한 주방 형태도 있습니다.</li>
                                <li>주방을 단독 주방으로 사용할 것인지, 가족 주방/거실로 사용할 것인지 생각해보세요.</li>
                                <li>각 주방은 외관과 활용도 측면에서 개별적인 특성을 가지고 있습니다.</li>
                            </ul>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="1">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">개요</div>
                        <p class="txt txt--sm">
                            새로운 주방을 구매할 때는 다양한 유형 중에서 선택할 수 있습니다. 가장 중요한 주방 유형으로는 내장형 주방(분리형 주방), 소형 주방 ​​또는 단일 주방, 클래식 주방, 식사 공간 주방, 오픈 플랜 주방이 있습니다. 너무 다양해서 선택하기가 쉽지 않습니다. 게다가 모든 아파트와 주택은 개별적이며 주방 계획과 관련하여 고유한 요구 사항이 있습니다. 예를 들어, U자형 주방은 L자형 주방과 다르게 계획해야 합니다. 어떤 변형을 선택하고 해당 주방 모양이 어떻게 되어야 하는지에 따라 가구를 배치할
                            때 특별한 세부 사항에 주의를 기울이는 것이 중요합니다. 먼저 주방을 어떻게 사용하고 싶은지 생각해 보세요. 그에 따라 선택할 수 있는 주방이 다양합니다. 아늑한 분위기에서 요리하는 것을 좋아하시나요? 그렇다면 조리대가 있는 주방이 좋은 선택입니다. 이렇게 하면 식사 공간을 위한 충분한 공간과 소규모 요리 모임을 조직할 수 있는 기회가 제공됩니다. 별도의 식당이 있는 경우 U자형 주방을 선택할 수 있습니다. 주방에 식사 공간이 반드시 필요한 것은 아닙니다. 그리고 가끔씩만 요리한다면
                            작은 주방 유닛이 좋은 선택입니다. 평화롭고 조용하게 요리하는 것을 선호한다면, 내장형 주방이 이상적인 솔루션입니다. 분리되어 있고 자체적으로 완성됩니다. 클래식 주방과 달리, 내장형 주방은 주방 공간에 맞게 맞춤 제작되어 방을 가득 채우는 주방 정면을 만듭니다. 여기서는 편의성, 보관 공간 및 최적의 공간 활용에 중점을 둡니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 py-0 my-40px my-md-80px">
            <v-row align="center" class="row--lg">
                <v-col cols="12" md="7" class="py-md-0">
                    <v-img src="/images/ideas/kitchen/overview/kitchenware.webp"></v-img>
                </v-col>
                <v-col cols="12" md="5" class="py-md-0">
                    <v-row class="row--lg">
                        <v-col cols="12" md="6">
                            <v-container>
                                <div class="txt txt--xs mb-20px grey--text font-weight-light">더 많은 개성</div>
                                <div class="tit mb-12px mb-md-16px">빌레로이앤보흐의 주방용품</div>
                                <p class="txt txt--sm mb-18px mb-md-24px">빌레로이 앤 보흐는 다양한 주방용품으로 꿈의 주방에 더욱 개성을 더합니다. 세련되게 디자인된 주방 수도꼭지, 세라믹 싱크대 및 기타 액세서리는 높은 수준의 적응성을 특징으로 하며 다른 주방 장비와 완벽하게 보완됩니다.</p>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="10">
                        <overview-kitchenwares />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="2">
            <v-container>
                <div class="tit mb-12px mb-md-16px">주방 틀</div>
                <p class="txt txt--sm">새로운 주방을 선택하기 전에, 어떤 주방 모양이 공간에 적합한지 고려하는 것도 중요합니다.</p>
            </v-container>
        </page-section>

        <overview-kitchen-slide />

        <page-section id="3">
            <v-container class="tit-wrap">
                <v-row class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit mb-12px mb-md-16px">Villeroy & Boch의 현대식 주방</div>
                        <p class="txt txt--sm">
                            모든 스타일과 취향에 맞는 주방이 있습니다. <span class="font-weight-bold">맞춤형 주방</span> , <span class="font-weight-bold">싱글 주방</span> 또는 <span class="font-weight-bold">오픈 플랜 주방 - L자형, U자형</span> 또는 <span class="font-weight-bold">쿠킹 아일랜드가 있는 주방</span>. 주방을 계획할 때 어떤 유형과 모양의 주방이 가장 적합한지 고려하세요. Villeroy & Boch와 함께라면 꿈의 주방에 맞는 올바른 스타일을 찾을 수 있습니다. 저희가 영감을 드리겠습니다!
                        </p>
                    </v-col>
                </v-row>
            </v-container>
            <overview-modern-slide />
        </page-section>
    </client-page>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import OverviewKitchenwares from "@/components/client/ideas/kitchen/overview/overviewKitchenwares.vue";
import OverviewKitchenSlide from "@/components/client/ideas/kitchen/overview/overview-kitchen-slide.vue";
import OverviewModernSlide from "@/components/client/ideas/kitchen/overview/overview-modern-slide.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        ClientPage,
        PageSection,
        ShopProductVisual,
        OverviewKitchenwares,
        OverviewKitchenSlide,
        OverviewModernSlide,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "주방에 대한 영감",
                href: "/ideas/bathroom/overview",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.glance-list {
    > li {
        padding-left: 20px;
        margin-bottom: 12px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: 4px;
            top: 8px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--v-grey-base);
        }
    }
}
@media (min-width: 768px) {
    .glance-list {
        > li {
            margin-bottom: 16px;
            &::before {
                top: 8px;
            }
        }
    }
}
</style>