<template>
    <page-section>
        <v-container>
            <v-row align="center" class="row--lg">
                <v-col cols="12" lg="3">
                    <h2 class="tit font-tertiary font-weight-regular mb-14px mb-md-16px">더 많은 아이디어 살펴보기</h2>
                    <p class="txt txt--sm">모든 생활 공간에 대한 아이디어. 이것이 현대 생활을 즐기는 방법입니다.</p>
                </v-col>
                <v-col cols="12" lg="9">
                    <v-row justify="center" justify-lg="start" class="row--lg">
                        <template v-for="item in items">
                            <v-col cols="6" lg="4" :key="item">
                                <v-card flat max-width="266" rounded="xs" class="main-design-card" :to="item.link">
                                    <v-responsive :aspect-ratio="1 / 1">
                                        <v-img :src="item.image" eager />
                                        <div class="v-card__name tit tit--sm font-tertiary white--text">
                                            {{ item.name }}
                                        </div>
                                    </v-responsive>
                                </v-card>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        PageSection,
    },
    data: () => ({
        items: [
            {
                name: "주방",
                link: "/ideas/kitchen/overview",
                image: "/images/main/design/kitchen.jpg",
            },
            {
                name: "욕실",
                link: "/ideas/bathroom/overview",
                image: "/images/main/design/bathroom.jpg",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.v-card {
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    .v-image {
        transform: scale(1.1);
        transition: all 0.4s;
    }
    &__name {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 16px;
        z-index: 1;
        transition: all 0.4s;
    }
    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: var(--v-primary-base);
        left: 0;
        bottom: 0;
        transform: translateY(100%);
        opacity: 0.8;
        transition: all 0.4s;
        border-radius: var(--rounded-xs);
    }
}
@media (min-width: 768px) {
    .v-card {
        &:hover {
            .v-card {
                &__name {
                    bottom: 50%;
                    transform: translate(-50%, 50%);
                }
            }
            .v-image {
                transform: scale(1);
            }
            &::after {
                transform: translateY(0);
            }
        }
    }
}
</style>