<template>
    <page-section>
        <v-container class="position-relative pb-12px py-md-72px">
            <v-sheet class="section-bg" />
            <v-row align="center" class="row--lg">
                <v-col cols="12" md="7">
                    <v-card tile img="/images/ideas/kitchen/overview/types.webp" class="position-relative mb-50px mb-md-0">
                        <v-responsive :aspect-ratio="674 / 504" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="5">
                    <v-card color="grey lighten-5" class="h-md-400px d-md-flex align-center">
                        <div>
                            <div class="tit mb-12px mb-md-16px">주방의 종류</div>
                            <p class="txt txt--sm mb-18px mb-md-24px">다양한 주방 스타일을 살펴보고 귀하의 요구에 딱 맞는 디자인을 찾는 방법을 알아보세요. 작고 컴팩트한 주방이든 넓고 개방형 주방-거실이든, 다양한 주방 유형에 맞게 특별히 맞춤화된 실용적인 팁과 세련된 아이디어를 제공합니다. 작은 공간에도 편안함과 스타일을 더하는 방법을 알아보세요.</p>
                            <v-btn large color="#fff" elevation="2" to="/ideas/kitchen/overview/types" class="rounded-xs">
                                주방 유형 살펴보기
                                <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                            </v-btn>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </page-section>
</template>

<script>
import pageSection from "@/components/client/templates/page-section.vue";

export default {
    components: { pageSection },
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
.section-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }
}
@media (min-width: 768px) {
    .section-bg {
        width: calc(100% / 12 * 8);
        height: 100%;
    }
}
</style>