var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-container', [_c('v-sheet', {
    staticClass: "section-bg section-bg--secondary"
  }), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "full-image-wrap"
  }, [_c('v-img', {
    staticClass: "d-md-none",
    attrs: {
      "src": "/images/ideas/bathroom/material/idea.webp"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', [_c('h2', {
    staticClass: "tit font-tertiary font-weight-regular mb-14px mb-md-16px"
  }, [_vm._v("현대적인 욕실을 위한 아이디어")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 현대적인 욕실을 만드는 것은 무엇입니까? 독립형 욕조, 바닥에 있는 샤워기 또는 대형 세면대? 당신이 좋아하는 것은 무엇이든 허용됩니다. 현대적인 욕실 디자인을 위한 아이디어를 찾아보세요! ")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }