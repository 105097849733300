<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/material/visual.webp" :breadcrumbs="breadcrumbs" title="Bathroom materials" />

        <page-section class="py-40px py-md-80px">
            <v-container class="mt-50px mt-md-100px">
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <v-col cols="12">
                                <router-link to="#1" class="txt txt--sm d-flex align-center">
                                    <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                    욕실을 위한 최고의 재료
                                </router-link>
                            </v-col>
                            <v-col cols="12">
                                <router-link to="#3" class="txt txt--sm d-flex align-center">
                                    <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                    욕실 디자인이 새롭게 바뀌었습니다.
                                </router-link>
                            </v-col>
                            <v-col cols="12">
                                <router-link to="#3" class="txt txt--sm d-flex align-center">
                                    <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                    자주 묻는 질문
                                </router-link>
                            </v-col>
                            <v-col cols="12">
                                <router-link to="#4" class="txt txt--sm d-flex align-center">
                                    <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                    욕실을 위한 영감
                                </router-link>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <v-row class="row--xs">
                                <v-col cols="auto">
                                    <v-icon small>mdi-check</v-icon>
                                </v-col>
                                <v-col> 욕실을 새롭게 디자인하고 싶은데 어떤 욕실 소재가 가장 좋은지 궁금하십니까? 도자기, 유리, 목재 등 욕실 소재의 차별점과 어디에 사용되는지 알려드리겠습니다. </v-col>
                            </v-row>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="1">
            <v-container>
                <div class="text-center">
                    <div class="tit mb-12px mb-md-16px">욕실을 위한 최고의 재료</div>
                    <div class="txt txt--sm">올바른 재료를 사용하면 욕실이 웰빙의 오아시스가 될 것입니다. 타일은 진정한 고전입니다. Villeroy & Boch의 벽 및 바닥 타일은 청소가 쉽고 다양한 형식, 디자인, 색상으로 제공됩니다. 타일을 바닥재로 사용하려면 견고하고 미끄러지지 않는 타일을 선택해야 합니다. 대형 욕실 타일은 표면을 균일하게 만들고 작은 욕실을 더 크게 보이게 합니다. 욕실 벽에는 고품질 석기 벽 타일을 권장합니다.</div>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="image-wrap">
                    <v-img src="/images/ideas/bathroom/material/antao-bathtub-white-with-washbasin-and-taps.webp" max-width="672" />
                    <v-img src="/images/ideas/bathroom/material/artis-washbasin-frozen-with-finion-furniture-and-toilet.webp" class="positioned-image positioned-image--1" />
                    <v-img src="/images/ideas/bathroom/material/squaro-infinity-shower-tray-in-grey-bathroom-with-chair.webp" class="positioned-image positioned-image--2" />
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <p class="txt txt--sm mt-md-16px">유리와 목재 소재는 욕실에서도 찾아볼 수 있습니다. 유리 샤워 부스는 현대적이고 우아합니다. 고품질 목재 패널과 목재 가구가 욕실에 따뜻함을 선사합니다. 욕실을 개조하고 싶고 적합한 재료를 찾고 있다면 타일 대신 특수 석고를 사용할 수도 있습니다.</p>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card tile elevation="2" color="grey lighten-5" class="pa-24px pa-md-40px">
                            <div class="mb-12px mb-md-16px">
                                <v-row align="center" class="row--sm">
                                    <v-col cols="auto">
                                        <v-avatar size="20" class="border border-dark">
                                            <v-icon color="#000">mdi-information-symbol</v-icon>
                                        </v-avatar>
                                    </v-col>
                                    <v-col>
                                        <div class="txt txt--dark font-weight-medium">팁</div>
                                    </v-col>
                                </v-row>
                            </div>
                            <p class="txt txt--sm">예를 들어, 욕실 타일의 자연스러운 대안은 발수성, 항진균성 특성을 지닌 Tadelakt 석회 석고로 샤워 뒷벽으로도 적합합니다.</p>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="2">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">욕실 디자인이 새롭게 바뀌었습니다.</div>
                        <p class="txt txt--sm">욕실은 나만의 웰빙 오아시스입니다. 따라서 욕실 소재를 신중하게 선택하는 것이 더욱 중요합니다. 위생용품의 디자인과 느낌이 중요한 역할을 합니다. 그러나 천장, 벽, 욕실 바닥의 디자인도 중요합니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container class="position-relative pb-12px py-md-72px">
                <v-sheet class="section-bg" />
                <swiper v-bind="{ options }">
                    <template v-for="item in items">
                        <swiper-slide :key="item.title">
                            <v-row align="center" class="row--lg">
                                <v-col cols="12" md="5" order="2" order-md="1">
                                    <v-card color="grey lighten-5" class="h-md-400px d-md-flex align-center">
                                        <div>
                                            <div class="tit mb-12px mb-md-16px">
                                                {{ item.title }}
                                            </div>
                                            <div class="txt txt--sm txt--dark font-weight-medium">
                                                {{ item.subtitle }}
                                            </div>
                                            <p class="txt txt--sm mb-18px mb-md-24px">
                                                {{ item.text, }}
                                            </p>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="7" order="1" order-md="2">
                                    <v-card tile :img="item.image" class="position-relative mb-50px mb-md-0">
                                        <v-responsive :aspect-ratio="674 / 504" />
                                        <div class="swiper-controler">
                                            <v-row align="center" class="row--xs">
                                                <v-col cols="auto">
                                                    <v-btn x-small icon outlined class="swiper-button swiper-button--prev">
                                                        <v-img max-width="32" src="/images/icon/icon-swiper-left.svg" />
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <div class="swiper-pagination" />
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-btn x-small icon outlined class="swiper-button swiper-button--next">
                                                        <v-img max-width="32" src="/images/icon/icon-swiper-right.svg" />
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </swiper-slide>
                    </template>
                </swiper>
            </v-container>
        </page-section>

        <material-idea />

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">빌레로이앤보흐의 혁신</div>
                        <p class="txt txt--sm">빌레로이앤보흐는 항상 품질과 혁신을 대표해 왔습니다. AntiBac(항균 표면) 또는 Quaryl 기술과 같은 혁신과 기술은 회사의 창의성에서 비롯되었으며 욕실의 더욱 편안함과 지속 가능성을 보장합니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-sheet color="transparent" max-width="940" class="mx-auto">
                    <v-row justify="center" align="center" class="row--xxl">
                        <v-col cols="12" md="6">
                            <v-card max-width="776" color="transparent" tile flat class="mx-auto">
                                <v-img src="/images/ideas/bathroom/material/antao-bathtub-white-with-bath-tap-in-beige-bathroom.webp" class="mb-12px mb-md-16px" />
                                <div class="px-12px px-md-16px">
                                    <div class="tit tit--xs grey--text text--darken-3 mb-6px mb-md-8px">욕실을 위한 Quaryl 기술</div>
                                    <p class="txt txt--sm grey--text text--darken-3">욕조나 샤워 트레이에 이상적인 재료가 무엇인지 궁금하십니까? 우리는 Quaryl 또는 아크릴이라고 말합니다. 다용도 위생 아크릴은 대부분의 욕조에 사용됩니다. 아크릴 수지와 천연 석영석으로 구성된 최고급 쿼릴로 만든 욕조도 제공합니다. 그 결과, 잘 디자인되고 안정적인 위생 제품이 탄생했습니다.</p>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card max-width="776" color="transparent" tile flat class="mx-auto">
                                <v-img src="/images/ideas/bathroom/material/architectura-toilet-white-in-scandic-bathroom.webp" class="mb-12px mb-md-16px" />
                                <div class="px-12px px-md-16px">
                                    <div class="tit tit--xs grey--text text--darken-3 mb-6px mb-md-8px">AntiBac: 항균 표면</div>
                                    <p class="txt txt--sm grey--text text--darken-3">AntiBac은 지속적인 항균 효과를 지닌 혁신적인 표면 기술입니다. 독립 기관에서 장기적인 보호를 확인했습니다. 유약에 포함된 은 이온 덕분에 AntiBac은 박테리아의 성장을 99.9% 이상 감소시킵니다. 테두리가 없는 DirectFlush 양변기 및 CeramicPlus 표면과 함께 Villeroy & Boch 기술은 최고 수준의 청결과 위생을 제공합니다.</p>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-container>
        </page-section>

        <page-section id="3" class="grey lighten-5 py-40px py-md-80px my-20px my-md-40px">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">자주 묻는 질문</div>
                        <div class="txt txt--sm">아래에서 욕실 자재에 관해 자주 묻는 질문에 대한 답변을 찾아보세요.</div>

                        <v-expansion-panels tile>
                            <template v-for="item in qna">
                                <v-expansion-panel :key="item.header">
                                    <v-expansion-panel-header class="txt txt--lg txt--dark">
                                        {{ item.header }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="txt txt--sm txt--dark">
                                        {{ item.content }}
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </template>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="4">
            <v-container class="text-center">
                <div class="tit mb-12px mb-md-16px">욕실을 위한 영감</div>
                <p class="txt txt--sm">욕실을 아름답고 아늑하게 만드는 것은 무엇일까요? 우리의 디자인 아이디어가 당신에게 영감을 주도록 하세요!</p>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <template v-for="item in inspirations">
                        <v-col :key="item.title" cols="12" md="6">
                            <v-row no-gutters>
                                <v-col cols="6">
                                    <v-img :src="item.image" :aspect-ratio="1 / 1" />
                                </v-col>
                                <v-col cols="6">
                                    <v-sheet color="grey lighten-5" height="100%" class="d-flex align-center pa-20px">
                                        <div>
                                            <div class="tit tit--sm mb-10px">
                                                {{ item.title }}
                                            </div>
                                            <p class="txt txt--sm">
                                                {{ item.text }}
                                            </p>
                                        </div>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import MaterialIdea from "@/components/client/ideas/bathroom/overview/material/material-idea.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        ClientPage,
        PageSection,
        ShopProductVisual,
        MaterialIdea,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 개요",
                href: "/ideas/bathroom/overview",
            },
        ],
        items: [
            {
                title: "욕실 및 도자기",
                text: "Villeroy & Boch의 고품질 위생 제품은 현대적인 디자인과 품질을 결합합니다. 내구성이 뛰어난 소재의 위생 도자기가 클래식 욕실 세면대에 사용됩니다. 제품군의 일부 세면기는 혁신적인 소재인 TitanCeram으로 제작되어 탄력성이 뛰어납니다. 먼지 방지 CeramicPlus 표면이나 아름다운 무광택 외관을 연출하는 TitanGlaze 유약이 더욱 편안함을 보장합니다.",
                image: "/images/ideas/bathroom/material/slide-1.webp",
            },
            {
                title: "욕실의 나무",
                text: "최고의 욕실 가구 재료는 목재입니다. 천연 소재를 사용하면 욕실에 아늑함을 더할 수 있습니다. Villeroy & Boch의 목재 욕실 가구는 욕실의 세라믹 제품과 전체적으로 조화로운 그림을 만들어냅니다. 기능적인 화장대, 찬장, 선반 모듈, 키가 큰 캐비닛을 만나보세요!",
                image: "/images/ideas/bathroom/material/slide-2.webp",
            },
            {
                title: "욕실에 유리",
                text: "고품질 유리 샤워스크린은 욕실을 우아하고 현대적으로 보이게 합니다. 우아한 샤워 트레이와 함께 사용하면 시대를 초월한 디자인을 보장할 수 있습니다. 빌레로이앤보흐의 욕실 거울도 아름다운 디자인의 하이라이트입니다. 간접 조명으로 아늑함을 더해줍니다.",
                image: "/images/ideas/bathroom/material/slide-3.webp",
            },
        ],
        qna: [
            {
                header: "싱크대에는 어떤 재료가 적합합니까?",
                content: "다양한 고품질 세면대와 세면기를 갖춘 Villeroy & Boch는 모든 취향에 맞는 완벽한 모델을 제공합니다. 욕실에 적합한 세면대 재료를 어떻게 찾나요? 우리는 위생 세라믹과 TitanCeram이라는 두 가지 고품질 재료 중에서 선택할 수 있습니다. 두 재료 모두 부드럽고 내구성이 뛰어난 표면을 가지고 있습니다.",
            },
            {
                header: "내 욕실에는 어떤 설비가 적합합니까?",
                content: "현대적인 스타일, 순수주의적인 스타일, 고전적인 우아한 스타일 중 어떤 가구 스타일을 욕실에 구현하고 싶나요? Villeroy & Boch의 다용도 수전 컬렉션에는 다양한 디자인 옵션이 포함되어 있습니다. 당사의 피팅 가이드에서 욕실 설비에 관한 모든 중요한 정보를 찾아보실 수 있습니다. ",
            },
            {
                header: "욕실 타일은 어떤 재료로 만들어야 합니까?",
                content: "귀하가 꿈꾸는 욕실을 디자인하는 데 적합한 타일을 찾을 수 있도록 Villeroy & Boch는 다양한 형식, 색상 및 디자인의 다양한 벽 및 바닥 타일을 제공합니다. 욕실에 적합한 타일 재료를 찾고 계십니까? 도자기 석기 또는 토기로 만든 내구성이 뛰어난 타일을 선택하는 것이 좋습니다.",
            },
        ],
        inspirations: [
            {
                title: "타일과 타일",
                text: "석회석고(tadelakt), 콘크리트, 목재 또는 합성수지와 같은 재료는 매끄러운 욕실을 디자인하는 데 이상적입니다. 또는 욕실에 대형 타일과 타일을 설치하여 욕실의 간격을 줄일 수 있습니다. Villeroy & Boch에서 벽과 바닥에 적합한 대형 욕실 타일을 찾을 수 있습니다.",
                image: "/images/ideas/bathroom/material/theano-bath-tub-white-in-bathroom-with-plant.webp",
            },
            {
                title: "현대적인 설비",
                text: "욕실 설비는 모든 욕실에서 중요한 부분입니다. 스테인레스 스틸과 황동으로 제작된 현대적인 설비는 당사 제품군의 세면대, 욕조 및 샤워기에 최적화되어 있습니다. Villeroy & Boch에서는 고품질 욕실용 부품 소재를 만나보실 수 있습니다.",
                image: "/images/ideas/bathroom/material/squaro-infinity-shower-tray-in-grey-bathroom-with-chair.webp",
            },
        ],
        options: {
            effect: "fade",
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                },
            },
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
::v-deep(.swiper-container) {
    overflow: visible;
}
.swiper-controler {
    position: absolute;
    bottom: -56px;
    left: 0;
    .swiper-pagination {
        position: static;
        padding: 0 16px;
        ::v-deep(.swiper-pagination-bullet) {
            margin: 0 16px !important;
            background-color: initial !important;
            border: initial !important;
            width: auto !important;
            height: auto !important;
            font-weight: 500;
            color: var(--v-grey-darken3);
            border-radius: initial;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
            &-active {
                border-bottom: 1px solid var(--v-grey-darken3) !important;
            }
        }
    }
}

.list {
    margin: 40px 0 0;
    > li {
        padding-left: 24px;
        position: relative;
        margin-bottom: 12px;
        &::before {
            content: "";
            position: absolute;
            left: 10px;
            top: 8px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--v-grey-base);
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.tip-list {
    > li {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
@media (min-width: 768px) {
    .list {
        margin: 80px 0 0;
        > li {
            padding-left: 32px;
            margin-bottom: 16px;
            &::before {
                width: 6px;
                height: 6px;
                left: 6px;
                top: 9px;
            }
        }
    }
    .tip-list {
        > li {
            margin-bottom: 16px;
        }
    }
}

.image-wrap {
    position: relative;
    margin-top: 30px;
    .positioned-image {
        &--1 {
            max-width: 288px;
            margin-top: -60px;
            margin-right: calc(-1 * var(--container-gutter));
            margin-left: auto;
            position: relative;
            z-index: 1;
        }
        &--2 {
            max-width: 240px;
            margin-left: calc(-1 * var(--container-gutter));
            margin-top: -180px;
        }
    }
}
@media (min-width: 768px) {
    .image-wrap {
        margin-top: 40px;
        .positioned-image {
            max-width: 368px;
            &--1 {
                margin-top: -130px;
                margin-right: 266px;
            }
            &--2 {
                margin: initial;
                position: absolute;
                top: 100px;
                right: 0;
            }
        }
    }
}
@media (min-width: 1024px) {
    .image-wrap {
        margin-top: 40px;
        .positioned-image {
            max-width: 368px;
            &--1 {
                margin-top: -130px;
                margin-right: 266px;
            }
            &--2 {
                margin: initial;
                position: absolute;
                top: 100px;
                right: 0;
            }
        }
    }
}

.section-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }

    &--secondary {
        left: initial;
        right: 0;
        &::before {
            left: initial;
            right: -100vw;
        }
    }
}

@media (min-width: 768px) {
    .section-bg {
        width: calc(100% / 12 * 8);
        height: 100%;
    }
}

.v-expansion-panels {
    .v-expansion-panel {
        background-color: transparent;
        &::before {
            box-shadow: none;
        }
        &-header {
            padding: 18px 12px 18px 0;
        }
        &-content {
            ::v-deep(.v-expansion-panel-content__wrap) {
                padding-left: 0;
                padding-right: 56px;
            }
        }
    }
}

@media (min-width: 768px) {
    .v-expansion-panels {
        .v-expansion-panel {
            &-header {
                padding: 24px 16px 24px 0;
            }
        }
    }
}
</style>