var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xxl"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": ""
      }
    }, [_c('v-img', {
      staticClass: "mb-6px mb-md-8px",
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "pa-16px"
    }, [_c('div', {
      staticClass: "tit tit--xs mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('p', {
      staticClass: "txt txt--sm mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('router-link', {
      staticClass: "font-weight-medium txt txt--xs txt--dark d-flex align-center",
      attrs: {
        "to": item.link
      }
    }, [_vm._v(" " + _vm._s(item.name) + "에 대한 추가 정보 "), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)], 1)], 1)], 1)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }