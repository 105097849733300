<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/slope/visual.webp" :breadcrumbs="breadcrumbs" title="Sloping ceilings" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">경사진 천장으로 인해 욕실 계획이 어려워집니다. 올바른 객실 컨셉으로 경사진 천장을 갖춘 욕실은 여전히 ​​웰빙의 오아시스가 될 것입니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <template v-for="(item, index) in navigation">
                                <v-col cols="12" :key="index">
                                    <router-link :to="`#${index + 1}`" class="txt txt--sm d-flex align-center">
                                        <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                        {{ item }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <dot-list>
                                <template v-for="item in overview">
                                    <li :key="item">
                                        {{ item }}
                                    </li>
                                </template>
                            </dot-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="1">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">경사 아래 욕실 계획</div>
                        <p class="txt txt--sm">
                            경사진 천장이 있는 욕실을 계획하려면 시간과 정확성이 필요합니다. 대부분의 경우 비정상적인 치수를 다루게 되며 가변적인 천장 높이로 인해 상황이 더욱 어려워집니다. 욕실 평면도도 중요합니다. 욕실이 작다면 샤워기와 욕조 중 하나를 선택해야 할 수도 있습니다. 또한 욕실이 작을수록 욕실 수납 공간을 현명하게 활용해야 합니다. <br />
                            여러 작업을 결합한 욕실 가구는 경사진 천장이 있는 욕실에 매우 적합합니다. 실용적인 싱크대 하부장이나 거울수납장은 넉넉한 수납 공간을 제공하지만 공간을 거의 차지하지 않습니다. Villeroy & Boch Avento 시리즈와 같은 싱크대 하부장은 다양한 크기로 제공되므로 필요와 사용 가능한 공간에 정확하게 가구를 맞출 수 있습니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <section-slide :path="path" :items="slides" />
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">작고 섬세한 위생용품</div>
                        <p class="txt txt--sm">
                            특히 경사진 천장이 있는 좁은 욕실을 설치하는 경우에는 섬세한 가구와 위생 용품이 적합합니다. 공간을 적게 차지하는 가구는 이동 공간을 늘려 실내의 웰빙감을 높여줍니다. 또한 섬세한 물체가 더욱 우아하고 스타일리시해 보입니다. 싱크대는 상대적으로 얕은 깊이를 가져야 합니다. 세면대 유닛은 일반적으로 폭이 특히 좁습니다. Finion 및 Venticello 컬렉션을 권장합니다. 이는 또한 기본 캐비닛을 찾는 데 시간이 많이 소요되는 작업을 없애줍니다. Villeroy & Boch 제품군에서 적합한 캐비닛을
                            선택하기만 하면 됩니다! 변기는 매립형 모델을 선택하고 물탱크를 경사진 지붕에 숨깁니다. 샤워실과 욕조를 섬세하게 디자인할 수 있습니다. Oberon 2.0 컬렉션과 같이 모서리가 둥근 욕조는 시각적으로 공간을 덜 차지합니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="2">
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="5" order-md="2">
                        <v-img :src="`${path}/squaro-bathtub-in-corner-with-mirror.webp`" />
                    </v-col>
                    <v-col cols="12" md="5" order-md="1">
                        <div class="tit mb-12px mb-md-16px">경사진 지붕 아래 공간을 현명하게 활용하세요.</div>
                        <p class="txt txt--sm">
                            경사진 지붕 아래에 어떤 욕실 가구를 놓을 수 있는지 신중하게 생각해 보세요. 물론 변기에 앉아 있는 동안에는 방 전체 높이가 필요하지 않습니다. 그렇기 때문에 경사진 지붕 아래에 화장실을 확실히 배치할 수 있습니다. 특히 창문이 있는 곳이라면 더욱 그렇습니다. 코너 욕조는 넓은 욕실의 경사진 지붕 아래에 편안하게 들어맞습니다. Villeroy & Boch Squaro 컬렉션에서 당신이 찾고 있는 것을 확실히 찾을 수 있을 것입니다. 경사진 천장이 있는 작은 욕실을 디자인하고 있다면 Subway 컬렉션의
                            공간 절약형 욕조가 올바른 선택입니다. 또한 경사면 아래에도 쉽게 들어갈 수 있으며 동시에 공간을 거의 차지하지 않습니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col md="1" class="d-none d-md-block" />
                    <v-col cols="12" md="8">
                        <v-card color="grey lighten-5" tile elevation="4" class="pa-24px pa-md-40px">
                            <div class="mb-12px mb-md-16px">
                                <v-row align="center" class="row--sm">
                                    <v-col cols="auto">
                                        <v-icon>mdi-information-outline</v-icon>
                                    </v-col>
                                    <v-col class="font-weight-medium font-size-18">팁!</v-col>
                                </v-row>
                            </div>
                            <p class="txt txt--sm">채광창이 클수록 욕조에서 보이는 전망이 더 좋아집니다. 이는 경사면 아래의 욕실이 별을 바라보며 편안하고 때로는 낭만적인 경험이 될 수 있다는 것을 의미합니다.</p>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <div class="tit-wrap tit-wrap--lg">
                    <v-row class="row--lg">
                        <v-col cols="12" md="8">
                            <div class="tit mb-12px mb-md-16px">거울은 웰빙과 공간감을 보장합니다.</div>
                            <p class="txt txt--sm">
                                거울이 시각적으로 방을 확대하는 것은 욕실에서만이 아닙니다. 경사진 작은 욕실을 설치하는 경우 하나 이상의 큰 거울 표면을 사용하십시오. 여기에는 무엇보다도 싱크대 위의 거울이 포함될 수 있습니다. My View Now 컬렉션 에는 욕실 공간을 제공하는 대형 거울 캐비닛이 있습니다. <br />
                                하지만 싱크대가 경사면 바로 아래에 있으면 거울을 놓을 공간이 없을 수도 있습니다. 그런 다음 직선 벽에 거울문이 달린 키 큰 수납장을 선택하세요. 수건과 기타 욕실용품을 보관할 수 있는 공간과 실용적인 거울이 결합되어 있습니다. 천장이 경사진 아주 작은 욕실이라면 욕실 문 안쪽에 거울면을 부착할 수도 있습니다.
                            </p>
                        </v-col>
                    </v-row>
                </div>
                <idea-product-list :path="path" :items="products" />
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/artis-washbasin-white-with-conum-tap-and-finion-bath.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">경사진 천장이 있는 욕실 조명</div>
                        <p class="txt txt--sm">욕실의 고전적인 기본 조명은 일반적으로 천장 또는 벽 램프로 구성됩니다. 이는 실내를 고르게 조명하고 안전에 기여하며 밝은 빛을 제공합니다. 벽감과 모서리가 많은 욕실에서 바닥 스포트라이트는 모든 모서리를 적절하게 비추는 데 유용한 추가 기능입니다. 경사진 천장에 바닥 스포트라이트를 비추면 공간이 시각적으로 넓어집니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col md="1" class="d-none d-md-block" />
                    <v-col cols="12" md="8">
                        <div class="txt txt--sm">이러한 기본 조명 외에도 구역 조명(Zone Lighting)이 있습니다. 경사진 천장으로 욕실을 디자인하는 동안 추가 조명 요소 설치 옵션에 주의를 기울이세요. My View 14 컬렉션 의 거울과 거울 캐비닛에는 3단계로 밝기를 조절할 수 있는 실용적인 LED 조명이 함께 제공됩니다. 샤워실이나 욕조 위의 LED 조명은 필요한 경우 분위기 있는 조명을 제공하여 욕실의 기분을 좋게 만듭니다.</div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="3">
            <v-container>
                <div class="text-center">
                    <div class="tit mb-12px mb-md-16px">공간 효과에 주목하세요</div>
                    <p class="txt txt--sm">밝은 색상과 좁고 섬세한 가구, 절제된 장식으로 넓은 공간 효과를 연출합니다. 욕실 액세서리를 고를 때는 깔끔한 디자인 라인과 통일감 있는 룩에 주목하세요. 욕실 액세서리는 가구 및 위생 용품과 겹쳐서는 안됩니다.</p>
                </div>
                <three-images :path="path" first="onovo-washbasin-white-in-bathroom-with-toilet-and-bathtub.webp" second="manufacture-vases-beige-as-diffusor-in-bathroom.webp" third="artis-washbasin-in-guest-bathroom.webp" />
            </v-container>
        </page-section>

        <page-section bg="left">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">경사진 천장이 있는 욕실의 밝고 자연스러운 톤</div>
                        <p class="txt txt--sm">
                            경사진 천장이 있는 욕실을 디자인할 때 밝은 색상은 공간을 더 크게 보이게 하기 때문에 의미가 있습니다. 분홍색이나 보라색과 같은 밝은 색상의 사용을 피하세요. 대신 개별 색상으로 포인트를 준 자연스러운 톤을 선택하세요. 벽을 디자인할 때 흰색 또는 크림색 톤을 선택하세요. 타일은 세면대, 욕조, 샤워실 등 꼭 필요한 곳에만 드물게 사용됩니다. 큰 타일은 시각적으로 공간을 더 작게 만들기 때문에 좁은 타일을 사용하십시오. <br />
                            욕실 가구와 액세서리의 경우 베이지, 라이트 브라운 등 밝고 자연스러운 색상도 사용합니다. 액세서리는 가구의 색상과 조화를 이루며, 개별적인 다채로운 색상은 방에 생동감을 부여하고 조화로운 색상 개념을 교묘하게 깨뜨립니다.
                        </p>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/oberon-20-bathtub-with-liberty-tap.webp`" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/theano-bathtub-white-with-artis-washbasin.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">싱크대와 변기의 최소 치수를 준수하세요.</div>
                        <p class="txt txt--sm">
                            경사진 천장의 욕실에서 위생도기를 최대한 활용하기 위해서는 명심해야 할 몇 가지 사항이 있습니다. 세면대에서는 머리와 천장 사이의 거리가 40cm 이상이어야 합니다. 즉, 싱크대를 편안하게 사용할 수 있다는 의미입니다. 가능하다면 경사진 지붕 바로 아래에 세면대를 두지 마십시오. 대안은 비대칭 디자인입니다. 예를 들어 Villeroy & Boch Artis 또는 Loop & Friends 컬렉션의 조리대 세면대를 중앙에 배치하지 않고 경사진 지붕에서 약간 떨어진 곳에 배치하고 공간을 선반으로 사용합니다. 이
                            디자인은 특히 현대적으로 보입니다. 변기를 편안하게 사용하기 위해서는 뒷벽 높이가 1.30m 이상 되어야 합니다. 그렇지 않으면 일어날 때 머리를 부딪힐 수 있습니다. 또한 변기 앞에 똑바로 설 수 있어야 합니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">저장 공간 옵션으로 캐비티</div>
                        <p class="txt txt--sm">
                            욕실에는 구조적 조건으로 인해 충치가 자주 발생합니다. 이러한 기능을 디자인에 최적으로 통합하고 여기에 선반이나 붙박이 찬장을 배치할 수 있습니다. 천장이 경사진 작은 욕실을 계획하고 있다면 여기에 숨겨진 수납 공간이 많기 때문에 특별히 고려하여 공간을 만들어야 합니다. <br />
                            천장이 경사진 욕실의 벽감에 샤워기를 설치하는 경우 일반적으로 사용할 수 있는 공간이 생깁니다. 샤워실에서 개방형 선반 역할을 할 건식벽체에 틈새를 만드세요. 샤워젤 등을 보관하기에 완벽한 수납 공간입니다. 타일이 설치되어 있어 청소도 쉽습니다. 좁은 약국 캐비닛도 세면대 옆의 건식벽과 빈 공간에 설치할 수 있으며 헤어드라이어, 칫솔 및 기타 욕실 용품을 위한 공간을 제공합니다.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit mb-12px mb-md-16px">경사면 아래에 있는 욕조와 샤워기</div>
                        <p class="txt txt--sm">샤워 – 경사진 천장과 작은 욕실? 이는 기본적으로 가능하지만 샤워기 헤드룸은 2.30미터가 되어야 합니다. 키가 1.75미터 미만인 경우 샤워를 위한 직선 벽이 하나 이상 있으면 경사진 지붕에 직접 샤워를 설치할 수도 있습니다. 그렇지 않은 경우 작은 욕실에는 Subway Infinity 컬렉션과 같은 샤워 트레이를 직선 벽에 자유롭게 배치할 수 있는 옵션이 있습니다.</p>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card tile elevation="4" class="pa-24px pa-md-40px">
                            <div class="mb-12px mb-md-16px">
                                <v-row align="center" class="row--sm">
                                    <v-col cols="auto">
                                        <v-icon>mdi-information-outline</v-icon>
                                    </v-col>
                                    <v-col class="font-weight-medium font-size-18">팁!</v-col>
                                </v-row>
                            </div>
                            <p class="txt txt--sm">욕조를 사용하면 유연성이 더 높아집니다. 쉬운 진입을 위해 적어도 한쪽 측면에는 1.90m의 헤드룸이 있어야 합니다. 그 외에도 앉을 때 전체 높이가 필요하지 않기 때문에 욕조를 경사면 아래에 설치할 수 있습니다.</p>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="4">
            <v-container class="text-center">
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit mb-12px mb-md-16px">이는 경사진 욕실을 최적으로 사용할 수 있음을 의미합니다.</div>
                        <p class="txt txt--sm">경사진 천장을 갖춘 수많은 욕실 아이디어가 있습니다. 현대적이고 섬세한 위생용품과 밝은 색상을 선택하여 시각적으로 공간을 넓혀보세요. 붙박이 찬장으로 여유 공간을 능숙하게 활용하고 장식 아이템을 아껴서 액센트로 사용하세요. Finion, Antao 및 Loop & Friends 컬렉션은 경사진 천장이 있는 욕실을 위한 현대적인 디자인의 다양한 위생용품과 욕실 가구를 제공합니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <template v-for="item in collections">
                        <v-col cols="6" lg="4" :key="item">
                            <v-card tile flat :href="item.link" target="_blank" max-width="400" class="mx-auto">
                                <v-img :src="`${path}/${item.image}`" class="mb-12px mb-md-16px" />
                                <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm grey--text text--darken-3">
                                    {{ item.text }}
                                </p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">자세히 알아보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";

import SectionSlide from "@/components/client/dumb/section-slide.vue";
import ExpansionPanels from "@/components/client/ideas/expansion-panels.vue";
import DotList from "@/components/client/ideas/dot-list.vue";
import ThreeImages from "@/components/client/ideas/three-images.vue";
import IdeaProductList from "@/components/client/ideas/idea-product-list.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,

        SectionSlide,
        ExpansionPanels,
        DotList,
        ThreeImages,
        IdeaProductList,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 플래너",
                href: "/ideas/bathroom/planner",
            },
        ],
        path: "/images/ideas/bathroom/slope",
        navigation: [
            "경사 아래 욕실 계획",
            "경사진 천장으로 욕실 디자인하기",
            "공간 효과에 주목하세요",
            "이는 경사진 욕실을 최적으로 사용할 수 있음을 의미합니다.",
            //
        ],
        overview: [
            "평면도와 방 높이를 기준으로 가장 중요한 욕실 가구의 위치를 ​​계획합니다.",
            "거울은 작은 욕실을 더 크게 보이게 만듭니다.",
            "아름다운 전망을 위해 욕조를 경사진 지붕 아래에 배치하세요.",
            "밝고 친근한 자연스러운 톤으로 욕실을 디자인하세요.",
            //
        ],
        slides: [
            {
                title: "경사면 아래 공간 계산",
                text: "먼저 실제로 사용 가능한 공간이 얼마나 되는지 명확히 해야 합니다. 경사진 작은 욕실은 위치에 따라 높이가 다르기 때문이죠. 한편으로는 평균 2.30미터에서 2.50미터 사이의 방 높이가 있습니다. 헤드룸은 경사진 천장이 있는 욕실에도 적합합니다. 최소 1.90m 이상이어야 합니다. 경사진 천장 아래 머리 공간이 항상 1.90미터인 것은 아니므로 무릎 바닥, 즉 경사진 천장 아래의 직선 영역이 얼마나 높은지, 그리고 가구를 배치할 수 있는 위치를 정확히 측정해야 합니다. 지붕창이 있는 경우 해당 영역에서 사용 가능한 공간이 더 큽니다. ",
                image: "legato-bathroom-furniture-kansas-oak-with-shower-and-toilet.webp",
            },
            {
                title: "귀하의 욕실 장비 요구 사항을 파악하십시오.",
                text: "사용 가능한 공간이 얼마나 되는지 알게 되면 다음 단계는 우선순위를 명확히 하는 것입니다. 목욕을 좋아한다면 샤워도 할 수 있는 욕조를 설치하는 것을 고려해 보세요. 예를 들어 Verve 샤워 컬렉션의 샤워 커튼과 샤워 장치가 포함된 샤워 레일을 사용하면 욕조가 다기능으로 변합니다.\r\n욕실에 얼마나 많은 수납 공간이 필요한지 아는 것도 중요합니다 . 제한된 공간을 최대한 활용하려면 선반과 붙박이 찬장에 빈 공간을 사용하는 것이 좋습니다. 수건 사다리, 걸이 선반, 벽감 수납칸을 이용해 모든 욕실 필수품을 보관할 수 있습니다.",
                image: "subway-30-washbasin-with-vanity-unit-and-light-mirror.webp",
            },
            {
                title: "제품 다양성 및 설치 옵션 고려",
                text: "경사진 지붕을 목적에 맞게 활용하는 방법에는 여러 가지가 있습니다. 경사진 천장에는 창문이 있는 경우가 많기 때문에 아래 공간은 경사진 천장 아래에 욕조를 놓거나, 더 큰 욕실에서는 수납 공간이 있는 벤치로 사용하기에 이상적입니다. 변기는 일반적으로 경사진 지붕 바로 아래에 쉽게 설치할 수도 있습니다. 이는 곧은 벽에 거울이 있는 세면대나 샤워기를 놓을 공간이 충분하다는 것을 의미합니다.\r\n경사진 지붕이 일종의 더 큰 틈새를 만드는 경우 경사진 지붕 옆에 개방형 샤워 시설을 설치하는 것이 좋습니다. Villeroy & Boch의 다양한 제품 덕분에 샤워 트레이, 부속품, 샤워기를 선택하고 개별적으로 조합하여 꿈의 샤워를 만들 수 있습니다. ",
                image: "architectura-bathtub-with-hand-shower-and-toilet.webp",
            },
        ],
        products: [
            {
                title: "캐비닛",
                text: "욕실에 적합한 키가 큰 캐비닛이나 측면 캐비닛을 찾아보세요.",
                cols: "6",
                image: "antao-highboard-bathroom-honey-oak.webp",
                link: "/",
            },
            {
                title: "거울장",
                text: "Villeroy & Boch에서 어울리는 거울 수납장을 찾아보세요.",
                cols: "6",
                image: "my-view-mirror-cabaniet-with-washbasin.webp",
                link: "/",
            },
        ],
        collections: [
            {
                title: "Finion",
                text: "당신의 주장을 실현하세요",
                image: "finion-bath-tub-and-furniture-in-blue-bathroom.webp",
                link: "/",
            },
            {
                title: "Antao",
                text: "자연과의 만남",
                image: "antao-bathroom-green-bath-tub.webp",
                link: "/",
            },
            {
                title: "Loop & Friends",
                text: "특별한 장소의 경우",
                image: "loop-and-friends-washbasin-in-white-bathroom-santorinian-style.webp",
                link: "/",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>